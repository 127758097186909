import React from "react";
import {
    Row,
    Button,
    Modal
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

export const ConfirmationModal = (props) => {
    const continueApi = () => {
        props.setcontinuationafterconfirmation(true)
        console.log("CONFIRMED AND CONTINUED.")
        props.onHide()
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            backdropClassName="custom-backdrop"
            centered
            scrollable="false"
        >
            <Modal.Header className="row no-gutter" style={{paddingBottom: 0}} closeButton>
            </Modal.Header>
            <Modal.Body style={{paddingTop: 0, paddingBottom: 0}} >
                <Row className="no-gutter flex-row-start-center">
                    <h5>
                        <Icon.ExclamationOctagonFill style={{ color: 'red', fontSize: 20, marginRight: 10 }} />
                        You have not selected any of the approval options. <br /><br />
                    </h5>
                    <h6>Are you sure you do not want to send this contact for approval?</h6>
                </Row>
            </Modal.Body>
            <Modal.Footer style={{padding: '0px 10px'}}>
                <div className="flex-row-center-start" style={{ float: 'left', width: '100%' }}>
                    <Button className="custom-button" style={{
                        width: "fit-content",
                        display: "inline-block",
                        padding: "10px 20px",
                        margin: "10px 0px 15px 15px"
                    }} onClick={() => continueApi()} >
                        Continue
                    </Button>
                    <Button className="custom-button" style={{
                        width: "fit-content",
                        display: "inline-block",
                        padding: "10px 20px",
                        margin: "10px 0px 15px 25px"
                    }} onClick={() => props.onHide()} >
                        Go back
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}