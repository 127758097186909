import React, {
    useMemo,
    useState,
    useEffect,
    useCallback,
    useRef,
} from "react";
import {
    Row,
    Col,
    Button,
    Modal,
    Form
} from "react-bootstrap";
import { logout } from "../../../utils";
import { toast } from "react-toastify";
import { getFromLS } from "../../../utils/storage";
import { FormContext } from "../../reusable_components/input_fields/FormContext";
import Element from '../../reusable_components/input_fields/Input';
import * as Icon from "react-bootstrap-icons";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";

export const RecordsFullWindowModal = (props) => {
    const [data, setData] = useState([])
    const [rowData, setRowData] = useState([])
    const [previewComponents, setPreviewComponents] = useState([])
    const [formTitle, setFormTitle] = useState("")
    const [formUrl, setFormUrl] = useState("")
    const [formDescription, setFormDescription] = useState("")
    useEffect(() => {
        setFormTitle(props.formtitle)
    }, [props.formtitle])
    useEffect(() => {
        setFormUrl(props.formurl)
    }, [props.formurl])
    useEffect(() => {
        setFormDescription(props.formdescription)
    }, [props.formdescription])

    useEffect(() => {
        setData(props.recordsmodaldata)
        console.log("DATA: ", props.recordsmodaldata)
        const row = []
        for (let record of props.recordsmodaldata) {
            const newEntry = {}
            for (let object of record.Response) {
                Object.assign(newEntry, { [object["label"]]: object["value"] })
            }
            Object.assign(newEntry, { "previewComponent": record.Response })
            Object.assign(newEntry, { "firmFormResponseId": record.FirmFormsResponse })
            row.push(newEntry)
        }
        console.log("Row: ", row)
        setRowData(row)
        gridParamsMapper(row)
    }, [props.recordsmodaldata])

    // Setting default grid params
    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const onFirstDataRendered = useCallback((params) => {
        // gridRef.current.api.sizeColumnsToFit();
    }, []);

    let gridApi;
    const onGridReady = useCallback((params) => {
        gridApi = params.api;
        gridApi.closeToolPanel();
    }, []);

    const defaultColDef = useMemo(
        () => ({
            filter: true,
            resizable: true,
            animateRows: true,
        }),
        []
    );

    /**
     * A grid cell renderer button.
     * @param {*} props: props include the selected row data for editing.
     * @returns A button for editing the row in grid.
     */
    const BtnCellRenderer = (props) => {
        const editClick = () => {
            props.selectedRow(props.data);
        }
        return (<Button onClick={() => editClick()} style={{ cursor: "pointer", backgroundColor: "transparent" }}><Icon.PencilSquare /></Button>);
    }

    // Declaring the api - grid connections
    const [columnDefs, setColumnDefs] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState({ "previewComponent": [] });
    useEffect(() => {
        console.log("SELECTED ROW DATA: ", selectedRowData)
        for (let component of selectedRowData["previewComponent"]) {
            if (!component["show"]) {
                component["show"] = true
            }
        }
        setPreviewComponents(selectedRowData["previewComponent"])
    }, [selectedRowData])

    const onSelectionChanged = () => { const selected_rows = gridRef.current.api.getSelectedRows() };

    const CustomNoRowsOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center flex-row-center-center"
                style={{ height: '9%' }}
            >
                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                    {props.noRowsMessageFunc()}
                </div>
            </div>
        );
    };

    const noRowsOverlayComponent = useMemo(() => { return CustomNoRowsOverlay }, []);

    const noRowsOverlayComponentParams = useMemo(() => { return { noRowsMessageFunc: () => 'Loading your records' } }, []);

    /**
    * This function is used for formatting the default column names of the grid
    * @param {*} defaultName: Current name from API
    * @returns: Final column name
    */
    const GridColumnTitleRenderer = (defaultName) => {
        const splitWords = defaultName.split("_")
        for (let word in splitWords) {
            splitWords[word] = splitWords[word].charAt(0).toUpperCase() + splitWords[word].slice(1)
        }
        return splitWords.join(" ")
    }

    /**
     * This function takes the API response data and maps it to the grid params
     * @param {*} result: API response
     */
    const gridParamsMapper = (rowData) => {
        if (rowData.length !== 0) {
            const keys = Object.keys(rowData[0]).slice(0, -2) // skipping the previewcomponents and firmformresponseid
            let jsonColDefs = keys.map(key => {
                return {
                    field: key, headerName: GridColumnTitleRenderer(key)
                }
            });
            let colDefs = [{
                field: "", headerName: "Actions",
                cellStyle: { 'border-right-color': '#ccc' },
                minWidth: 75,
                width: 75,
                suppressMenu: true,
                cellRenderer: BtnCellRenderer,
                cellRendererParams: {
                    selectedRow: setSelectedRowData
                }
            }].concat(jsonColDefs)
            setColumnDefs(colDefs)
        }
    }

    /**
     * Update the values of fields in the PREVIEW form
     * @param {*} field_id: ID of field to be updated 
     * @param {*} event: The trigger event of input in preview form
     */
    const handleChange = (field_id, event) => {
        const newList = [...previewComponents]
        newList.forEach(field => {
            const { type, id } = field;
            if (field_id === id) {
                switch (type) {
                    case 'checkbox':
                        field['value'] = event.target.checked;
                        break;

                    case 'select':
                        field['value'] = event.value;
                        break;

                    case 'file':
                        field['value'] = event[0];
                        break;

                    case 'date':
                        field['value'] = event;
                        break;

                    default:
                        field['value'] = event.target.value;
                        break;
                }
            }
            const newListTwo = [...newList]
            setPreviewComponents(newListTwo)
        })
    }

    const updateData = (firmFormResponseId) => {
        let payloadFormat;
        payloadFormat = {
            "FirmFormsResponse": firmFormResponseId,
            "Response": previewComponents
        }
        console.log("PAYLOAD FORMAT: ", payloadFormat)
        const token = getFromLS("token")
        const postOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payloadFormat),
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/${formUrl}/`, postOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.success === true) {
                    toast.success(
                        " Entry successfully updated!",
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-success",
                        }
                    );
                } else {
                    toast.error(
                        "Saving failed, " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch((err) => console.log("Err: ", err));
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            backdropClassName="custom-backdrop"
            centered
            className="full-modal"
            scrollable="false"
        >
            <Modal.Header className="mapping-modal-header" closeButton>
            </Modal.Header>
            <Modal.Body className="mapping-modal">
                <Row className='no-gutter'>
                    <Col md={6} className="flex-row-space-center" style={{ padding: "0px 20px 0px 0px" }}>
                        <p className="display-6" style={{ height: "fit-content", marginBottom: 10, width: "fit-content" }}>{formDescription} records</p> <p style={{ width: "fit-content" }}>Form name: <b>{formTitle}</b></p>
                    </Col>
                    <Col md={6} className="flex-row-center-center">
                        <p className="display-6" style={{ height: "fit-content", marginBottom: 10 }}>{formTitle}</p>
                    </Col>
                </Row>
                <Row className='no-gutter' style={{ height: "90%" }}>
                    <Col md={6}>
                        <div className="ag-theme-balham" style={gridStyle}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                onGridReady={onGridReady}
                                alwaysShowHorizontalScroll={false}
                                alwaysShowVerticalScroll={false}
                                rowHeight={25}
                                rowSelection={"single"}
                                suppressContextMenu={true}
                                onFirstDataRendered={onFirstDataRendered}
                                noRowsOverlayComponent={noRowsOverlayComponent}
                                noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        {
                            previewComponents ?
                                <>
                                    <FormContext.Provider value={{ handleChange }}>
                                        <Form className='no-padding'>
                                            <Row className='no-gutter'>
                                                {
                                                    previewComponents.map((field, i) =>
                                                        <Element
                                                            key={i}
                                                            field={field}
                                                        />
                                                    )
                                                }
                                            </Row>
                                        </Form>
                                    </FormContext.Provider>
                                    <Row className="no-gutter flex-row-center-center">
                                        <Button className="custom-button" style={{
                                            width: "fit-content",
                                            padding: "10px 20px",
                                            marginTop: 25
                                        }} onClick={() => updateData(selectedRowData["firmFormResponseId"])} >
                                            <Icon.Upload style={{ fontSize: 20, margin: "0px 10px 0px 0px" }} />
                                            Update information
                                        </Button>
                                    </Row>
                                </>
                                :
                                <></>
                        }
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="action-footer" style={{ padding: 0 }}>
            </Modal.Footer>
        </Modal>
    )
}