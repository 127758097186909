import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppWrapper from "./AppWrapper";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH9ed3VURmRcUU11W0c=');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Router>
      <AppWrapper />
    </Router>
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// DEFAULT SCRIPTS
// "start": "react-scripts start",
// "build": "CI= react-scripts build",
// "test": "react-scripts test",
// "eject": "react-scripts eject"
reportWebVitals();
