import React, { useState, useEffect, createContext } from "react";
import { Row } from "react-bootstrap";
import "./App.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import App from './App'
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import styled, { ThemeProvider } from "styled-components";
import { useTheme } from "./themes/useTheme";
import { useChartTheme } from "./themes/useChartTheme";
import ThemeSelector from "./themes/theme_selector_component/ThemeSelector";
import Accordion from "react-bootstrap/Accordion";
import { Button} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useAuth } from "./context/AuthProvider";
import { useNavigate } from "react-router-dom";
import themes from "./themes/themes.json";
import chart_themes from "./themes/chartThemes.json";
import { getFromLS, setToLS } from "./utils/storage";
import { ThreeCircles } from "react-loader-spinner";
import { logout } from "./utils";
import { GlobalStyles } from "./themes/GlobalStyles";

const ROLE_NAME_MAPPER = {
    "SuperAdmin": "Super admin",
    "FirmAdmin": "Firm admin",
    "Analyzer": "Analyst",
    "FlowBuilder": "Flow builder",
    "Client Data Manager": "Data manager",
    "User": "User",
    "Partner": "Partner"
}

export const AppContext = createContext(null);
const Themer = styled.div`
  margin: 0px !important;
`;

const AppWrapper = () => {
    // const [inactive, setInactive] = useState(false);
    const [currPath, setCurrPath] = useState(window.location.pathname);
    const url = (getFromLS("url") !== undefined ? getFromLS("url") : [])
    // const baseChildren = <div>{url.map(createCarouselItemImage)}</div>;
    const id = localStorage.getItem("firmId");
    const user = localStorage.getItem("username");
    const active_features = localStorage.getItem("active-features");
    useEffect(() => {
        setCurrPath(window.location.pathname);
        if (active_features === undefined || active_features === null || active_features.length === 0) {
            setToLS("active-features", []);
        }
        setActiveFeatures(active_features)
    }, []);
    useEffect(() => {
        console.log("App rendering");
    }, []);
    const [localStorageThemes, setLocalStorageThemes] = useState({});
    const localStorageActiveTheme = getFromLS("theme");
    const localStorageActiveChartTheme = getFromLS("chart-theme");
    const enabledMode = getFromLS("mode");
    const [isLoading, setLoading] = useState(false);
    const [darkModeToggle, setDarkModeToggle] = useState(false);
    const [activeFeatures, setActiveFeatures] = useState(getFromLS("active-features"));
    const username = localStorage.getItem("username");
    const restrictedUsers = ["wenddev", "ewilder@wendroffcpa.com", "wendprod"];

    useEffect(() => {
        let mode = getFromLS("mode");
        if (mode === undefined || mode === null) {
            setToLS("mode", themes.mode["light"]);
            document.documentElement.style.setProperty(
                "--body_color",
                themes.mode["light"].colors.body
            );
            document.documentElement.style.setProperty(
                "--text_color",
                themes.mode["light"].colors.text
            );
            document.documentElement.style.setProperty(
                "--card_body_color",
                themes.mode["light"].colors.card_body
            );
        } else if (mode.name === "light") {
            setDarkModeToggle(false);
        } else {
            setDarkModeToggle(true);
        }
    }, [darkModeToggle]);
    useEffect(() => {
        if (localStorageActiveTheme === undefined) {
            setToLS("theme", themes.data["calm"]);
        }
    }, []);
    useEffect(() => {
        if (!enabledMode || enabledMode === undefined) {
            setToLS("mode", themes.mode["light"]);
            document.documentElement.style.setProperty(
                "--body_color",
                themes.mode["light"].colors.body
            );
            document.documentElement.style.setProperty(
                "--text_color",
                themes.mode["light"].colors.text
            );
            document.documentElement.style.setProperty(
                "--card_body_color",
                themes.mode["light"].colors.card_body
            );
        } else if (enabledMode.name === "light") {
            document.documentElement.style.setProperty(
                "--body_color",
                themes.mode["light"].colors.body
            );
            document.documentElement.style.setProperty(
                "--text_color",
                themes.mode["light"].colors.text
            );
            document.documentElement.style.setProperty(
                "--card_body_color",
                themes.mode["light"].colors.card_body
            );
        }
        else {
            setToLS("mode", enabledMode);
            document.documentElement.style.setProperty(
                "--body_color",
                themes.mode["dark"].colors.body
            );
            document.documentElement.style.setProperty(
                "--text_color",
                themes.mode["dark"].colors.text
            );
            document.documentElement.style.setProperty(
                "--card_body_color",
                themes.mode["dark"].colors.card_body
            );
        }
    }, []);
    useEffect(() => {
        if (localStorageActiveChartTheme === undefined) {
            setToLS("chart-theme", chart_themes.data["Tableau Classic 10"]);
        }
    }, []);

    useEffect(() => {
        const ls_themes = getFromLS("all-themes");
        if (!ls_themes) {
            setLocalStorageThemes();
        } else {
            setLocalStorageThemes(ls_themes);
        }
    }, []);

    useEffect(() => {
        setToLS("chart-themes", chart_themes.data);
        setToLS("all-themes", themes.data)
    }, []);

    const [allThemes, setAllThemes] = useState(localStorage.getItem("all-themes"));
    const { theme, themeLoaded } = useTheme();
    const [selectedTheme, setSelectedTheme] = useState(theme);

    useEffect(() => {
        setSelectedTheme(theme);
    }, [themeLoaded]);

    const { chartTheme, chartThemeLoaded } = useChartTheme();
    const [selectedChartTheme, setSelectedChartTheme] = useState(chartTheme);

    useEffect(() => {
        setSelectedChartTheme(chartTheme);
    }, [chartThemeLoaded]);

    const [show, setShow] = useState(false);
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem("username")) {
            navigate("/login");
        }
    }, []);

    const handleLogout = () => {
        logout();
    };
    const clearCache = () => {
        localStorage.clear();
        window.location.reload();
        // navigate(currPath)
    };

    const ThemeComponent = (props) => {
        const themeSetter = (prop) => {
            setLoading(true)
            setSelectedTheme(prop)
            setTimeout(() => {
                setLoading(false)
            }, 2000);
        }
        const chartThemeSetter = (prop) => {
            setLoading(true)
            setSelectedChartTheme(prop)
            setTimeout(() => {
                setLoading(false)
            }, 2000);
        }
        return (
            <>
                {
                    !isLoading ?
                        <div>
                            <Row className="show-grid">
                                {/* <p className="display-6" style={{ marginLeft: 20 }}>Select or create a theme</p> */}
                                <ThemeSelector
                                    setter={(prop) => themeSetter(prop)}
                                    tether={(prop) => chartThemeSetter(prop)}
                                    isLoading={setLoading}
                                />
                            </Row>
                        </div> :
                        <div className="center-loader">
                            <p>Applying your theme. Please wait a moment.</p>
                            <ThreeCircles
                                height="100"
                                width="100"
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="three-circles-rotating"
                                outerCircleColor="var(--theme1_primary)"
                                innerCircleColor="var(--theme1_secondary)"
                                middleCircleColor="var(--theme1_tertiary)"
                            />
                        </div>
                }
            </>
        );
    }
    
    function openNav() {
        document.getElementById("myNav").style.height = "100%";
    }

    function closeNav() {
        document.getElementById("myNav").style.height = "0%";
    }


    return (
        <>
            {themeLoaded && (
                <ThemeProvider theme={selectedTheme} chartTheme={selectedChartTheme}>
                    <GlobalStyles />
                    <Themer>
                        {/* <MyVerticallyCenteredModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        /> */}
                        {id && user ?

                            <div>
                                <div id="myNav" class="nav-overlay">
                                    <a class="closebtn" onClick={() => closeNav()}>&times;</a>
                                    <div class="nav-overlay-content">
                                        {active_features.includes("THMS") &&
                                            <Accordion style={{ border: 0, borderColor: "#fff", padding: 0 }} defaultActiveKey="0">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header style={{ borderRadius: 12 }}>
                                                        <Icon.PaletteFill className={"accordian-icons"} />{" "}
                                                        &nbsp;&nbsp; Theme Settings
                                                    </Accordion.Header>
                                                    <Accordion.Body style={{ minHeight: 500, maxHeight: 575, overflowY: "auto" }}>
                                                        <ThemeComponent />
                                                    </Accordion.Body>
                                                </ Accordion.Item>
                                            </Accordion>
                                        }
                                        {(active_features.includes("FRMADMN") && localStorage.getItem("usertype") === "FirmAdmin") &&
                                            <Accordion.Header onClick={() => {
                                                closeNav()
                                                navigate("/firmadmin")
                                            }
                                            }>
                                                <Icon.PersonFillGear className={"accordian-icons"} />{" "}
                                                &nbsp;&nbsp; Firm admin panel
                                            </Accordion.Header>
                                        }
                                        {/* {localStorage.getItem("usertype")==="SuperAdmin" &&
                                    <div className="themes-button">
                                        <Accordion.Header onClick={() => navigate("/superadmin")}>
                                            <Icon.PersonFillGear className={"accordian-icons"} />{" "}
                                            &nbsp;&nbsp; Super admin panel
                                        </Accordion.Header>
                                    </div>} */}
                                        <div className="cache">
                                            <Accordion.Header>
                                                <Button
                                                    variant="secondary"
                                                    className="custom-button"
                                                    onClick={handleLogout}
                                                    style={{
                                                        width: "50%",
                                                        borderRadius: "0px",
                                                    }}
                                                >
                                                    <Icon.DoorClosedFill
                                                        className={"accordian-icons"}
                                                    />{" "}
                                                    Logout
                                                </Button>
                                                <OverlayTrigger
                                                    delay={{ hide: 450, show: 300 }}
                                                    overlay={(props) => (
                                                        <Tooltip {...props}>
                                                            Clear cache and hard reload
                                                        </Tooltip>
                                                    )}
                                                    placement="bottom"
                                                >
                                                    <Button
                                                        variant="secondary"
                                                        className="custom-button cacheone"
                                                        onClick={clearCache}
                                                        style={{
                                                            width: "50%",
                                                            borderRadius: "0px",
                                                            margin: 0,
                                                        }}
                                                    >
                                                        <Icon.ArrowCounterclockwise
                                                            className={"accordian-icons"}
                                                        />{" "}
                                                        Clear cache
                                                    </Button>
                                                </OverlayTrigger>
                                            </Accordion.Header>
                                        </div>
                                    </div>
                                </div>
                                {/* <span
                            style={{ fontSize: "30px", cursor: "pointer" }}
                            onClick={() => openNav()}>
                            &#9776; open
                        </span> */}
                                <div style={{ position: "relative", cursor: "pointer" }}>
                                    <div className="new-nav flex-row-center-center" onClick={() => openNav()}>
                                        <div className="profile-icon"><Icon.PersonCircle className="firm-logo" /></div>
                                        <div className="user-role-name profile-full-section">
                                            {user.toUpperCase()} &nbsp;{"(" + ROLE_NAME_MAPPER[localStorage.getItem("usertype")] + ")"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            // <Navbar collapseOnSelect expand="lg" className="sticky-nav">
                            //     <Navbar.Brand className="dashboard-logo" onClick={() => navigate("/dashboard")}>
                            //         <img
                            //             src={dashboard_logo}
                            //             alt="logo"
                            //         />
                            //     </Navbar.Brand>
                            //     <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                            //     <Navbar.Collapse id="responsive-navbar-nav">
                            //         <Nav className="me-auto"></Nav>

                            //         <div className="nav-right">
                            //             {/* <Nav className="helper">
                            //                 <Nav.Link href="" className={"helper"}>
                            //                     {" "}
                            //                     <Icon.QuestionCircle
                            //                         onClick={() => setHelperModalShow(true)}
                            //                     />{" "}
                            //                     &nbsp;
                            //                 </Nav.Link>
                            //             </Nav> */}
                            //             <NavDropdown
                            //                 title={
                            //                     <span className={"settingTitle"}>
                            //                         <Icon.PersonFill className="firm-logo" />&nbsp;&nbsp; | &nbsp;&nbsp;{user.toUpperCase()}
                            //                         <p className="user-role-name">{ROLE_NAME_MAPPER[localStorage.getItem("usertype")]}</p>
                            //                     </span>
                            //                 }
                            //                 id="navbarScrollingDropdown"
                            //                 autoClose={false}
                            //                 className="settingDropdown"
                            //                 align={{ lg: 'end' }}
                            //             >
                            //                 {
                            //                     <div className="themes-button">
                            //                         <Accordion.Header onClick={() => setModalShow(true)}>
                            //                             <Icon.PaletteFill className={"accordian-icons"} />{" "}
                            //                             &nbsp;&nbsp; Theme Settings
                            //                         </Accordion.Header>
                            //                     </div>
                            //                 }
                            //                 {(active_features.includes("FRMADMN") && localStorage.getItem("usertype") === "FirmAdmin") &&
                            //                     <div className="themes-button">
                            //                         <Accordion.Header onClick={() => navigate("/firmadmin")}>
                            //                             <Icon.PersonFillGear className={"accordian-icons"} />{" "}
                            //                             &nbsp;&nbsp; Firm admin panel
                            //                         </Accordion.Header>
                            //                     </div>}
                            //                 {/* {localStorage.getItem("usertype")==="SuperAdmin" &&
                            //                 <div className="themes-button">
                            //                     <Accordion.Header onClick={() => navigate("/superadmin")}>
                            //                         <Icon.PersonFillGear className={"accordian-icons"} />{" "}
                            //                         &nbsp;&nbsp; Super admin panel
                            //                     </Accordion.Header>
                            //                 </div>} */}
                            //                 <div className="cache">
                            //                     <Accordion.Header>
                            //                         <Button
                            //                             variant="secondary"
                            //                             onClick={handleLogout}
                            //                             style={{
                            //                                 width: "50%",
                            //                                 borderRadius: "0px",
                            //                             }}
                            //                         >
                            //                             <Icon.DoorClosedFill
                            //                                 className={"accordian-icons"}
                            //                             />{" "}
                            //                             Logout
                            //                         </Button>
                            //                         <OverlayTrigger
                            //                             delay={{ hide: 450, show: 300 }}
                            //                             overlay={(props) => (
                            //                                 <Tooltip {...props}>
                            //                                     Clear cache and hard reload
                            //                                 </Tooltip>
                            //                             )}
                            //                             placement="bottom"
                            //                         >
                            //                             <Button
                            //                                 variant="secondary"
                            //                                 onClick={clearCache}
                            //                                 className="cacheone"
                            //                                 style={{
                            //                                     width: "50%",
                            //                                     borderRadius: "0px",
                            //                                     margin: 0,
                            //                                 }}
                            //                             >
                            //                                 <Icon.ArrowCounterclockwise
                            //                                     className={"accordian-icons"}
                            //                                 />{" "}
                            //                                 Clear cache
                            //                             </Button>
                            //                         </OverlayTrigger>
                            //                     </Accordion.Header>
                            //                 </div>
                            //             </NavDropdown>

                            //         </div>
                            //     </Navbar.Collapse>
                            // </Navbar>
                            :
                            <></>
                        }
                        <App
                            activeFeatures={active_features}
                        />
                    </Themer>
                </ThemeProvider>
            )}</>
    )
}

export default AppWrapper;