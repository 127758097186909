import React, {
    useMemo,
    useState,
    useEffect,
    useCallback,
    useRef,
} from "react";
import {
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip,
    Modal
} from "react-bootstrap";
import { ThreeCircles, Bars } from "react-loader-spinner";
import { logout } from "../../utils";
import { capitalizeFirstLetter } from "../../utils";
import { getFromLS } from "../../utils/storage";
import * as Icon from "react-bootstrap-icons";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { PreviewFullWindowModal } from "./PreviewFullWindowModal";
import { toast } from "react-toastify";
import Select, { LoadingIndicatorProps } from 'react-select';
import AddNewWizardModal from "../engagement_letter/add_new_wizard_modal/AddNewWizardModal";
import UploadSpreadsheetModal from "../engagement_letter/upload_spreadsheet_modal/UploadSpreadsheetModal";
import { ApprovalSettingsModal } from "../approval_config/ApprovalSettingsModal";
import { createPortal } from "react-dom";
import CustomButton from "../reusable_components/custom_button_component/Button";
import Toolbar from "../reusable_components/collapsible_toolbar/CollapsibleToolbar";

const MyClients = (props) => {
    const [data, setData] = useState({})
    const [allForms, setAllForms] = useState([])
    const [rowData, setRowData] = useState([])
    const [fieldNameMap, setFieldNameMap] = useState({})
    const [previewStructure, setPreviewStructure] = useState([])
    const [previewComponents, setPreviewComponents] = useState([])
    const [formTitle, setFormTitle] = useState("")
    const [formUrl, setFormUrl] = useState("")
    const [activeClientType, setActiveClientType] = useState()
    const [formDescription, setFormDescription] = useState("")
    const [previewFullWindowModalShow, setPreviewFullWindowModalShow] = useState(false)
    const [formOptions, setFormOptions] = useState([{ value: '', label: '' }])
    const [selectedFormOption, setSelectedFormOption] = useState({ value: '', label: '', url: '' })
    const [approvalConfig, setApprovalConfig] = useState({})
    const [addNewWizardShow, setAddNewWizardShow] = useState(false);
    const [wizardData, setWizardData] = useState()
    const [wizarDataMessage, setWizarDataMessage] = useState("")
    const [uploadSpreadsheetModal, setUploadSpreadsheetModalShow] = useState(false);
    const [engagementInitiationModal, setEngagementInitiationModal] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState({ "label": "", "value": "" })
    const [addNewWizardSaveFlag, setAddNewWizardSaveFlag] = useState(false);
    const [templateOptions, setTemplateOptions] = useState([])
    const [spreadsheetFileUploading, setSpreadsheetFileUploading] = useState(false);
    const [approvalSettingModal, setApprovalSettingModal] = useState(false);
    const [clientMasterId, setClientMasterId] = useState(null)
    const [clientTypeId, setClientTypeId] = useState(null)
    const [sendForApprovalBulkApiProgress, setSendForApprovalBulkApiProgress] = useState(false);

    // PAGINATION LOGIC
    const [cachedData, setCachedData] = useState([]); // Store all fetched data
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 25;
    const onPaginationChanged = (params) => {
        const dt = { "message": undefined }
        setData(Object.assign(data, dt))
        const currentPage = params.api.paginationGetCurrentPage() + 1; // Current page (1-based index)
        const totalPages = params.api.paginationGetTotalPages(); // Total number of pages
        console.log(`Pagination changed: Current Page = ${currentPage}, Total Pages = ${totalPages}`);
    }

    // Custom Loading Indicator
    const CustomLoader = (props) => {
        return (
            <div style={{ display: "flex", alignItems: "center", padding: "5px" }}>
                <span style={{ marginRight: "8px" }}>
                    <ThreeCircles
                        height="20"
                        width="20"
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="three-circles-rotating"
                        outerCircleColor="var(--theme1_primary)"
                        innerCircleColor="var(--theme1_secondary)"
                        middleCircleColor="var(--theme1_tertiary)"
                    />
                </span>
            </div>
        );
    };

    const CustomLoadingIndicator = (props) => {
        return (<CustomLoader />);
    };

    // Simulate fetching data with isLoading true
    const isLoading = formOptions.length === 0;
    const [engagementTemplateDetails, setEngagementTemplateDetails] = useState([]);
    useEffect(() => {
        fetchEngagementTemplatePageData()
    }, [])

    const fetchEngagementTemplatePageData = useCallback(() => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/templates/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                setEngagementTemplateDetails(data.data);
            })
            .finally(() => { });
    }, []);
    const removeFileExtension = (filename) => {
        return filename.replace(/\.[^/.]+$/, "");
    };

    useEffect(() => {
        const options = []
        if (engagementTemplateDetails !== undefined) {
            console.log("TEMPLATE DETAILS: ", engagementTemplateDetails)
            for (let template of engagementTemplateDetails) {
                options.push({
                    "label": removeFileExtension(template["TemplateOriginalFileName"] !== null ? template["TemplateOriginalFileName"] : ""),
                    "value": template.TemplateId
                })
            }
            setTemplateOptions(options)
        }
    }, [engagementTemplateDetails])
    const postOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
            "FormPurpose": "Client Onboarding"
        }),
    };

    useEffect(() => {
        FetchApprovalConfiguration()
    }, [])
    const [engagementClientMasterData, setEngagementClientMasterData] = useState()
    const [selectedFieldOptions, setSelectedFieldOptions] = useState({})
    useEffect(() => {
        const token = localStorage.getItem("token")
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/firm_template_fields/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.success === true) {
                    console.log("SELECTED FIELD OPTIONS: ", JSON.parse(data["data"][0]["SelectedFields"]))
                    setSelectedFieldOptions(JSON.parse(data["data"][0]["SelectedFields"]))
                } else {
                    toast.error(
                        "Failed to fetch selected fields: " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch((err) => console.log("Err: ", err));
    }, [])
    const toastBasicConfig = {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };

    const toastSuccessConfig = {
        ...toastBasicConfig,
        className: "toastify-color-success",
    };

    const toastErrorConfig = {
        ...toastBasicConfig,
        className: "toastify-color-error",
    };

    const fetchWizardFormDetails = (templateId) => {
        return new Promise((resolve, reject) => {
            let token = localStorage.getItem("token");
            const getRequestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            };

            if (templateId !== undefined) {
                fetch(
                    `${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/wizard_page_templates/?TemplateId=${templateId}`,
                    getRequestOptions
                )
                    .then((response) => {
                        if (!response.ok) {
                            if (response.status === 401) {
                                logout();
                                alert("Session ended , Please login back");
                            } else {
                                reject(new Error("Request failed."));
                            }
                        }
                        return response.json();
                    })
                    .then((result) => {
                        resolve(result["data"]);
                    })
                    .catch((error) => reject(error));
            } else {
                reject(new Error("Template ID is undefined"));
            }
        });
    };
    const uploadSpreadsheet = async () => {
        const templateId = localStorage.getItem("template_id");
        console.log("templateId", templateId)
        const approvalConfigDetails = await fetchTemplateApprovalConfigDetails(templateId);
        const isApprovalConfigured = approvalConfigDetails.filter(data => data.ApprovalRequired == "N" || data.Active == "Y").length > 0;

        if (selectedTemplate) {
            if (isApprovalConfigured) {
                setUploadSpreadsheetModalShow(true);
            }
            else {
                toast.error(
                    `Please set approval configuration for the template before uploading.`,
                    toastErrorConfig
                );
            }
        }
        else {
            toast.error(
                `Please select a template before uploading.`,
                toastErrorConfig
            );
        }
    }

    const fetchTemplateApprovalConfigDetails = (templateId) => {
        return new Promise((resolve, reject) => {
            let token = localStorage.getItem("token");
            const getRequestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            };

            if (templateId !== undefined) {
                fetch(
                    `${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/template_approval_configuration_details/?TemplateId=${templateId}`,
                    getRequestOptions
                )
                    .then((response) => {
                        if (!response.ok) {
                            if (response.status === 401) {
                                logout();
                                alert("Session ended , Please login back");
                            } else {
                                reject(new Error("Request failed."));
                            }
                        }
                        return response.json();
                    })
                    .then((result) => {
                        resolve(result["data"]);
                    })
                    .catch((error) => reject(error));
            } else {
                reject(new Error("Template ID is undefined"));
            }
        });
    };

    const addNewWizard = async () => {
        setAddNewWizardShow(true);
        const templateId = localStorage.getItem("template_id");
        console.log("templateId", templateId)
        setWizarDataMessage("Loading your wizard")
        const wizardFormDetails = await fetchWizardFormDetails(templateId);
        console.log("WIZARD FORM DETAILS: ", wizardFormDetails)
        const formatted_pages = []
        if (wizardFormDetails.length !== 0) {
            for (let [index, page] of wizardFormDetails.entries()) {
                formatted_pages.push({
                    data: JSON.parse(page["PreviewStructure"]),
                    flag: false,
                    id: index,
                    pageName: page["TemplateName"]
                })
            }
        } else {
            setWizarDataMessage("This feature has not been configured for the selected template. Please complete the configuration to use this feature.")
        }
        console.log("FORMATTED PAGES: ", formatted_pages)
        setWizardData(formatted_pages)
    }

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                console.log("ALL FORMS: ", data)
                setAllForms(data.data)
            });
    }, [])


    useEffect(() => {
        const optionList = []
        for (let form of allForms) {
            const option = { "label": `${form.FormName} (${form.ClientTypeName})`, "value": form.FirmFormId, "url": form.Url, "client_type": form.ClientTypeName }
            optionList.push(option)
        }
        setFormOptions(optionList)
        if (optionList[0] !== undefined) {
            // console.log("OPTION LIST: ", optionList[0])
            setSelectedFormOption(optionList[0])
            setActiveClientType(optionList[0]["client_type"])
        }
    }, [allForms])

    const FetchFormRecords = useCallback((page) => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        if (selectedFormOption !== undefined && selectedFormOption.value !== '') {
            setFormTitle(selectedFormOption["label"])
            setFormUrl(selectedFormOption["url"])
            fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/responses/${selectedFormOption.value}?page=${page}&page_size=${pageSize}`, requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    // console.log("FORM RESPONSES: ", data.data)
                    setData(data)
                    if (Object.keys(data).length > 0) {
                        const records = data["data"]["records"]
                        const sorted_records = records.sort((a, b) => {
                            const dateA = a["Last Updated Date"] ? new Date(a["Last Updated Date"]) : 0;
                            const dateB = b["Last Updated Date"] ? new Date(b["Last Updated Date"]) : 0;
                            return dateB - dateA;
                        });
                        // console.log(sorted_records)
                        setCachedData((prevData) => {
                            const newData = [...prevData];
                            newData[page - 1] = sorted_records; // Cache data for the current page
                            return newData;
                        });
                        const preview = data["data"]["previewStructure"]
                        const fieldnamemap = data["data"]["fieldnamemap"]
                        setRowData(sorted_records)
                        setPreviewStructure(preview)
                        setFieldNameMap(fieldnamemap)
                        setFormDescription("Client onboarding")
                    }
                });
        }
    })

    const FetchApprovalConfiguration = () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/approval_config/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.data.length !== 0) {
                    const source_data = [{
                        "Category": "Clients created in MDM",
                        "Client Type": "Individual Client",
                        "Approval": data.data[0].ApprovalConfig ? data.data[0].ApprovalConfig : "No"
                    },
                    {
                        "Category": "Clients created in MDM",
                        "Client Type": "Entity Client",
                        "Approval": data.data[1].ApprovalConfig ? data.data[1].ApprovalConfig : "No"
                    },
                    {
                        "Category": "Clients created externally",
                        "Client Type": "Individual Client",
                        "Approval": data.data[2].ApprovalConfig ? data.data[2].ApprovalConfig : "No"
                    },
                    {
                        "Category": "Clients created externally",
                        "Client Type": "Entity Client",
                        "Approval": data.data[3].ApprovalConfig ? data.data[3].ApprovalConfig : "No"
                    }
                    ]
                    setApprovalConfig(source_data)
                    console.log(approvalConfig)
                } else {
                    const source_data = [{
                        "Category": "Clients created in MDM",
                        "Client Type": "Individual Client",
                        "Approval": "No"
                    },
                    {
                        "Category": "Clients created in MDM",
                        "Client Type": "Entity Client",
                        "Approval": "No"
                    },
                    {
                        "Category": "Clients created externally",
                        "Client Type": "Individual Client",
                        "Approval": "No"
                    },
                    {
                        "Category": "Clients created externally",
                        "Client Type": "Entity Client",
                        "Approval": "No"
                    }
                    ]
                    setApprovalConfig(source_data)
                }
            })
    }

    useEffect(() => {
        // console.log("ROWDATA:", rowData)
        // console.log("FIELD MAP DATA:", fieldNameMap)
        if (rowData.length > 0 && fieldNameMap !== undefined) {
            gridParamsMapper(rowData)
        }
    }, [rowData, previewStructure, fieldNameMap])


    // Setting default grid params
    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    let gridApi;
    const onGridReady = (params) => {
        gridApi = params.api;
        gridApi.closeToolPanel();
        console.log("ON GRID READY CALLED!")
        params.api.setDatasource(createDataSource());
        gridRef.current.api.sizeColumnsToFit();
    };

    // Run when selectedFormOption changes
    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            console.log("Selected form option changed:", selectedFormOption);
            gridRef.current.api.setDatasource(createDataSource());
            setFormUrl(selectedFormOption["url"])
        }
    }, [selectedFormOption]);

    const createDataSource = () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        return {
            getRows: (params) => {
                // Show the loading overlay
                gridRef.current.api.showLoadingOverlay();
                console.log("getRows called"); // Debug log
                console.log("Params:", params); // Debug log
                console.log("Request:", params.request); // Debug log
                const currentPage = Math.ceil(params.startRow / pageSize) + 1;
                console.log(`Fetching data for page ${currentPage} with page size ${pageSize}`);
                if (selectedFormOption !== undefined && selectedFormOption.value !== '') {
                    fetch(
                        `${process.env.REACT_APP_BASE_URI}/workflow/forms/responses/${selectedFormOption.value}?page=${currentPage!==undefined?currentPage:1}&page_size=${pageSize!==undefined?pageSize:25}`, requestOptions
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            // console.log("FORM RESPONSES: ", data.data)
                            setData(data)
                            if (Object.keys(data).length > 0) {
                                const records = data["data"]["records"]
                                const sorted_records = records.sort((a, b) => {
                                    const dateA = a["Last Updated Date"] ? new Date(a["Last Updated Date"]) : 0;
                                    const dateB = b["Last Updated Date"] ? new Date(b["Last Updated Date"]) : 0;
                                    return dateB - dateA;
                                });
                                const preview = data["data"]["previewStructure"]
                                const fieldnamemap = data["data"]["fieldnamemap"]
                                setRowData(sorted_records)
                                setPreviewStructure(preview)
                                setFieldNameMap(fieldnamemap)
                                setFormDescription("Client onboarding")
                                params.successCallback(sorted_records, data["data"]["total_records"]); // Provide rows and total count
                            } else {
                                console.error('Error fetching data',);
                                params.failCallback();
                            }
                        })
                        .catch((error) => {
                            console.error('Error fetching data:', error);
                            params.failCallback();
                        })
                        .finally(() => {
                            // Hide the overlay
                            gridRef.current.api.hideOverlay();
                        });
                }
            },
        };
    };

    const defaultColDef = useMemo(
        () => ({
            filter: true,
            resizable: true,
            animateRows: true,
            sizeColumnsToFit: true,
            flex: 1
        }),
        []
    );

    const FetchRecordData = (props) => {
        // console.log("PROPS:", props)
        if (selectedFormOption !== undefined && selectedFormOption.value !== '') {
            const postOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getFromLS("token")}`,
                },
                body: JSON.stringify({
                    "ClientMasterId": props.data.ClientMasterId,
                    "FormId": selectedFormOption.value
                }),
            };
            setFormTitle(selectedFormOption["label"])
            fetch(`${process.env.REACT_APP_BASE_URI}/workflow/form/response/`, postOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    const ps_data = [...previewStructure]
                    console.log("Preview Structure: ", ps_data)
                    console.log("DATA: ", data.data)
                    for (let field of ps_data) {
                        field["value"] = data.data[0][field["label"]]
                        // console.log("FIELD: ", field)
                    }
                    props.selectedrow({
                        "id": props.data.ClientMasterId,
                        "previewComponent": ps_data,
                        "update": true
                    });
                    setPreviewFullWindowModalShow(true)
                }
                );
        }
    }

    const SendForApproval = (props) => {
        // console.log("SEND FOR APPROVAL PROPS:", props)
        let clientMasterIds = []
        if (selectedRows.length > 1) {
            clientMasterIds = selectedRows
                .filter(row => row["Approval Status"] !== "approved")
                .map(row => row.ClientMasterId);
        }
        else {
            clientMasterIds = [props.data.ClientMasterId]
        }

        const postOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getFromLS("token")}`,
            },
            body: JSON.stringify({
                "ClientMasterIds": clientMasterIds,
            }),
        };
        // console.log("FORM URL: ", formUrl)
        // console.log("CLIENT MASTER ID: ", props.data.ClientMasterId)
        // console.log(postOptions)
        setSendForApprovalBulkApiProgress(true)
        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/send_for_approval/`, postOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.success === true) {
                    setSendForApprovalBulkApiProgress(false)
                    toast.success(
                        "Sent for approval.",
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-success",
                        }
                    );
                } else {
                    setSendForApprovalBulkApiProgress(false)
                    toast.error(
                        "Unable to send for approval, " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch((err) => console.log("Err: ", err))
            .finally(() => {
                gridRef.current.api.deselectAll();
                setSendForApprovalBulkApiProgress(false)
                setSelectedRows([])
            });
    }

    const FetchClientDetails = (props) => {
        if (selectedFormOption !== undefined && selectedFormOption.value !== '') {
            const postOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getFromLS("token")}`,
                },
                body: JSON.stringify({
                    "ClientMasterId": props.data.ClientMasterId,
                    "FormId": selectedFormOption.value
                }),
            };
            setFormTitle(selectedFormOption["label"])
            fetch(`${process.env.REACT_APP_BASE_URI}/workflow/form/response/`, postOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log("DATA: ", data.data)
                    setEngagementClientMasterData(data.data)
                }
                );
        }
    }

    /**
     * A grid cell renderer button.
     * @param {*} props: props include the selected row data for editing.
     * @returns A button for editing the row in grid.
     */
    const BtnCellRenderer = (props) => {
        // console.log("BTN CELL RENDERER:", props)
        const editClick = () => {
            FetchRecordData(props)
        }

        const sendForApproval = () => {
            // console.log("SEND FOR APPROVAL CALLED")
            SendForApproval(props)
        }

        const engage = () => {
            setEngagementClientMasterData(props.data)
            setAddNewWizardShow(true)
        }
        return (

            previewStructure.length > 0 && props.data !== undefined ?
                <>
                    <Button
                        style={{
                            cursor: "pointer",
                            color: "var(--theme1_primary)",
                            backgroundColor: "transparent",
                            border: "0px solid white",
                            padding: "5px 0px"
                        }}
                        onClick={() => editClick()}>
                        <Icon.PencilSquare />
                    </Button>

                    {
                        props.data["Approval Status"] === "Not started" || props.data["Approval Status"] === "pending" || props.data["Approval Status"] === undefined || props.data["Approval Status"] === "rejected" ?
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="button-tooltip">
                                        {props.data["Approval Status"] === "rejected" ? "Resend for approval" : "Send for approval"}
                                    </Tooltip>
                                }
                                error={true}
                            >
                                <Button
                                    style={{
                                        cursor: "pointer",
                                        color: "var(--theme1_tertiary)",
                                        backgroundColor: "transparent",
                                        border: "0px solid white",
                                        paddingRight: "0px"
                                    }}
                                    onClick={() => sendForApproval()}>
                                    <Icon.SendPlusFill />
                                </Button>
                            </ OverlayTrigger>
                            :
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="button-tooltip">
                                        {props.data["Approval Status"] === "approved" ? 'Client Approved' : 'The approval is in process.'}
                                    </Tooltip>
                                }
                                error={true}
                            >
                                <Button
                                    style={{
                                        cursor: "pointer",
                                        color: "gray",
                                        backgroundColor: "transparent",
                                        border: "0px solid white",
                                        paddingRight: "0px"
                                    }}>
                                    <Icon.SendSlashFill />
                                </Button>
                            </ OverlayTrigger>
                    }
                    {
                        props.data.EngagementLetterStatus === "pending" || props.data.EngagementLetterStatus === undefined ?
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="button-tooltip">
                                        Create Engagement Letter
                                    </Tooltip>
                                }
                                error={true}
                            >
                                <Button
                                    style={{
                                        cursor: "pointer",
                                        color: "var(--theme1_tertiary)",
                                        backgroundColor: "transparent",
                                        border: "0px solid white",
                                    }}
                                    onClick={() => engage()}>
                                    <Icon.FileEarmarkRichtextFill />
                                </Button>
                            </ OverlayTrigger>
                            :
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="button-tooltip">
                                        Engagement letter already exists. Click to create another.
                                    </Tooltip>
                                }
                                error={true}
                            >
                                <Button
                                    style={{
                                        cursor: "pointer",
                                        color: "gray",
                                        backgroundColor: "transparent",
                                        border: "0px solid white",
                                    }}>
                                    <Icon.FileEarmarkRichtextFill />
                                </Button>
                            </ OverlayTrigger>
                    }
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id="button-tooltip">
                                Approval Setting
                            </Tooltip>
                        }
                        error={true}
                    >
                        <div style={{ width: "fit-content", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Icon.GearFill
                                style={{ fontSize: 15, color: "var(--theme1_primary)", cursor: "pointer" }}
                                onClick={() => {
                                    setClientMasterId(props.data["ClientMasterId"]);
                                    setClientTypeId(props.data["ClientTypeId"]);
                                    setApprovalSettingModal(true);
                                }}
                            />
                        </div>
                    </OverlayTrigger>
                </>
                :

                <Button
                    style={{
                        cursor: "wait",
                        color: "var(--theme1_primary)",
                        backgroundColor: "transparent",
                        border: "0px solid white"
                    }}
                    onClick={() => editClick()}>
                    <Icon.PencilSquare />
                </Button>
        )
    }

    /**
     * A grid cell renderer button.
     * @param {*} props: props include the selected row data for editing.
     * @returns A button for editing the row in grid.
     */
    const ApprovalStatusRenderer = (props) => {
        const [showPopup, setShowPopup] = useState(false);
        const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
        const [approvalStatusRefreshGrid, setApprovalStatusRefreshGrid] = useState(false);
        const [approversData, setApproversData] = useState([]);
        const buttonRef = useRef(null);

        const fetchApprovalPageData = useCallback(() => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            };

            const clientMasterId = props.data["ClientMasterId"];

            setApprovalStatusRefreshGrid(true);

            fetch(`${process.env.REACT_APP_BASE_URI}/workflow/form/clientapprovers/${clientMasterId}`, requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    const details = data.data;
                    setApproversData(details);
                })
                .finally(() => {
                    setApprovalStatusRefreshGrid(false);
                });
        }, []);


        useEffect(() => {
            if (showPopup) {
                fetchApprovalPageData();
                // console.log("in show popup")
                updatePopupPosition();
            }
        }, [showPopup]);


        // Function to update popup position
        const updatePopupPosition = () => {
            if (buttonRef.current) {
                const rect = buttonRef.current.getBoundingClientRect();
                setPopupPosition({
                    top: rect.bottom + window.scrollY - 5, // Calculate the position relative to the viewport and page
                    left: rect.left + window.scrollX - 485, // Adjust the left position to move it to the left side
                });
            }
        };

        const approverDefs = [
            { headerName: "Approver Name", field: "Username" },
            { headerName: "Approval Status", field: "ApprovalStatus" },
            { headerName: "Approval Message", field: "Message" }
        ];

        // console.log("PROPS: ", props.data)
        return (
            props.data !== undefined && props.data["Approval Status"] !== undefined
                ?
                <>
                    {/* capitalizeFirstLetter(props.data["Approval Status"]) */}
                    <div className="label-icon">
                        <span style={{ flexGrow: 1, textAlign: "left" }}>
                            {props.data["Approval Status"]}
                        </span>

                        {props.data["Approval Status"] ? (
                            <div
                                onMouseEnter={() => setShowPopup(true)}
                                onMouseLeave={() => setShowPopup(false)}
                                ref={buttonRef}
                            >
                                <Icon.PatchQuestionFill style={{ color: "var(--theme1_tertiary)", margin: "2px 0px 0px 5px", cursor: "pointer" }} />
                            </div>
                        )
                            :
                            null
                        }
                    </div>
                    {showPopup && createPortal(
                        <div
                            style={{
                                position: "absolute",
                                top: popupPosition.top,
                                left: popupPosition.left,
                                zIndex: 1000,
                                width: "500px",
                                border: "1px solid #ccc",
                                backgroundColor: "white",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                            onMouseEnter={() => setShowPopup(true)}
                            onMouseLeave={() => setShowPopup(false)}
                        >
                            <div className="ag-theme-balham" style={{ height: "200px", width: "100%" }}>
                                {!approvalStatusRefreshGrid
                                    ?
                                    <>
                                        <AgGridReact
                                            rowData={approversData}
                                            columnDefs={approverDefs}
                                            defaultColDef={{ flex: 1 }}
                                            suppressContextMenu={true}
                                            suppressCellFocus={true}
                                            onFirstDataRendered={onFirstDataRendered}
                                        />
                                    </>
                                    :
                                    <div
                                        className="icon-cell-container"
                                    >
                                        <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                                            <Bars
                                                height="80"
                                                width="80"
                                                color="var(--theme1_primary)"
                                                ariaLabel="bars-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />

                                            Loading your data
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>,
                        document.body
                    )}
                </>
                :
                null
        )
    }

    // Declaring the api - grid connections
    const [columnDefs, setColumnDefs] = useState([]);
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowData, setSelectedRowData] = useState({ "id": "", "previewComponent": [], "update": false });
    useEffect(() => {
        console.log("SELECTED ROW DATA: ", selectedRowData)
        for (let component of selectedRowData["previewComponent"]) {
            if (!component["show"]) {
                component["show"] = true
            }
        }
        setPreviewComponents(selectedRowData)
    }, [selectedRowData])

    const onSelectionChanged = () => {
        const selected_rows = gridRef.current.api.getSelectedRows()
        setSelectedRows(selected_rows)
        // console.log("SELECTED ROWS: ", selected_rows)
    };

    const CustomNoRowsOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center flex-row-center-center"
                style={{ height: '9%' }}
            >
                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                    {props.noRowsMessageFunc()}
                </div>
            </div>
        );
    };

    const noRowsOverlayComponent = useMemo(() => { return CustomNoRowsOverlay }, [data]);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () =>
                data["message"] !== undefined ? <>No data for {formTitle} client onboarding yet. Please add new records first.</> : <>
                    <div
                        className="flex-row-center-center"
                        style={{ marginTop: "20%" }}
                    >
                        <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                            <Bars
                                height="80"
                                width="80"
                                color="var(--theme1_primary)"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />

                            Loading your data
                        </div>
                    </div></>
        }
    }, [data]);

    /**
    * This function is used for formatting the default column names of the grid
    * @param {*} defaultName: Current name from API
    * @returns: Final column name
    */
    const GridColumnTitleRenderer = (defaultName) => {
        return defaultName
    }

    /**
     * This function takes the API response data and maps it to the grid params
     * @param {*} result: API response
     */
    const gridParamsMapper = (rowData) => {
        if (rowData.length !== 0) {
            const keys = Object.keys(rowData[0])
            const index = keys.indexOf("ApprovalMessage");
            if (index > -1) {
                keys.splice(index, 1);
            }
            // KEYS RESTRICTOR
            const substrings = ['name', 'email', 'approval', 'date'];
            const filteredKeys = keys.filter(key =>
                substrings.some(substring => key.toLowerCase().includes(substring.toLowerCase()))
            );
            const jsonColDefs = filteredKeys.map(key => {
                return (
                    key === 'Approval Status' ?
                        {
                            field: key,
                            headerName: GridColumnTitleRenderer(key),
                            cellRenderer: ApprovalStatusRenderer,
                            cellRendererParams: {
                                selectedrow: setSelectedRowData
                            },
                        }
                        :
                        key === 'Client Name' ?
                            {
                                field: key, headerName: GridColumnTitleRenderer(key), checkboxSelection: true,
                            } :
                            {
                                field: key, headerName: GridColumnTitleRenderer(key)
                            }
                )
            }).filter(item => item !== null).sort()

            const cold = jsonColDefs.concat([
                {
                    field: "", headerName: "Actions",
                    cellStyle: { 'border-right-color': '#ccc' },
                    maxWidth: 125,
                    width: 125,
                    pinned: "right",
                    suppressMenu: true,
                    cellRenderer: BtnCellRenderer,
                    cellRendererParams: {
                        selectedrow: setSelectedRowData
                    }
                }
            ])

            // let colDefs = cold.concat([{
            //     field: "ApprovalStatus", headerName: "Approval Status",
            //     cellStyle: { 'border-right-color': '#ccc' },
            //     maxWidth: 150,
            //     width: 75,
            //     suppressMenu: true,
            //     cellRenderer: ApprovalStatusRenderer,
            //     cellRendererParams: {
            //         selectedrow: setSelectedRowData
            //     }
            // }])

            setColumnDefs(cold)
        }
    }

    const addNewRecord = () => {
        const approval = activeClientType === "Individual" ? approvalConfig.filter((obj) => {
            return obj.Category === "Clients created in MDM" &&
                obj["Client Type"] === "Individual Client"
        })[0]["Approval"] : approvalConfig.filter((obj) => {
            return obj.Category === "Clients created in MDM" &&
                obj["Client Type"] === "Entity Client"
        })[0]["Approval"]

        const preview_comps = {
            "id": null,
            "previewComponent": [...previewStructure],
            "update": false,
            "approval": approval === "Yes" ? true : false
        }
        // console.log("APPROVAL CONFIG in PREVIEW COMPS: ", preview_comps)
        // console.log("APPROVAL CONFIG: ", approvalConfig)
        // console.log("ACTIVE CLIENT TYPE: ", activeClientType)

        setPreviewComponents(preview_comps)
        setPreviewFullWindowModalShow(true)
    }
    const transporter = (pill) => {
        props.transporter(pill);
    };

    const refreshGrid = () => {
        FetchFormRecords()
    }

    const customLoadingOverlay = () => {
        return (
            <div
                className="flex-row-center-center"
                style={{ marginTop: "20%" }}
            >
                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                    <Bars
                        height="80"
                        width="80"
                        color="var(--theme1_primary)"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />

                    Loading your data
                </div>
            </div>
        )
    }

    return (
        <>
            <PreviewFullWindowModal
                show={previewFullWindowModalShow}
                onHide={() => setPreviewFullWindowModalShow(false)}
                previewcomponents={previewComponents}
                refresh={() => refreshGrid()}
                formtitle={formTitle}
                formurl={formUrl}
            />
            <AddNewWizardModal
                show={addNewWizardShow}
                onHide={() => setAddNewWizardShow(false)}
                clientmasterdata={engagementClientMasterData}
                engagementlettertemplates={(elt) => console.log(elt)}
                setapiflag={(flag) => setAddNewWizardSaveFlag(flag)}
            />
            <UploadSpreadsheetModal
                show={uploadSpreadsheetModal}
                onHide={() => setUploadSpreadsheetModalShow(false)}
                setfileuploading={(flag) => setSpreadsheetFileUploading(flag)}
            />
            {approvalSettingModal &&
                <ApprovalSettingsModal
                    clientmasterid={clientMasterId}
                    clienttypeid={clientTypeId}
                    onHide={() => setApprovalSettingModal(false)}
                />
            }
            <Row className='no-gutter page-wrapper'>
                <Col md={2} className="flex-row-space-center" style={{ padding: "0px 20px 0px 0px" }}>
                    <p className="display-6" style={{ height: "fit-content", marginBottom: 0, width: "fit-content" }}>Clients</p> <p style={{ width: "fit-content" }}></p>
                </Col>
                <Col md={2} className="flex-row-space-center" style={{ padding: "0px 20px 0px 0px" }}>
                    <Button className="custom-button"
                        style={{
                            display: "inline-block",
                            padding: "10px 10px",
                        }}
                        onClick={() => addNewRecord()} >
                        <Icon.PlusCircleFill style={{ fontSize: 20, margin: "0px 10px 0px 0px" }} />
                        Add new client
                    </Button>
                </Col>
                <Col md={3}>
                    <div className="form-select-wrapper" style={{ width: 300, marginLeft: 20 }}>
                        <p style={{ marginRight: "0.5rem", marginBottom: 0, width: 100, textAlign: "right", fontWeight: 500 }}>Onboarding template: </p>
                        <Select
                            value={selectedFormOption}
                            onChange={(e) => setSelectedFormOption(e)}
                            options={formOptions}
                            className="form-select custom-select"
                            aria-label="Default"
                            closeMenuOnSelect={true}
                            autosize={true}
                            placeholder="Filter by form"
                            menuPosition="fixed"
                            classNamePrefix="react-select"
                            isLoading={isLoading}
                        />
                    </div>
                </Col>
                {selectedRows.length > 1 ?
                    <>
                        {/* <Col md={2}>
                        <Button className="custom-button"
                            style={{
                                display: "inline-block",
                                padding: "10px 10px",
                            }}
                            onClick={() => addNewRecord()} >
                            Create Engagement Letter
                        </Button>
                    </Col> */}
                        <Col md={2}>
                            {/* <Button className="custom-button"
                                style={{
                                    display: "inline-block",
                                    padding: "10px 10px",
                                }}
                                onClick={() => SendForApproval()} >
                                Send for approval
                            </Button> */}
                            <CustomButton
                                progress={sendForApprovalBulkApiProgress}
                                onclick={() => SendForApproval()}
                                content={"Send for approval"}
                            />
                        </Col>
                    </>
                    :
                    <Col md={4}></Col>
                }
                <Col md={1}>
                    {/* <Icon.ArrowCounterclockwise onClick={() => FetchFormRecords(currentPage)}
                        style={{
                            fontSize: 20,
                            float: "right",
                            border: "0px",
                            cursor: "pointer",
                            marginBottom: -10,
                            marginTop: 10
                        }}
                    /> */}
                </Col>
                {/* <Col md={10} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                    <Toolbar elements={elements} /> 
                </Col> */}
                {/* <Col md={2}>
                            <div className="navigator" style={{ float: "right", width: "fit-content", marginBottom: "0rem" }}>
                                <Button className="nav-direction" onClick={() => transporter("client_approval")}><Icon.CaretRightFill /> Client Approval</Button>
                            </div>
                        </Col> */}
            </Row>
            <Row className='no-gutter' style={{ height: "83vh" }}>
                <Col md={12}>
                    <div className="ag-theme-balham" style={gridStyle}>
                        <AgGridReact
                            ref={gridRef}
                            // rowData={getCurrentPageData()}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                            alwaysShowHorizontalScroll={false}
                            alwaysShowVerticalScroll={false}
                            rowHeight={25}
                            rowSelection={"multiple"}
                            // suppressContextMenu={true}
                            onFirstDataRendered={onFirstDataRendered}
                            noRowsOverlayComponent={noRowsOverlayComponent}
                            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                            onSelectionChanged={onSelectionChanged}
                            rowModelType="infinite" // Use Infinite Row Model
                            paginationPageSize={pageSize} // Page size
                            cacheBlockSize={pageSize} // Matches page size
                            pagination={true}
                            onPaginationChanged={onPaginationChanged} // Listen for pagination changes
                            components={{ customLoadingOverlay: customLoadingOverlay }} // Register the custom component
                            loadingOverlayComponent="customLoadingOverlay"
                            suppressPaginationPanel={true}
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default MyClients;