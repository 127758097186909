import React, { useContext } from 'react'
import { FormContext } from '../FormContext';
import { Col, Image } from "react-bootstrap";
import "./CustomElements.css"
import DropFileInput from "../../drop_file_component/DropFileComponent";


const FileInput = ({ id, source, label, placeholder, value, example, disabled, mandatory, hidden, md, margins }) => {
    const { handleChange } = useContext(FormContext)
    const marginTop = margins["top"] !== undefined && margins["top"] !== null ? margins["top"] : 5
    const marginRight = margins["right"] !== undefined && margins["right"] !== null ? margins["right"] : 5
    const marginBottom = margins["bottom"] !== undefined && margins["bottom"] !== null ? margins["bottom"] : 5
    const marginLeft = margins["left"] !== undefined && margins["left"] !== null ? margins["left"] : 5
    return (
        <Col md={md} className="pad-trl-10" style={hidden ? { display: "None", visibility: "hidden" } : { margin: `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px` }}>
            {label !== null ? <label htmlFor="exampleInputEmail1" className="form-label move-left">{source ? <Image src={source} style={{ width: 15, height: 15, borderRadius: 5, marginRight: 5 }} /> : ""}{label}{mandatory ? <span style={{ color: "red", marginLeft: 5 }}>*</span> : ""}</label> : <></>}
            <DropFileInput
                onFileChange={(files) => handleChange(id, files)}
                fileLimit="single"
            />
            {example !== null ? <div id="emailHelp" className="text-muted move-left input-example">Example: {example}</div> : <></>}
        </Col>
    )
}

export default FileInput