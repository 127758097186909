import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import { Field } from './Fields';
import { Row } from 'react-bootstrap';
import { Bars } from "react-loader-spinner";

const FieldList = styled.div`
  padding: 10px;
  transition: background-color 0.2s ease;
  background-color: ${props => (props.isDraggingOver ? 'white' : 'white')};
  height: fit-content;
  flex-grow: 1;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  flex-flow: row wrap;
  min-width: 100%;
  width: 100%;
`;

export const Column = (props) => {
    // console.log("COLUMN: ", props.column.id)
    const removeFieldFromTarget = (field) => {
        props.onremove(field)
    }

    const addFieldToTarget = (field) => {
        props.onadd(field)
    }

    const [fields, setFields] = useState([])
    useEffect(() => {
        // console.log("FIELDS: ", props.fields)
        setFields(props.fields)
    }, [props.fields])


    return (
        <Row className={"no-gutter"} style={{ height: "575px", width: "100%", overflowY: "auto", background: "white" }}>
            <Droppable droppableId={props.column.id} direction='horizontal'>
                {(provided, snapshot) => (
                    <FieldList
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        isDraggingOver={snapshot.isDraggingOver}
                        column={props.column.id}
                    >
                        {
                            props.column.id === "target-column"
                                ?
                                <>
                                    {
                                        Object.keys(fields).length > 0 ?
                                            Object.keys(fields).map((category, id) => {
                                                // console.log("FIELDS INSIDE MAP: ", fields)
                                                return (
                                                    <>{
                                                        fields[category].length > 0
                                                            ?
                                                            <div style={{ width: "100%" }}>
                                                                <p style={{ fontWeight: 700, margin: "0px 0px 5px 10px" }}>{category}</p>
                                                                <div style={{ width: "100%", display: "flex", flexDirection: "column", flexFlow: "row wrap", minWidth: "100%", width: "100%" }}>
                                                                    {
                                                                        fields[category].map((field, index) => (
                                                                            <Field
                                                                                key={field.id}
                                                                                field={field}
                                                                                index={index}
                                                                                onremove={(field) => removeFieldFromTarget(field)}
                                                                            />
                                                                        )
                                                                        )
                                                                    }
                                                                </div>
                                                                <hr style={{ color: 'gray' }} />
                                                            </div>
                                                            : <>
                                                                {
                                                                    category === "Client information" ?
                                                                        snapshot.isDraggingOver ? <></> : <h3 style={{ color: "#e1e3e4" }}>Drag and drop fields to be configured</h3>
                                                                        : <></>
                                                                }
                                                            </>
                                                    }
                                                    </>

                                                )
                                            }
                                            )

                                            :
                                            <>
                                                {
                                                    snapshot.isDraggingOver ? <></> : <h3 style={{ color: "#e1e3e4" }}>Drag and drop fields to be configured</h3>
                                                }
                                            </>
                                    }
                                </>
                                :
                                <>
                                    {
                                        Array.isArray(fields) && fields.length > 1 ?
                                            fields.map((field, index) => (
                                                <Field
                                                    key={field.id}
                                                    field={field}
                                                    index={index}
                                                    onadd={(field) => addFieldToTarget(field)}
                                                />
                                            ))
                                            :
                                            <div
                                                className="flex-row-center-center"
                                                style={{ marginTop: "35%", marginLeft: "40%" }}
                                            >
                                                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                                                    <Bars
                                                        height="80"
                                                        width="80"
                                                        color="var(--theme1_primary)"
                                                        ariaLabel="bars-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={true}
                                                    />

                                                    Loading your data
                                                </div>
                                            </div>
                                    }
                                </>
                        }
                        {provided.placeholder}
                    </FieldList>
                )}
            </Droppable>
        </Row>
    );
}
