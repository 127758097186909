import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import {  Row,  Nav, Tab } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { getFromLS, setToLS } from "../../utils/storage.js";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider.js";

// Themes setting
import themes from "../../themes/themes.json";
import chart_themes from "../../themes/chartThemes.json";

// Dashboard Pills
import ReportDashboard from "../reports_dashboard/ReportDashboard.js";
import ClientDataMapping from "../client_onboarding/ClientDataMapping.js";
import DiffToolMainClientGridPage from "../client_reconciliation/diff_tool_main_client_grid_page/DiffToolMainClientGridPage.js";
import MyClients from "../client_onboarding/MyClients.js";
import ClientOnboardingFormConfig from "../client_onboarding/ClientOnboardingFormConfig.js";
import ClientDataManagementConfig from "../client_onboarding/ClientDataManagementConfig.js";
import MyForms from "../onboarding_form_builder/my_forms/MyForms.js";

// Intelligence report Pills
import { ConfirmationModal } from "./ConfirmationModal.js";
import IndividualVsEntityChart from '../reports_dashboard/reports/CustomersByType.js';
import SingleVsJointFiling from '../reports_dashboard/reports/CustomersByJoinType.js';
import GeoDistMap from "../reports_dashboard/reports/CustomersOfficewiseGeoDistribution.js";
import ExtensionStatus from "../reports_dashboard/reports/CustomersExtensionStatus.js";
import EntityTypeDistribution from "../reports_dashboard/reports/EntityTypeDist.js";
import TopCustomers from "../reports_dashboard/reports/TopCustomersBillingInfo.js";
import MyTemplatesPage from "../engagement_letter/my_templates_page/MyTemplatesPage.js";
import EngagementLetterDetailsPage from "../engagement_letter/engagement_letter_details_page/EngagementLetterDetailsPage.js";
import ApprovalDetailsPage from "../engagement_letter/approval_details_page/ApprovalDetailsPage.js";
import ContentBlocksPage from "../engagement_letter/content_blocks_page/ContentBlocksPage.js";
import WizardFormConfig from "../wizard_form_builder/my_wizard_forms/WizardFormConfig.js";
import MyWizardForms from "../wizard_form_builder/my_wizard_forms/MyWizardForms.js";
import { TemplateMappingsPage } from "../engagement_letter/template_mappings/TemplateMappingsPage.js";
import ClientApproval from "../client_onboarding/ClientApproval.js";
import ApprovalConfig from "../approval_config/ApprovalConfig.js";

const Dashboard = () => {
  // User details
  const firm_id = localStorage.getItem("firmId");
  const user = localStorage.getItem("user");
  const userType = localStorage.getItem("usertype");
  const firmname = localStorage.getItem("firmname")
  const availableFeatures = getFromLS("active-features")
  // console.log("Available features = ", availableFeatures)
  const auth = useAuth();
  const navigate = useNavigate()

  const handleLogout = () => {
    auth.logout();
    localStorage.removeItem("token");
    localStorage.removeItem("firmId");
    localStorage.removeItem("user");
    localStorage.removeItem("username");
    localStorage.removeItem("usertype");
    localStorage.removeItem("firmname");
    localStorage.setItem("active-features", []);
    // localStorage.clear()
    navigate("/login");
  };

  const setThemer = (result) => {
    console.log("SET THEMER: ", result)
    // console.log("GET THEME api result: ", JSON.parse(result.data[0].params[0].parameter_value_1))
    // setAllThemes(JSON.parse(result.data[0].params[0].parameter_value_1));
    if (result.data[0].params.length > 0) {
      setToLS(
        "all-themes",
        JSON.parse(result.data[0].params[0].parameter_value_1)
      );
    }
    // console.log("After setting allThemes: ", allThemes)
  };

  const getThemes = () => {
    const parameter_name = "themes";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ firm_id, parameter_name }),
    };
    // console.log('Get themes requestOptions:`, requestOptions)
    fetch(
      `${process.env.REACT_APP_BASE_URI}/workflow/personalization/get_params/`,
      requestOptions
    )
      .then((result) => result.json())
      .then((res) => setThemer(res));
  };

  useEffect(() => {
    getThemes();
    getActiveThemes(true) // for chart theme
    getActiveThemes(false) // for normal theme
  }, []);

  const setActiveThemes = (chart, activeTheme) => {
    const parameter_value_1 = JSON.stringify(activeTheme);
    const parameter_name = chart ? "activeChartTheme" : "activeTheme";
    const payload = { firm_id, parameter_name, parameter_value_1 };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    fetch(
      `${process.env.REACT_APP_BASE_URI}/workflow/personalization/set_params/`,
      requestOptions
    ).then((result) => result.json());
  };

  const getActiveThemes = (chart) => {
    const parameter_name = chart ? "activeChartTheme" : "activeTheme";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ firm_id, parameter_name }),
    };
    fetch(
      `${process.env.REACT_APP_BASE_URI}/workflow/personalization/get_params/`,
      requestOptions
    )
      .then((result) => result.json())
      .then((res) => storeActiveThemeValues(chart, res))
  }

  const storeActiveThemeValues = (chart, res) => {
    if (res.data[0].params.length === 0 && !chart) {
      setActiveThemes(chart, themes.data["light"])
    } else if (res.data[0].params.length === 0 && chart) {
      setActiveThemes(chart, chart_themes.data["Tableau Classic 10"])
    } else {
      // setActiveThemes(false, themes.data["light"])
      // setActiveThemes(true, chart_themes.data["Tableau Classic 10"])
      !chart ? setToLS("theme", JSON.parse(res.data[0].params[0].parameter_value_1)) : setToLS("chart-theme", JSON.parse(res.data[0].params[0].parameter_value_1))
      // window.location.reload()
    }
  }

  // Sidenav open close feature
  let [isOpen, setIsOpen] = useState(false);
  let [forcedOpen, setForcedOpen] = useState(false);
  let [logoutIcon, setLogoutIcon] = useState(false)
  const openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("dashboard-content").style.marginLeft = "250px";
    setTimeout(() => {
      setIsOpen(true);
    }, 0);
  };
  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "70px";
    document.getElementById("dashboard-content").style.marginLeft = "70px";
    setIsOpen(false);
  };

  const sidenavOpenDoor = () => {
    setLogoutIcon(true)
  }
  const sidenavCloseDoor = () => {
    setLogoutIcon(false)
  }

  const redirectToDatahub = () => {
    let url = null
    if (process.env.REACT_APP_ENV === "dev") {
      url = process.env.REACT_APP_DATAHUB_DEV_URL;
    }
    else if (process.env.REACT_APP_ENV === "prod") {
      url = process.env.REACT_APP_DATAHUB_PROD_URL
    }
    console.log(`this is URL - ${url} and this is environment - ${process.env.REACT_APP_ENV}`)
    if (url) {
      window.open(url, '_blank')
    }
    else {
      console.error("invalid environment configuration")
    }
  
  }

  // Setting default opening pill based on user (this is hard coded right now)
  // Done in Login.js

  // Navigate with props to bring in dynamic behaviour
  /**
   * 
   * @param {*} pill : This arguement tells us where to navigate, there is no default value.
   * @param {*} mapping_option : This arguement is by default null and is only used when navigating to mapping page.
   * @param {*} report_navigator : This is a boolean arguement which tells us if navigation is within Intelligence
   *                               report and we then do not need to update dashboard nav-pills
   */
  const navigateWithData = (pill, options = { mapping_option: null, config_option: null, wizard_config_option: null, report_navigator: false }) => {
    const mapping_option = options.mapping_option;
    const config_option = options.config_option;
    const wizard_config_option = options.wizard_config_option;
    const report_navigator = options.report_navigator;
    if (pill != undefined && pill.length != 0) {
      if (report_navigator) {
        // here we only navigate to report but do not change the active dashboard pill
        setRender(RENDER_MAP[pill]);
      }
      else if (mapping_option != null || mapping_option != undefined) {
        // here we navigate to mapping with a mapping_option for pre-selected dropdown
        // change the active dashboard pill to mapping
        setActiveKey(pill);
        setRender(CustomClientDataMappingRender(mapping_option));
      }
      else if (config_option != null || config_option != undefined) {
        setActiveKey(pill)
        setRender(CustomClientOnboardingFormConfigRender(config_option))
      }
      else if (wizard_config_option != null || wizard_config_option != undefined) {
        setActiveKey(pill)
        setRender(CustomWizardFormConfigRender(wizard_config_option))
      }
      else {
        // here we navigate to pill and change the active dashboard pill to selected Pill
        if (["dynamicform"].includes(activeKey)) {
          setConfirmationModal(true)
          setNavigationData(pill)
        } else {
          setActiveKey(pill);
          setRender(RENDER_MAP[pill]);
        }
      }

    } else if (pill != "empty") {
      setRender(RENDER_MAP[pill]);
    } else {
      console.log("NO PAGE FOUND TO NAVIGATE.")
    }
  };

  const CustomClientDataMappingRender = (mapping_option) => {
    // console.log("IS THIS CUSTOM FUNCTION BEING CALLED?", mapping_option)
    return (
      <ClientDataMapping mapoption={mapping_option} transporter={navigateWithData} />
    )
  }

  const CustomClientOnboardingFormConfigRender = (config_option) => {
    console.log("IS THIS CUSTOM FUNCTION BEING CALLED?", config_option)
    return (
      <ClientOnboardingFormConfig configoption={config_option} transporter={navigateWithData} />
    )
  }

  const CustomWizardFormConfigRender = (wizard_config_option) => {
    console.log("IS THIS CUSTOM FUNCTION BEING CALLED?", wizard_config_option)
    return (
      <WizardFormConfig configoption={wizard_config_option} transporter={navigateWithData} />
    )
  }

  // const CustomMaintenanceReportRender = (report_option) => {
  //   // console.log("IS THIS CUSTOM FUNCTION BEING CALLED?", report_option)
  //   return (
  //     <MaintanenceReports reportoption={report_option} transporter={navigateWithData} />
  //   )
  // }

  /**
   *  Global mapper for page navigation
   *                                !!!!! WARNING !!!!
   *  Please do not change any of the existing keys in RENDER_MAP as they are being used in different places. 
   *                       Doing so may end up breaking the functionality.
   */
  const RENDER_MAP = {
    // Admins
    // superadmin: <SuperAdmin transporter={navigateWithData} />,
    // firmadmin: <FirmAdmin transporter={navigateWithData} />,
    // Dashboard pills: Page component

    dashboard: <ReportDashboard transporter={navigateWithData} />,
    my_forms: <MyForms transporter={navigateWithData} />,
    field_mapping: <CustomClientDataMappingRender />,
    configure_fields: <ClientDataManagementConfig transporter={navigateWithData} />,
    wizard_form_config: <CustomWizardFormConfigRender />,
    client_onboarding_form_config: <CustomClientOnboardingFormConfigRender />,
    logs: <ClientDataMapping transporter={navigateWithData} />,
    client_reconciliation: <DiffToolMainClientGridPage transporter={navigateWithData} />,
    client_approval: <ClientApproval transporter={navigateWithData} />,
    approval_config: <ApprovalConfig transporter={navigateWithData} />,
    clients: <MyClients transporter={navigateWithData} />,
    templates: <MyTemplatesPage transporter={navigateWithData} />,
    client_details: <EngagementLetterDetailsPage transporter={navigateWithData} />,
    approval_details: <ApprovalDetailsPage transporter={navigateWithData} />,
    wizard_form_builder: <MyWizardForms transporter={navigateWithData} />,
    template_mappings: <TemplateMappingsPage transporter={navigateWithData} />,
    content_blocks: <ContentBlocksPage transporter={navigateWithData} />,

    // INNTERNAL REPORTS NAVIGATOR
    ZipCodeMap: <GeoDistMap transporter={navigateWithData} />,
    SingleVsJointFiling: <SingleVsJointFiling transporter={navigateWithData} />,
    IndividualVsEntityChart: <IndividualVsEntityChart transporter={navigateWithData} />,
    ExtensionStatus: <ExtensionStatus transporter={navigateWithData} />,
    EntityTypeDistribution: <EntityTypeDistribution transporter={navigateWithData} />,
    TopCustomers: <TopCustomers transporter={navigateWithData} />

  };

  const [render, setRender] = useState(RENDER_MAP[localStorage.getItem("defaultActiveKey")]);
  const [activeKey, setActiveKey] = useState(localStorage.getItem("defaultActiveKey"));
  const [openSetup, setOpenSetup] = useState(true);
  const [openEngagementLetterSetup, setOpenEngagementLetterSetup] = useState(true);
  const [openApproval, setOpenApproval] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [navigationData, setNavigationData] = useState({})

  const continueNavigation = (navigationData) => {
    setActiveKey(navigationData);
    setRender(RENDER_MAP[navigationData]);
  }

  const SetupToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setOpenSetup(!openSetup)
    );

    return (
      <Nav.Link eventKey="" onClick={decoratedOnClick}>
        <Icon.GearWideConnected />
        {isOpen ? <p>&nbsp;Firm&nbsp;Setup {children}
          {openSetup
            ?
            <Icon.ChevronRight className="chevy" />
            :
            <Icon.ChevronDown className="chevy" />
          }
        </p> : ""}

      </Nav.Link>
    );
  }

  const EngagementLetterSetupToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setOpenEngagementLetterSetup(!openSetup)
    );

    return (
      <Nav.Link eventKey="" onClick={decoratedOnClick}>
        <Icon.GearWideConnected />
        {isOpen ? <p>&nbsp;Engagement&nbsp;Letter {children}
          {openEngagementLetterSetup
            ?
            <Icon.ChevronRight className="chevy" />
            :
            <Icon.ChevronDown className="chevy" />
          }
        </p> : ""}

      </Nav.Link>
    );
  }

  const ApprovalToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setOpenApproval(!openApproval)
    );

    return (
      <Nav.Link eventKey="" onClick={decoratedOnClick}>
        <Icon.ClipboardCheckFill />
        {isOpen ? <p>&nbsp;Approval&nbsp;{children}
          {openSetup
            ?
            <Icon.ChevronRight className="chevy" />
            :
            <Icon.ChevronDown className="chevy" />
          }
        </p> : ""}

      </Nav.Link>
    );
  }



  return (
    <>
      <div className="wrapper">
        <div
          id="mySidenav"
          className="sidenav"
          onMouseEnter={() => openNav()}
          onMouseLeave={() => forcedOpen ? console.log() : closeNav()}
        >
          <ConfirmationModal
            show={confirmationModal}
            onHide={() => setConfirmationModal(false)}
            navigationdata={navigationData}
            stayandsave={() => setConfirmationModal(false)}
            skipandleave={(navigationData) => {
              setConfirmationModal(false)
              continueNavigation(navigationData)
            }
            }
          />
          <Tab.Container
            id="left-tabs-example"
            activeKey={activeKey}
            defaultActiveKey={activeKey}
          >
            <Nav variant="pills" defaultActiveKey={activeKey} style={{ marginBottom: 50 }}>
              {
                <div className="flex-row-center-start go-home" onClick={() => navigate("/dashboard")}>
                  <img className="sidenav-dashboard-logo" src={require("../../images/dashboard_logo.png")} />
                  <p className="typing-text">{isOpen ? <>&nbsp;Cygnus&nbsp;Accounting&nbsp;Hub</> : ""}</p>
                  <div className={forcedOpen ? isOpen ? "sidenav-open" : "sidenav-lock" : "sidenav-lock"}>
                    {
                      forcedOpen ? <Icon.LockFill onClick={() => setForcedOpen(false)} /> : <Icon.UnlockFill onClick={() => setForcedOpen(true)} />
                    }
                  </div>
                </div>
              }
              <hr className="sidenav-divider" />
              {
                // ((availableFeatures.filter(str => str.includes("FLW_"))).length > 0) &&
                <Nav.Item onClick={() => navigateWithData("dashboard")}>
                  <Nav.Link eventKey="dashboard">
                    <Icon.Clipboard2DataFill />
                    {isOpen ? <p>&nbsp;Dashboard&nbsp;</p> : ""}
                  </Nav.Link>
                </Nav.Item>
              }
              {
                // ((availableFeatures.filter(str => str.includes("FLW_"))).length > 0) &&
                <Nav.Item onClick={() => navigateWithData("clients")}>
                  <Nav.Link eventKey="clients">
                    <Icon.FilePersonFill />
                    {isOpen ? <p>&nbsp;Clients&nbsp;</p> : ""}
                  </Nav.Link>
                </Nav.Item>
              }
              {/* {
                // (userType == "Analyzer" || userType == "FirmAdmin") && ((availableFeatures.filter(str => str.includes("RPT_"))).length > 0 || availableFeatures.includes("CGPT")) &&
                <Accordion className="sidenav-dropdown">
                  <Nav.Item>
                    <ApprovalToggle eventKey="0"></ApprovalToggle>
                  </Nav.Item>
                  <Accordion.Collapse eventKey="0">
                    <>
                     {
                        // availableFeatures.includes("CGPT") &&
                        <Nav.Item onClick={() => navigateWithData("client_approval")}>
                          <Nav.Link eventKey="client_approval">
                            <Icon.PersonFillCheck />
                            {isOpen ? <p>&nbsp;Client&nbsp;Approval</p> : ""}
                          </Nav.Link>
                        </Nav.Item>
                      }
                     
                    </>
                  </Accordion.Collapse>
                </Accordion>
              } */}
              {/* {
                // ((availableFeatures.filter(str => str.includes("FLW_"))).length > 0) &&
                <Nav.Item onClick={() => navigateWithData("client_approval")}>
                  <Nav.Link eventKey="client_approval">
                    <Icon.ClipboardCheckFill />
                    {isOpen ? <p>&nbsp;Client&nbsp;Approval&nbsp;</p> : ""}
                  </Nav.Link>
                </Nav.Item>
              } */}
              {
                // availableFeatures.includes("CGPT") &&
                <Nav.Item onClick={() => navigateWithData("client_approval")}>
                  <Nav.Link eventKey="client_approval">
                    <Icon.PersonFillCheck />
                    {isOpen ? <p>&nbsp;Client&nbsp;Approval</p> : ""}
                  </Nav.Link>
                </Nav.Item>
              }
              {
                // ((availableFeatures.filter(str => str.includes("FLW_"))).length > 0) &&
                <Nav.Item onClick={() => navigateWithData("client_reconciliation")}>
                  <Nav.Link eventKey="client_reconciliation">
                    <Icon.Exclude />
                    {isOpen ? <p>&nbsp;Client&nbsp;Reconciliation&nbsp;</p> : ""}
                  </Nav.Link>
                </Nav.Item>
              }
              {/* {
                // ((availableFeatures.filter(str => str.includes("FLW_"))).length > 0) &&
                <Nav.Item onClick={() => navigateWithData("logs")}>
                  <Nav.Link eventKey="logs">
                    <Icon.FileTextFill />
                    {isOpen ? <p>&nbsp;Logs&nbsp;</p> : ""}
                  </Nav.Link>
                </Nav.Item>
              } */}
              {
                // (userType == "Analyzer" || userType == "FirmAdmin") && ((availableFeatures.filter(str => str.includes("RPT_"))).length > 0 || availableFeatures.includes("CGPT")) &&
                <Accordion className="sidenav-dropdown">
                  <Nav.Item>
                    <SetupToggle eventKey="0"></SetupToggle>
                  </Nav.Item>
                  <Accordion.Collapse eventKey="0">
                    <>
                      {
                        // (availableFeatures.filter(str => str.includes("RPT_"))).length > 0 &&
                        <Nav.Item onClick={() => navigateWithData("configure_fields")}>
                          <Nav.Link eventKey="configure_fields">
                            <Icon.Grid1x2Fill />
                            {isOpen ? <p>&nbsp;Configure&nbsp;Fields</p> : ""}
                          </Nav.Link>
                        </Nav.Item>
                      }
                      {
                        // availableFeatures.includes("CGPT") &&
                        <Nav.Item onClick={() => navigateWithData("field_mapping")}>
                          <Nav.Link eventKey="field_mapping">
                            <Icon.Bezier />
                            {isOpen ? <p>&nbsp;Field&nbsp;Mapping</p> : ""}
                          </Nav.Link>
                        </Nav.Item>
                      }
                      {
                        // availableFeatures.includes("CGPT") &&
                        <Nav.Item onClick={() => navigateWithData("my_forms")}>
                          <Nav.Link eventKey="my_forms">
                            <Icon.PostcardFill />
                            {isOpen ? <p>&nbsp;Intake&nbsp;Templates</p> : ""}
                          </Nav.Link>
                        </Nav.Item>
                      }
                      {
                        // availableFeatures.includes("CGPT") &&
                        <Nav.Item onClick={() => navigateWithData("approval_config")}>
                          <Nav.Link eventKey="approval_config">
                            <Icon.GearFill />
                            {isOpen ? <p>&nbsp;Configuration</p> : ""}
                          </Nav.Link>
                        </Nav.Item>
                      }
                    </>
                  </Accordion.Collapse>
                </Accordion>
              }
              {
                // (userType == "Analyzer" || userType == "FirmAdmin") && ((availableFeatures.filter(str => str.includes("RPT_"))).length > 0 || availableFeatures.includes("CGPT")) &&
                <Accordion className="sidenav-dropdown">
                  <Nav.Item>
                    <EngagementLetterSetupToggle eventKey="0"></EngagementLetterSetupToggle>
                  </Nav.Item>
                  <Accordion.Collapse eventKey="0">
                    <>
                      { userType === "FirmAdmin" &&
                        // (availableFeatures.filter(str => str.includes("RPT_"))).length > 0 &&
                        <Nav.Item onClick={() => navigateWithData("wizard_form_builder")}>
                          <Nav.Link eventKey="wizard_form_builder">
                            <Icon.Grid1x2Fill />
                            {isOpen ? <p>&nbsp;Wizard&nbsp;Form&nbsp;Builder</p> : ""}
                          </Nav.Link>
                        </Nav.Item>
                      }
                      { userType === "FirmAdmin" &&
                        // availableFeatures.includes("CGPT") &&
                        <Nav.Item onClick={() => navigateWithData("template_mappings")}>
                          <Nav.Link eventKey="template_mappings">
                            <Icon.Bezier />
                            {isOpen ? <p>&nbsp;Wizard&nbsp;Group&nbsp;Mapping</p> : ""}
                          </Nav.Link>
                        </Nav.Item>
                      }
                      { userType === "FirmAdmin" &&
                        // availableFeatures.includes("CGPT") &&
                        <Nav.Item onClick={() => navigateWithData("content_blocks")}>
                          <Nav.Link eventKey="content_blocks">
                            <Icon.GridFill />
                            {isOpen ? <p>&nbsp;Content&nbsp;Blocks</p> : ""}
                          </Nav.Link>
                        </Nav.Item>
                      }
                      { userType === "FirmAdmin" &&
                        // availableFeatures.includes("CGPT") &&
                        <Nav.Item onClick={() => navigateWithData("templates")}>
                          <Nav.Link eventKey="templates">
                            <Icon.FileTextFill />
                            {isOpen ? <p>&nbsp;Templates</p> : ""}
                          </Nav.Link>
                        </Nav.Item>
                      }
                      { userType === "FirmAdmin" &&
                        // availableFeatures.includes("CGPT") &&
                        <Nav.Item onClick={() => navigateWithData("client_details")}>
                          <Nav.Link eventKey="client_details">
                            <Icon.FilePersonFill />
                            {isOpen ? <p>&nbsp;Engagement&nbsp;Letter&nbsp;Details</p> : ""}
                          </Nav.Link>
                        </Nav.Item>
                      }
                      {
                        // availableFeatures.includes("CGPT") &&
                        <Nav.Item onClick={() => navigateWithData("approval_details")}>
                          <Nav.Link eventKey="approval_details">
                            <Icon.PersonFillCheck />
                            {isOpen ? <p>&nbsp;Approval&nbsp;Details</p> : ""}
                          </Nav.Link>
                        </Nav.Item>
                      }
                    </>
                  </Accordion.Collapse>
                </Accordion>
              }
              {
                <Nav.Item onClick={() => redirectToDatahub()}>
                  <Nav.Link eventKey="redirecttocdh">
                    <Icon.ArrowUpRightSquareFill />
                    {isOpen ? <p>&nbsp;Cygnus Datahub&nbsp;</p> : ""}
                  </Nav.Link>
                </Nav.Item>
              }
              {
                <Row className="sidenav-bottom-container">
                  <div className="sidenav-firm-icon">
                    <Icon.PersonWorkspace style={{ height: 25, width: 25 }} />
                  </div>
                  <div style={{ width: "fit-content", padding: 0, marginLeft: 5 }}>
                    {isOpen ? <p style={{ fontSize: "var(--small_font)", margin: 0 }}>Logged&nbsp;in&nbsp;from&nbsp;</p> : ""}
                    {isOpen ? <p style={{ fontWeight: 600, marginTop: 0, width: 80, maxWidth: 80 }}>{firmname}</p> : ""}
                  </div>
                  {
                    isOpen
                      ?
                      <div
                        className="sidenav-logout-container"
                        onMouseEnter={() => sidenavOpenDoor()}
                        onMouseLeave={() => sidenavCloseDoor()}>
                        {
                          logoutIcon
                            ?
                            <><Icon.DoorOpenFill className="sidenav-logout" onClick={() => handleLogout()} />&nbsp;Logout</>
                            :
                            <><Icon.DoorClosedFill className="sidenav-logout" onClick={() => handleLogout()} />&nbsp;Logout</>
                        }
                      </div>
                      :
                      <></>
                  }
                </Row>
              }

            </Nav>
          </Tab.Container>
        </div>
        <div id="dashboard-content">{render}</div>
      </div>
    </>
  );
};

export default Dashboard;
