import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './CollapsibleToolbar.css';

function Toolbar({ elements }) {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          style={{ marginLeft: 'auto' }} // Align toggle icon to the right
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="flex-column"> {/* Stack elements vertically */}
            {elements.map((element, id) => (
              <Nav.Link key={id} style={{ margin: '5px 0' }}>
                {element}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Toolbar;
