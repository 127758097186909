import React, {
    useState,
    useEffect,
    useCallback
} from "react";
import {
    Row,
    Col,
    Button,
    Modal
} from "react-bootstrap";
import Select from 'react-select';
import * as Icon from "react-bootstrap-icons";
import { logout } from "../../../utils";
import { toast } from "react-toastify";
import { ThreeCircles, Bars } from "react-loader-spinner";
import './ApprovalConfigurationModal.css'

export const ApprovalConfigurationModal = (props) => {
    const [allUsers, setAllUsers] = useState([]);
    const [approvers, setApprovers] = useState([]);
    const [allApprovalOptions, setAllApprovalOptions] = useState();
    const [approvalCheck, setApprovalCheck] = useState(false);
    const [templateId, setTemplateId] = useState(props.templateId);
    const [refreshGrid, setRefreshGrid] = useState(true);
    const [approvalConfigurationApiProgress, setApprovalConfigurationApiProgress] = useState(false);
    const [templateGroups, setTemplateGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [linkGroupApiProgress, setLinkGroupApiProgress] = useState(false);
    const [approvalConfigChanged, setApprovalConfigChanged] = useState(false);
    const [groupLinkChanged, setGroupLinkChanged] = useState(false);
    

    useEffect(() => {
        setTemplateId(props.templateId)
    }, [props.templateId])

    useEffect(() => {
        console.log("ALL APPROVERS: ", approvers)
    }, [approvers])


    useEffect(() => {
        const approvers = []
        const roles = ["SuperAdmin", "FirmAdmin", "Client Data Manager", "Partner", "User"]
        for (let user of allUsers) {
            if (roles.includes(user["Role"])) {
                approvers.push({ "label": `${user["UserName"]} - ${user["Role"]}`, "value": user["FirmDetailId"] })
            }
        }
        setAllApprovalOptions(approvers)
    }, [allUsers])


    useEffect(() => {
        let token = localStorage.getItem("token");
        const getRequestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        fetch(
            `${process.env.REACT_APP_BASE_URI}/workflow/subscriptions/roles/users/`,
            getRequestOptions
        )
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((result) => setAllUsers(result["data"]))
    }, [])

    const fetchTemplateApprovalConfigDetails = (templateId) => {
        let token = localStorage.getItem("token");
        const getRequestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        if (templateId !== undefined) {
            setRefreshGrid(true);

            fetch(
                `${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/template_approval_configuration_details/?TemplateId=${templateId}`,
                getRequestOptions
            )
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((result) => {
                if (result["data"].length > 0) {
                    setApprovers(result["data"]);
                    setApprovalCheck(result["data"][0]["ApprovalRequired"] !== "Y");
                }
            })
            .finally(() => {
                setRefreshGrid(false);
            });
        }
    };
    
    useEffect(() => {
        fetchTemplateApprovalConfigDetails(templateId);
    }, [templateId]);

    const addApprover = () => {        
        // Get the last input field in the array
        const lastField = approvers[approvers.length - 1];

        const new_approver = {
            "TemplateApprovalConfigId": 0,
            "TemplateApprovalConfigDetailId": 0,
            "TemplateId": templateId,
            "ApprovalRequired": !approvalCheck ? "Y" : "N",
            "FirmDetailId": 0,
            "ApproverName": "",
            "ApproverEmail": "",
            "ApproverRole": "",
            "Active": "Y"
        }

        console.log(lastField)
        // Only add a new field if the last field is not blank (has some value)
        if (lastField === undefined || lastField.ApproverName === null || lastField.ApproverName.trim() !== '') {
            const temp_approvers = [...approvers.filter(approver => approver.Active !== null), new_approver];
            setApprovers(temp_approvers);
            setApprovalConfigChanged(true);
        }
        else {
            toast.error(
                `Please select an approver before adding a new one.`,
                {
                    position: "bottom-right",
                    autoClose: 2500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    className: "toastify-color-error",
                }
            );
            console.log("Please select an approver before adding a new one.");
        }
    }
    
    const removeApprover = (approverName, approverRole, index) => {
        console.log(approverName, approverRole, index);
    
        // Find the actual approver by config_details_id
        const newApprovers = approvers.map((approver) => {
            // If the config_details_id matches
            if (approver.ApproverName === approverName && approver.ApproverRole === approverRole) {
                if (approver.TemplateApprovalConfigDetailId === 0) {
                    return null; // Return null to remove the approver
                } else {
                    // Set Active to "N" if TemplateApprovalConfigDetailId is not 0
                    return { ...approver, Active: "N" };
                }
            }
            return approver; // Return unchanged approver
        }).filter(Boolean); // Filter out the null (removed) approvers

        setApprovers(newApprovers);
        setApprovalConfigChanged(true);
    };
     

    const saveApprovalConfiguration = () => {
        const filtered_approvers = approvers
        .filter(approver => approver.FirmDetailId !== 0)
        .map((approver, index) => {
            return { ...approver, ApprovalRequired: "Y" };
        })

        console.log("filtered_approvers", filtered_approvers);
        if(!approvalCheck && !filtered_approvers.length) {
            console.log("Empty", filtered_approvers);
            props.onHide();
            return;
        }

        const skip_approver = [{
            "TemplateId": templateId,
            "ApprovalRequired": "N",
            "FirmDetailId": 0,
        }]
        
        const payload = {
            ApprovalConfigs: approvalCheck 
                ? skip_approver 
                : filtered_approvers
        };
        console.log("Hello approvers payload: ", payload);

        setApprovalConfigurationApiProgress(true);
        let token = localStorage.getItem("token");
        
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(payload),
        };
        
        fetch(
            `${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/template_approval_configuration_details/`,
            requestOptions
        )
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized error
                    logout();
                    alert("Session ended , Please login back");
                } else {
                    throw new Error("Request failed.");
                }
            }
            return response.json();
        })
        .then((data) => {
            if (data.success === true) {
                toast.success(
                    "Save approval configuration successful!",
                    {
                        position: "bottom-right",
                        autoClose: 2500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        className: "toastify-color-success",
                    }
                );
                setApprovalConfigurationApiProgress(false);
                console.log("Approval SAVED DATA: ", data.data);
                props.onHide();
                props.fetchTemplateData();
            } else {
                setApprovalConfigurationApiProgress(false)
                toast.error(
                    "Error while fetching data: " + data.message,
                    {
                        position: "bottom-right",
                        autoClose: 2500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        className: "toastify-color-error",
                    }
                );
            }
        })
        .catch((err) => console.log("Err: ", err));
    }

    useEffect(() => {
        getAllGroups();
    }, []);

    const getAllGroups = useCallback(async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };
        
        setRefreshGrid(true);
    
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement_letter_wizard/template_wizard_group_mappings`, requestOptions);
    
            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized error
                    logout();
                    alert("Session ended , Please login back");
                } else {
                    throw new Error("Request failed.");
                }
            }
    
            const data = await response.json();
            
            const details = data.data.map(group => ({
                value: group.WizardGroupID,
                label: group.GroupName
            }));
            
            setTemplateGroups(details);
            console.log(props);
            console.log(props.templateData);
            if (props.templateData) {
                const { WizardGroupId, GroupName } = props.templateData;
                if (WizardGroupId) {
                    setSelectedGroup({
                        value: WizardGroupId, 
                        label: GroupName
                    })
                }
            }                       
    
        } catch (error) {
            console.error('Error fetching template groups data:', error);
        } finally {
            setRefreshGrid(false);
        }
    }, [])

    const handleChange = (selectedOption) => {
        setSelectedGroup(selectedOption);
        // console.log(selectedOption);
        setGroupLinkChanged(true);
    };

    const linkTemplateGroup = () => {       
        // console.log(props.templateData.TemplateId)
        // console.log(props.templateId)
        const requestBody = {            
            TemplateId: props.templateId ?? props.templateData.TemplateId,            
            WizardGroupId: selectedGroup.value
        }

        setLinkGroupApiProgress(true);        
        // console.log(`in link template group - ${requestBody}`);         
        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/templates/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(requestBody)
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized error
                    logout();
                    alert("Session ended , Please login back");
                } else {
                    throw new Error("Request failed.");
                }
            }

            return response.json();
        })
        .then((data) => {
            if (data.success) {                
                toast.success(
                    "Link Group successful!",
                    {
                        position: "bottom-right",
                        autoClose: 2500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        className: "toastify-color-success",
                    }
                );
            } else {
                toast.error(
                    `Link Group failed: ${data.message}`,
                    {
                        position: "bottom-right",
                        autoClose: 2500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        className: "toastify-color-error",
                    }
                );
            }
        })
        .catch(error => {
            // Handle error
            console.error('Error:', error);
            toast.error(
                "Error in Linking Group to Template",
                {
                    position: "bottom-right",
                    autoClose: 2500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    className: "toastify-color-error",
                }
            );
        })
        .finally(() => {
            setLinkGroupApiProgress(false);
            props.onHide();
            props.fetchTemplateData();
        });
        
    }

    const saveTemplateSettings = async () => {
        const promises = []
        if (approvalConfigChanged) {            
            promises.push(saveApprovalConfiguration());
        }
        if (groupLinkChanged) {
            promises.push(linkTemplateGroup());
        }

        try {            
            await Promise.all(promises);                    
            setApprovalConfigChanged(false);
            setGroupLinkChanged(false);        
            // props.onHide();            
        } catch (error) {
            console.error('Error in saving configurations:', error);
        }
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            scrollable="false"
            style={{
                maxHeight: 500,
                overflow: "auto",
                top: "20%"
            }}
        >
            <Modal.Header closeButton>
                <h5 style={{ marginBottom: 0, marginRight: 20, marginLeft: 10 }}>Settings</h5>
            </Modal.Header>
            <Modal.Body className={!refreshGrid ? "flex-column-start-center" : "flex-row-center-center"}>
                {!refreshGrid
                    ?
                    <>
                        <Row style={{ marginBottom: 20, width: '100%' }}>
                        <div style={{ margin: "0px 10px" }} className={"flex justify-content-center align-items-center"}>

                            <div className="flex-row-space-center">
                                <span className="block-select-label">Group Name</span>
                            </div>
                            <Select
                                value={selectedGroup}
                                onChange={handleChange}
                                options={templateGroups}
                                className="form-select custom-select"
                                aria-label="Default"
                                closeMenuOnSelect={true}
                                autosize={true}
                                placeholder="Select a group"
                                menuPosition="fixed"
                                classNamePrefix="react-select"
                            />
                            
                        </div>
                    </Row>
                        <Row style={{ marginBottom: 20 }}>
                            <div className={"pad-trbl-10 pivot-check-holder"}>
                                <label style={{ margin: "0px 5px" }}>No approval needed</label>
                                <input type="checkbox" className={"form-check-input move-left custom-check-input pivot-check"}
                                    style={{ margin: "0px 0px" }}
                                    checked={approvalCheck}
                                    onChange={event => {setApprovalCheck(event.target.checked); setApprovalConfigChanged(true)}}
                                    autoComplete="new-password"
                                />
                            </div>
                        </Row>
                        {
                            !approvalCheck ?
                                <>
                                    <Row style={{ width: "100%", marginBottom: 5 }} className="flex-row-center-start">
                                        <h5 style={{ marginBottom: 0, marginLeft: 10, width: "fit-content" }}>Approvers</h5>
                                        {
                                            !approvalCheck ?
                                                <Icon.PlusCircleFill
                                                    style={{
                                                        color: "var(--theme1_primary)",
                                                        fontSize: 20,
                                                        width: "fit-content",
                                                        cursor: "pointer",
                                                        paddingLeft: 0
                                                    }}
                                                    onClick={addApprover}
                                                /> :
                                                <></>
                                        }
                                    </Row>
                                    {
                                        approvers
                                        .filter(approver => approver.Active === "Y")
                                        .map((approver, index) =>
                                            <Row className="flex-row-center-center"
                                                style={{
                                                    margin: "10px 0px 10px 0px",
                                                    width: "100%"
                                                }}>
                                                <Col md={3}><h6 style={{ marginBottom: 0 }}>Approver {index + 1} </h6></Col>
                                                <Col md={8}>
                                                    <Select
                                                        value={{
                                                            label: approver["FirmDetailId"] ? `${approver["ApproverName"]} - ${approver["ApproverRole"]}` : "",
                                                            value: approver["FirmDetailId"]
                                                        }}
                                                        onChange={(e) => {
                                                            const apps = approvers.map(app => {
                                                                if (app.ApproverName === approver.ApproverName && app.ApproverRole === approver.ApproverRole) {
                                                                    const [approverName, approverRole] = e.label.split(' - ');
                                                                    return {
                                                                        ...app,
                                                                        ApproverName: approverName,
                                                                        ApproverRole: approverRole,
                                                                        FirmDetailId: e.value,
                                                                        Active: "Y"
                                                                    };
                                                                }
                                                                return app;
                                                            });
                                                    
                                                            setApprovers(apps);
                                                            setApprovalConfigChanged(true);
                                                        }}
                                                        options={allApprovalOptions.filter(option => {
                                                            // Filter out options already selected in other approvers
                                                            return !approvers.some(app => 
                                                                app.ApproverName === option.label.split(' - ')[0] && 
                                                                app.ApproverRole === option.label.split(' - ')[1] && 
                                                                app.Active === "Y"
                                                            );
                                                        })}
                                                        className="form-select custom-select"
                                                        aria-label="Default"
                                                        closeMenuOnSelect={true}
                                                        autosize={true}
                                                        placeholder="Select approver"
                                                        menuPosition="fixed"
                                                        classNamePrefix="react-select"
                                                    /></Col>
                                                <Col md={1}>
                                                    <Icon.DashCircleFill
                                                        style={{
                                                            color: "var(--theme1_primary)",
                                                            fontSize: 20,
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => removeApprover(approver.ApproverName, approver.ApproverRole, index)} 
                                                    />
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </>
                                :
                                <></>
                        }
                    </>
                    :
                    <div
                        className="flex-row-center-center"
                    >
                        <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                            <Bars
                                height="80"
                                width="80"
                                color="var(--theme1_primary)"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
    
                            Loading your data
                        </div>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer className="action-footer">
                <Row className='no-gutter' style={{ padding: 0, margin: 0 }}>
                    <Col md={6} style={{ width:"fit-content" }}>
                        <Button
                            onClick={saveTemplateSettings}
                            className={approvalConfigurationApiProgress || linkGroupApiProgress ? "api-progress" : "custom-button"}
                            style={{ maxHeight: "30px" }}
                            disabled={!approvalConfigChanged && !groupLinkChanged}
                        >   
                            {
                                approvalConfigurationApiProgress || linkGroupApiProgress ?
                                <>
                                    <ThreeCircles
                                        height="15"
                                        width="15"
                                        color="#4fa94d"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel="three-circles-rotating"
                                        outerCircleColor="var(--theme1_primary)"
                                        innerCircleColor="var(--theme1_secondary)"
                                        middleCircleColor="var(--theme1_tertiary)"
                                    />
                                </>
                                :
                                <p className="icon-wrapper" style={{ marginBottom: 0, marginTop: 0 }}>
                                    OK
                                </p>
                                                                       
                            }
                        </Button>
                    </Col>
                    <Col md={6} style={{ padding: "0px 10px 0px 0px", width:"fit-content" }}>
                        <Button
                            onClick={() => props.onHide("cancel")}
                            className="custom-button"
                        >
                            <p className="icon-wrapper" style={{ marginBottom: 0, marginTop: 0 }}>
                                Cancel
                            </p>
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}