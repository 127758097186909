import React, { useEffect, useState, useCallback } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import './ChartTable.css';
import { Row } from "react-bootstrap";
import { Tabs, Tab } from 'react-bootstrap';
import _ from "lodash";
import * as Icon from "react-bootstrap-icons";
import { Bars } from "react-loader-spinner";
// import { CSSTransition } from 'react-transition-group';
import { ActionGrid } from './Grid';
import { useNavigate } from 'react-router-dom';

const IndividualVsEntityChart = () => {
  const [isLoading, setLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [clickedSeriesData, setClickedSeriesData] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [noDataMessage, setNoDataMessage] = useState('');
  const [slices, setSlices] = useState([]);
  const [activeTab, setActiveTab] = useState('chart');
  const [tableHeader, setTableHeader] = useState('');
  const [originalData, setOriginalData] = useState(null);
  const [originalTableData, setOriginalTableData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        // const response = await fetch(`${process.env.REACT_APP_BASE_URI}/cust_type_reports/`, {
        const response = await fetch(`${process.env.REACT_APP_BASE_URI_REPORTS}/cust_type_reports/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            alert("Session ended, Please login back");
          } else {
            throw new Error("Request failed.");
          }
        }

        const data = await response.json();
        setOriginalData(data);
        setChartData(data);

        if (!data || !data.Success || !data.Data || data.Data.length === 0) {
            setNoDataMessage('No data currently available.');
          } else {
            setNoDataMessage('');
          }

          const responseTableData = await fetch(`${process.env.REACT_APP_BASE_URI_REPORTS}/workflow/get_default`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
          });
    
          if (!responseTableData.ok) {
            throw new Error('Request failed.');
          }
    
          const seriesData = await responseTableData.json();
          if(seriesData) {
            setClickedSeriesData(seriesData.Data);
            setOriginalTableData(seriesData.Data);
            setShowTable(true);
          }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    setTimeout(() => {
      setLoading(false);
    }, 6000);
  }, []); 

  const handleBackClick = () => {
    navigate('/dashboard'); 
  };

  const resetData = () => {
    if (originalData) {
      setChartData(originalData);
      setSlices(chartData.Data.map(item => ({
        name: item.custType,
        y: item.count
    })));
    if(originalTableData) {
      setClickedSeriesData(originalTableData);
      setShowTable(true);
      setTableHeader(null);
    }
    updateChart();
    setActiveTab('chart');
    }
  };
const handlePieSliceClick = async (event) => {
    // Extract the custType from the clicked point
    const custType = event.point.name;
    
    if(custType === 'Entity') {
      try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URI_REPORTS}/cust_entity_subtype/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          alert("Session ended, Please login back");
        } else {
          throw new Error("Request failed.");
        }
      }

      const data = await response.json();
      const subpies = data.Data.map(item => ({
        name: item.entityType,
        y: item.count
    }));

    // Update the chart with subpies
    // const chart = event.point.series.chart;
    // chart.addSeries({
    //     type: 'pie',
    //     name: 'Entity Subtypes',
    //     data: subpies
    // });

    const entitySeries = event.point.series;

    const existingEntityPoint = entitySeries.points.find(point => point.name === 'Entity');
    if (existingEntityPoint) {
        existingEntityPoint.remove();
    }

    // Add subpies data to 'Entity' series
    for (const subpie of subpies) {
        entitySeries.addPoint(subpie);
    }
    
    
} catch (error) {
    console.error('Error fetching data:', error);
}
    }
    else {
    // Make a POST call to get series data
    try {
      let seriesData;
      if(custType === 'Individual') {
        const response = await fetch(`${process.env.REACT_APP_BASE_URI_REPORTS}/cust_type_reports/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          },
          body: JSON.stringify({ custType: custType }),
        });

        if (!response.ok) {
          throw new Error('Request failed.');
        }

        seriesData = await response.json();
    }
    else {
      const response = await fetch(`${process.env.REACT_APP_BASE_URI_REPORTS}/cust_entity_subtype/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify({ subtype: custType }),
      });

      if (!response.ok) {
        throw new Error('Request failed.');
      }

      seriesData = await response.json();
    }
      if(seriesData) {
        setClickedSeriesData(seriesData.Data);
        setActiveTab('table');
        setShowTable(true);
        setTableHeader(custType);
    }
    } catch (error) {
      console.error('Error making POST request:', error);
    }
  }
  };

useEffect(() => {
  if (chartData && chartData.Success) {
      setSlices(chartData.Data.map(item => ({
          name: item.custType,
          y: item.count
      })));
  }
}, [chartData]);

const updateChart = async => {
  // console.log('update chart called');
  const options = {
    ...primaryOptions,
    series: [{
      data: slices,
      point: {
        events: {
          click: handlePieSliceClick,
        },
      },
    }],
  };
      return (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      );
}
let gridApi;
  const onGridReady = useCallback((params) => {
    gridApi = params.api;
    gridApi.closeToolPanel();
  }, []);

const renderTable = () => {
  if (clickedSeriesData && clickedSeriesData.length > 0 && showTable) {
    return (<>
   <h5>
   {tableHeader === null || tableHeader === '' ? 'Client Details' : (
  tableHeader === 'Individual' ? 'Client Type: ' : 'Entity Subtype: '
) + ' ' + tableHeader}
  {/* {tableHeader === 'Individual' ? 'Client Type: ': 'Entity Subtype: '} {tableHeader}  */}
</h5>
    <ActionGrid data = {clickedSeriesData} onGridReady={onGridReady} />
    </>);
  }
};

      // Creating the Highchart
      const primaryOptions = {
        chart: {
            type: 'pie' 
        },
        title: {
            text: 'Client Type Distribution'
        },
        plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: false
              },
              showInLegend: true,
              style: {
                color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
            }
          }
      },
      legend: {
        enabled: true,
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        x: 0,
        y: 0
    },
    
          tooltip: {
              pointFormat: '<b>Clients</b>: {point.y}'
            },
        series: [{
            name: 'Number of Clients',
            data: slices,
            showInLegend: true,
            point: {
                events: {
                    click: handlePieSliceClick,
                },
            },
        }]
    };

    const renderChart = () => {
      if (!chartData || !chartData.Success || !chartData.Data || chartData.Data.length === 0) {
        return <p>No data currently available.</p>;
      }
    
      const slices = chartData.Data.map((item) => ({
        name: item.custType,
        y: item.count,
      }));
    
      const options = {
        ...primaryOptions,
        series: [{
          data: slices,
          point: {
            events: {
              click: handlePieSliceClick,
            },
          },
        }],
      };
      return (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      );
    };

return (
  <div>
    {isLoading && (
      <div className="loading-container" style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
      <Row className="dashboard-row">
        <div className="spinner-container" style={{ margin: "0 auto", textAlign: "center" }}>
          <div className="spinner">
            <Bars
              height="80"
              width="80"
              color="var(--theme1_primary)"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
            <h5 style={{ display: "inline" }}>Loading Client Type Report</h5>
          </div>
        </div>
      </Row>
    </div> )}
    {!isLoading && (
      <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-1rem', float: 'right' }}>
       {/* <button className='btn-mini-card' onClick={handleBackClick}>
        <Icon.ArrowLeftCircleFill className="report-transporter" />
      </button> */}
      <button className='btn-mini-card' onClick={resetData}>
        <Icon.ArrowClockwise className="report-transporter" />
      </button>
      </div>
      <Tabs activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
      <Tab eventKey="chart" title="Chart View">
        {renderChart()}
      </Tab>
      <Tab eventKey="table" title="Table View">
        <Row style ={{height: "80vh"}}>
            {renderTable()}
          </Row>
      </Tab>
    </Tabs>
    </>
      )}
    </div>
  );
};

export default IndividualVsEntityChart;
