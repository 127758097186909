import React, { useState, useEffect, useCallback } from "react";
import { Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { logout } from "../../../utils";
import { ThreeCircles } from "react-loader-spinner";
import Select from "react-select";

const ExistingClientSelector = (props) => {
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState({
    lable: "",
    value: "",
  });
  const [clientAPIProgress, setClientAPIProgress] = useState(false);
  useEffect(() => {
    console.log("API PROGRESS: ", clientAPIProgress);
  }, [clientAPIProgress]);

  // Debounce function to delay the API call
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Function to fetch options from the API
  const fetchOptions = async (inputValue) => {
    setClientAPIProgress(true);
    if (!inputValue) return [];
    const payload = {
      search_token: inputValue, // Replace this with the appropriate payload
    };
    const postOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(payload),
    };

    fetch(
      `${process.env.REACT_APP_BASE_URI}/workflow/forms/fetch_clients_by_token/`,
      postOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            // Handle unauthorized error
            logout();
            alert("Session ended , Please login back");
          } else {
            throw new Error("Request failed.");
          }
        }
        return response.json();
      })
      .then((data) => {
        if (data.success === true) {
          const optionList = data.data.map((client) => ({
            label: `${client["Client Name"]}`,
            value: client.ClientMasterId,
          }));
          setClientOptions(optionList);
          setClientAPIProgress(false);
          console.log(optionList);
          return optionList;
        } else {
          setClientAPIProgress(false);
          toast.error("Unable to fetch data, " + data.message, {
            position: "bottom-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "toastify-color-error",
          });
        }
      })
      .catch((err) => console.log("Err: ", err));
  };

  const debouncedFetchOptions = useCallback(debounce(fetchOptions, 500), []);

  // Function to handle selection
  const handleChange = (selectedOption) => {
    setSelectedClient(selectedOption);
    console.log("Selected option:", selectedOption);
  };

   // Handle input change (manual debounce can be added here if needed)
   const handleInputChange = (inputValue) => {
    if (inputValue && inputValue.length >= 3) {
      debouncedFetchOptions(inputValue);
    }
  };

  return (
    <Row>
      <label className="form-label move-left">
        {clientAPIProgress ? (
          <>
            <Row className="flex-row-center-center" style={{ marginLeft: 5 }}>
              <div style={{ width: "fit-content", padding: 0 }}>
                <ThreeCircles
                  height="15"
                  width="15"
                  color="#4fa94d"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                  outerCircleColor="var(--theme1_tertiary)"
                  innerCircleColor="var(--theme1_secondary)"
                  middleCircleColor="var(--theme1_primary)"
                />
              </div>
              <div style={{ width: "fit-content" }}>
                Loading your clients ...
              </div>
            </Row>
          </>
        ) : (
          "Search client (please enter atleast 3 characters)"
        )}
      </label>
      <Select
        options={clientOptions} // Dynamically updated options
        onInputChange={handleInputChange} // Fetch options on input change
        onChange={handleChange} // Handle selection
        placeholder="Search..."
        value={selectedClient} // Selected client
        isLoading={clientAPIProgress} // Show loading spinner
        noOptionsMessage={() => "Start typing to search..."}
        aria-label="Default"
        autosize={false}
        className='custom-select '
        classNamePrefix="react-select"
      />
    </Row>
  );
};

export default ExistingClientSelector;
