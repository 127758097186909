import React, {
    useState,
    useEffect
} from "react";
import {
    Row,
    Col,
    Modal,
    Form
} from "react-bootstrap";
import { getFromLS } from "../../utils/storage";
import { FormContext } from "../reusable_components/input_fields/FormContext"
import Element from '../reusable_components/input_fields/Input';
import { logout } from "../../utils";
import { toast } from "react-toastify";
import { ConfirmationModal } from "./ConfirmationModal";
import CustomButton from "../reusable_components/custom_button_component/Button";
import { ApprovalMessageModal } from "./ApprovalMessageModal";

export const PreviewFullWindowModal = (props) => {
    // console.log("PFWM PROPS: ", props)
    const [previewComponents, setPreviewComponents] = useState([{}])
    const [formTitle, setFormTitle] = useState("")
    const [formUrl, setFormUrl] = useState("")
    const [id, setId] = useState()
    const [update, setUpdate] = useState(false)
    const [approval, setApproval] = useState(false)
    const [approvalRadio, setApprovalRadio] = useState()
    const [confirmationModalShow, setConfirmationModalShow] = useState(false)
    const [approvalMessageModal, setApprovalMessageModal] = useState(false)
    const [continuationAfterConfirmation, setContinuationAfterConfirmation] = useState(false)
    const [status, setStatus] = useState()
    const [forApproval, setForApproval] = useState(false)
    const [formId, setFormId] = useState()
    const [clientApprovalApiProgress, setClientApprovalApiProgress] = useState(false)

    // useEffect(() => {
    //     console.log("RADIO VALUE: ", approvalRadio)
    // }, [approvalRadio])

    useEffect(() => {
            // console.log("PC: ", props.previewcomponents)
        if (props.previewcomponents.length !==0) {
            const newComponents = [...props.previewcomponents.previewComponent]
            for (let component of newComponents) {
                if (component["approval"]) {
                    Object.assign(component, { "show": false })
                }
            }
            setPreviewComponents(newComponents)
            setId(props.previewcomponents.id)
            setUpdate(props.previewcomponents.update)
            setApproval(props.previewcomponents.approval === undefined ? false : props.previewcomponents.approval)
        }
    }, [props.previewcomponents])
    
    // useEffect(() => {
    //   console.log("Preview Components: ", previewComponents)
    // }, [previewComponents])
    

    useEffect(() => {
        setFormTitle(props.formtitle)
    }, [props.formtitle])
    useEffect(() => {
        // console.log("Form URLLLLLLLLLLLLLLLLLLL: ", props.formurl)
        setFormUrl(props.formurl)
    }, [props.formurl])

    useEffect(() => {
        setForApproval(props.forapproval === undefined ? false : true)
    }, [props.forapproval])

    useEffect(() => {
        if (props.formid !== undefined) {
            setFormId(props.formid)
        }
    }, [props.formid])



    // useEffect(() => {
    //     console.log("PREVIEW COMPONENTS: ", previewComponents)
    // }, [previewComponents])


    /**
     * Update the values of fields in the PREVIEW form
     * @param {*} field_id: ID of field to be updated 
     * @param {*} event: The trigger event of input in preview form
     */
    const handleChange = (field_id, event) => {
        const newList = [...previewComponents]
        newList.forEach(field => {
            const { type, id } = field;
            if (field_id === id) {
                switch (type) {
                    case 'checkbox':
                        field['value'] = event.target.checked;
                        break;

                    case 'select':
                        field['value'] = event.value;
                        break;

                    case 'file':
                        field['value'] = event[0];
                        break;

                    case 'date':
                        field['value'] = event;
                        break;

                    default:
                        field['value'] = event.target.value;
                        break;
                }
            }
            const newListTwo = [...newList]
            setPreviewComponents(newListTwo)
        })
    }

    const confirmModal = (status) => {
        // console.log("APPROVAL RADIO: ",  approvalRadio)
        if (forApproval) {
            setStatus(status)
            setApprovalMessageModal(true)
            // approveRecord(status)
        } else {
            if (approvalRadio === undefined && !update) {
                setConfirmationModalShow(true)
            } else {
                uploadData(formUrl)
            }
        }
    }

    useEffect(() => {
        if (continuationAfterConfirmation) {
            uploadData(formUrl)
        }
    }, [continuationAfterConfirmation])

    const approvalMessageInput = (message) => {
        // console.log("MESSAGE: ", message)
        // console.log("SELECTED STATUS: ", status)
        if (status !== undefined) {
            approveRecord(message, status)
        } else {
            toast.warn(
                "Unable to update the status. Please try again.",
                {
                    position: "bottom-right",
                    autoClose: 2500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    className: "toastify-color-warn",
                }
            );
        }
    }

    const approveRecord = (message, status) => {
        setClientApprovalApiProgress(true)
        let payloadFormat = {
            "ClientMasterId": id,
            "FormId": formId,
            "ApprovalMessage": message,
            "ApprovalStatus": status
        }
        const token = getFromLS("token")
        const postOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payloadFormat),
        };
        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/form/approval/`, postOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                setStatus(undefined)
                if (data.success === true) {
                    setClientApprovalApiProgress(false)
                    props.onHide()
                    props.refresh()
                    toast.success(
                        "Client successfully updated!",
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-success",
                        }
                    );
                } else {
                    setClientApprovalApiProgress(false)
                    toast.error(
                        "Saving failed, " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch((err) => console.log("Err: ", err));
    }

    const uploadData = () => {
        setClientApprovalApiProgress(true)
        let payloadFormat;
        let postOptions;
        if (update) {
            payloadFormat = {
                "ClientMasterId": id,
                "Response": previewComponents,
            }
            const token = getFromLS("token")
            postOptions = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payloadFormat),
            };
            fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/${formUrl}/`, postOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.success === true) {
                        setClientApprovalApiProgress(false)
                        props.onHide()
                        props.refresh()
                        toast.success(
                            "Client successfully updated!",
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-success",
                            }
                        );
                    } else {
                        setClientApprovalApiProgress(false)
                        toast.error(
                            "Saving failed, " + data.message,
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-error",
                            }
                        );
                    }
                })
                .catch((err) => console.log("Err: ", err));
        } else {
            payloadFormat = {
                "Response": previewComponents,
                "ApprovalStatus": approvalRadio === undefined ? "pre_approved" : approvalRadio
            }
            const token = getFromLS("token")
            postOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payloadFormat),
            };
            fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/${formUrl}/`, postOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.success === true) {
                        setClientApprovalApiProgress(false)
                        props.onHide()
                        props.refresh()
                        toast.success(
                            "Client successfully saved!",
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-success",
                            }
                        );
                    } else {
                        setClientApprovalApiProgress(false)
                        toast.error(
                            "Saving failed, " + data.message,
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-error",
                            }
                        );
                    }
                })
                .catch((err) => console.log("Err: ", err));
        }
    }
    return (
        <>
            <ConfirmationModal
                show={confirmationModalShow}
                onHide={() => setConfirmationModalShow(false)}
                setcontinuationafterconfirmation={(flag) => setContinuationAfterConfirmation(flag)}
            />
            <ApprovalMessageModal
                show={approvalMessageModal}
                onHide={() => setApprovalMessageModal(false)}
                approvalcontinuation={(message) => approvalMessageInput(message)}
            />
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                backdropClassName="custom-backdrop"
                centered
                className="full-modal"
                scrollable="false"
            >
                <Modal.Header className="preview-modal row no-gutter" closeButton>
                    <Col md={2}></Col>
                    <Col md={7}>
                        <h4 style={{ margin: "10px 0px 20px 0px", textAlign: "center" }}>{formTitle}</h4>
                    </Col>
                    <Col md={2}>
                        <Row className="no-gutter flex-row-center-center">
                            <CustomButton
                                progress={clientApprovalApiProgress}
                                onclick={() => confirmModal("approved")}
                                content={forApproval ? "Approve" : "Save"}
                            />
                            <CustomButton
                                progress={clientApprovalApiProgress}
                                onclick={() => confirmModal("rejected")}
                                content={forApproval ? "Reject" : "Cancel"}
                            />
                        </Row>
                    </Col>
                </Modal.Header>
                <Modal.Body className="preview-modal">
                    <Row className='no-gutter'>
                        <Col md={1}></Col>
                        <Col md={10}>
                            <FormContext.Provider value={{ handleChange }}>
                                <Form className='no-padding'>
                                    <Row className='no-gutter'>
                                        {
                                            previewComponents &&
                                            previewComponents.map((field, i) =>
                                                <Element
                                                    key={i}
                                                    field={field}
                                                />
                                            )
                                        }
                                    </Row>
                                </Form>
                            </FormContext.Provider>
                        </Col>
                        <Col md={1}></Col>
                    </Row>

                    {
                        !update && !forApproval && !approval ?
                            <Row className="no-gutter flex-row-center-center" style={{ marginTop: 20 }}>
                                <Col md={1}></Col>
                                <Col md={10} className="flex-row-center-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="send_for_approval" value="send_for_approval" onClick={() => setApprovalRadio('send_for_approval')} />
                                        <label class="form-check-label" for="send_for_approval">Send for approval</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="pre_approved" value="pre_approved" onClick={() => setApprovalRadio('pre_approved')} />
                                        <label class="form-check-label" for="pre_approved">Pre-approve</label>
                                    </div>
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                            :
                            <></>
                    }

                </Modal.Body>
                <Modal.Footer className="action-footer">
                </Modal.Footer>
            </Modal>
        </>
    )
}