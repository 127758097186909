import React, {
    useState
} from "react";
import {
    Row
} from "react-bootstrap";
import FormLayout from "../onboarding_form_builder/Layout";

const ClientOnboardingFormConfig = (props) => {
    console.log("PROPSYYY: ", props)
    const [clientType, setClientType] = useState(props.configoption.clientType)
    const [targetFields, setTargetFields] = useState(props.configoption.targetFields)
    const [formId, setFormId] = useState(props.configoption.formId)
    const [createFormOption, setCreateFormOption] = useState(props.configoption.createform_option)
    return (
        <>
        {
            createFormOption !== undefined
            ?
            <Row className="no-gutter flex-row-center-center">
                {/* JUGAAD WITH FIELDS TO NOT SEND EMPTY */}
                <FormLayout fields={[]} createformoption={createFormOption} transporter={props.transporter}/>
            </Row>
            :
            <Row className="no-gutter flex-row-center-center">
                <FormLayout clienttype={clientType} fields={targetFields !== undefined ? targetFields : []} createformoption={{}} transporter={props.transporter}/>
            </Row>
        }
        </>
    )
}
export default ClientOnboardingFormConfig