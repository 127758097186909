import React, {
    useState,
    useEffect
} from "react";
import {
    Row,
    Col,
    Modal
} from "react-bootstrap";
import Select from 'react-select';
import * as Icon from "react-bootstrap-icons";
import { logout } from "../../utils";
import { toast } from "react-toastify";
import CustomButton from "../reusable_components/custom_button_component/Button";
import { Bars } from "react-loader-spinner";

export const ApprovalSettingsModal = (props) => {
    const [allUsers, setAllUsers] = useState([])
    const [approvers, setApprovers] = useState([]);
    const [allApprovalOptions, setAllApprovalOptions] = useState()
    const [approvalCheck, setApprovalCheck] = useState(false)
    const [update, setUpdate] = useState(false)
    const [approvalSettingApiProgress, setApprovalSettingApiProgress] = useState(false)
    const [approvalRadio, setApprovalRadio] = useState("Parallel")
    const [refreshGrid, setRefreshGrid] = useState(false);

    useEffect(() => {
        const approvers = []
        const roles = ["SuperAdmin", "FirmAdmin", "Client Data Manager", "Partner", "User"]
        for (let user of allUsers) {
            if (roles.includes(user["Role"])) {
                approvers.push({ "label": user["UserName"] , "value": user["FirmDetailId"] })
            }
        }
        setAllApprovalOptions(approvers)
    }, [allUsers])


    useEffect(() => {
        let token = localStorage.getItem("token");
        const getRequestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        fetch(
            `${process.env.REACT_APP_BASE_URI}/workflow/subscriptions/roles/users/`,
            getRequestOptions
        )
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((result) => setAllUsers(result["data"]))
    }, [])

    useEffect(() => {
        let token = localStorage.getItem("token");
        const getRequestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
        };
        if (props.clienttypeid != null || props.clientmasterid != null) {
            let url = ``
            if (props.clienttypeid && !props.clientmasterid) {
                url = `${process.env.REACT_APP_BASE_URI}/workflow/form/approvalsetting/${props.clienttypeid}`;    
            }
            else if (props.clientmasterid && props.clienttypeid) {
                url = `${process.env.REACT_APP_BASE_URI}/workflow/form/clientapprovalsetting/${props.clientmasterid}/${props.clienttypeid}`;    
            }
            setRefreshGrid(true);
            fetch(
                url,
                getRequestOptions
            )
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((result) => {
                    if (result["data"].length > 0) {                            
                        setApprovers(result["data"])
                        if (result["data"][0].hasOwnProperty('ApprovalRequired')) {
                            setApprovalCheck(result["data"][0]["ApprovalRequired"] !== "Y")
                        }
                        else {
                            setApprovalCheck(false)    
                        }
                        setApprovalRadio(result["data"][0]["ApprovalType"])                   
                        setUpdate(true)                       
                    }
                }
            ).
            finally(() => {
                setRefreshGrid(false)
            })
        }        
    },[])


    const addApprover = () => {        
        // Get the last input field in the array
        const lastField = approvers[approvers.length - 1];

        const new_approver = {
            "OnboardingFirmApprovalGroupId": 0,
            "OnboardingFirmApprovalGroupDetailId": 0,
            "OnboardingClientApproverId": 0,
            "ApprovalRequired": !approvalCheck ? "Y" : "N",
            "FirmDetailId": 0,
            "ClientTypeId": props.clienttypeid,
            "Username": "",
            "Email": "",
            "ApproverOrder": "",
            "Active": "Y"
        }

        console.log(lastField)
        // Only add a new field if the last field is not blank (has some value)
        if (lastField == undefined || lastField.Username === null || lastField.Username.trim() !== '') {
            const temp_approvers = [...approvers.filter(approver => approver.Active !== null), new_approver];
            setApprovers(temp_approvers);
            // setApprovalConfigChanged(true);
        }
        else {
            toast.error(
                `Please select an approver before adding a new one.`,
                {
                    position: "bottom-right",
                    autoClose: 2500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    className: "toastify-color-error",
                }
            );
            console.log("Please select an approver before adding a new one.");
        }
    }

    const removeApprover = (Username, index) => {
        console.log(Username, index);
    
        // Find the actual approver by config_details_id
        const newApprovers = approvers.map((approver) => {
            // If the config_details_id matches
            if (approver.Username === Username) {
                if (approver.OnboardingFirmApprovalGroupDetailId === 0 || approver.OnboardingClientApproverId === 0) {
                    return null; // Return null to remove the approver
                } else {
                    // Set Active to "N" if TemplateApprovalConfigDetailId is not 0
                    return { ...approver, Active: "N" };
                }
            }
            return approver; // Return unchanged approver
        }).filter(Boolean); // Filter out the null (removed) approvers

        setApprovers(newApprovers);
        // setApprovalConfigChanged(true);
    };

    const handleClose = () => {
        setApprovalRadio("Parallel")
        setApprovers([])
        setAllApprovalOptions()
        setApprovalCheck(false)    
        setUpdate(false)
        props.onHide();
    }

    const handleRadioChange = (event) => {
        if (props.clientmasterid && props.clienttypeid) {
            event.preventDefault()
        }
        else {
            setApprovalRadio(event.target.value);
        }
    };

    const handleApprovalCheck = (event) => {
        if (props.clientmasterid && props.clienttypeid) {}
        else {
            setApprovalCheck(event.target.checked);
        }
    }


    const saveApprovalSetting = () => {
        setApprovalSettingApiProgress(true)
        let token = localStorage.getItem("token");
        const filtered_approvers = approvers
            .filter(approver => approver.FirmDetailId !== 0)
            .map((approver, index) => {
                return { ...approver, ApprovalRequired: "Y" };
        })

        // console.log("filtered_approvers", filtered_approvers);
        if(!approvalCheck && !filtered_approvers.length) {
            console.log("Empty", filtered_approvers);
            props.onHide();
            return;
        }
        const skip_approver = [{
            "ClientTypeId": props.clienttypeid,
            "ClientMasterId": props.clientmasterid,
            "ApprovalRequired": "N",
            "FirmDetailId": 0,                
        }]
        // console.log(filtered_approvers)
        filtered_approvers.forEach(approver => {
            if (!approver.hasOwnProperty('OnboardingClientApproverId')) {
                approver.OnboardingClientApproverId = 0;
            }
        });
        const payload = {
            ApprovalConfigs: approvalCheck 
                ? skip_approver 
                : filtered_approvers,
            ApprovalType: approvalRadio
        };

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(payload)
        };
        console.log("PAYLOAD: ", requestOptions["body"])
        let url = ``
        if (props.clienttypeid && !props.clientmasterid) {
            url = `${process.env.REACT_APP_BASE_URI}/workflow/form/approvalsetting/${props.clienttypeid}`;    
        }
        else if (props.clientmasterid && props.clienttypeid) {
            url = `${process.env.REACT_APP_BASE_URI}/workflow/form/clientapprovalsetting/${props.clientmasterid}/${props.clienttypeid}`;    
        }
        fetch(
            url,
            requestOptions
        )
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.success === true) {
                    console.log(refreshGrid)
                    setApprovalSettingApiProgress(false)
                    console.log("SAVED DATA: ", data.data)
                    toast.success(
                        "Approval Setting Saved Successfully:",
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-success",
                        }
                    );
                } else {                    
                    setApprovalSettingApiProgress(false)
                    toast.error(
                        "Error while fetching data: " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            scrollable="false"
            style={{
                maxHeight: 500,
                overflow: "auto",
                top: "20%"
            }}
            show={true}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <h5 style={{ marginBottom: 0, marginRight: 20, marginLeft: 10 }}>Approval Setting</h5>
            </Modal.Header>
            <Modal.Body className={!refreshGrid ? "flex-column-start-center" : "flex-row-center-center"}>
                {!refreshGrid 
                    ? 
                    <> 
                    <Row>
                        <div className={"pivot-check-holder"} style={{ marginBottom: "5px"}}>
                            <label style={{ margin: "0px 5px" }}>Please select this if you want to skip approval process for this form.</label>
                            <input type="checkbox" className={"form-check-input move-left custom-check-input pivot-check "}
                                style={{ margin: "0px 0px", cursor: "not-allowed",
                                    cursor: props.clientmasterid && props.clienttypeid ? 'not-allowed' : 'pointer',
                                    opacity: props.clientmasterid && props.clienttypeid ? 0.6 : 1
                                }}
                                checked={approvalCheck}
                                onChange={event => handleApprovalCheck(event)}
                                autoComplete="new-password"                                
                            />
                        </div>
                    </Row>                
                    <br />
                    {   
                    !approvalCheck ?
                        <>
                            <Row style={{ width: "100%", marginBottom: 10 }} className="flex-row-center-start">
                                <Col md={3} style={{ marginLeft: "10px" }}>
                                    <h6 style={{ marginBottom: 0 }}>Preference: </h6>
                                </Col>
                                <Col md={4} style={{ marginRight: "0px" }}>
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" 
                                        style={{ marginLeft: 0,  cursor: props.clientmasterid && props.clienttypeid ? 'not-allowed' : 'pointer',
                                            opacity: props.clientmasterid && props.clienttypeid ? 0.6 : 1 }}
                                        id="send_for_approval_sequential" value="Sequential" defaultChecked={approvalRadio === 'Sequential'}  
                                        onClick={(e) => handleRadioChange(e)}/>
                                        
                                    <label class="form-check-label" for="send_for_approval_sequential">Sequential</label>
                                </Col>
                                <Col md={3} style={{ marginRight: "0px" }}>
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions"
                                        id="send_for_approval_parallel" value="Parallel" defaultChecked={approvalRadio === 'Parallel'} 
                                        onClick={(e) => handleRadioChange(e)} 
                                        style={{ cursor: props.clientmasterid && props.clienttypeid ? 'not-allowed' : 'pointer',
                                            opacity: props.clientmasterid && props.clienttypeid ? 0.6 : 1 }} />
                                    <label class="form-check-label" for="send_for_approval_parallel">Parallel</label>
                                </Col>
                            </Row>
                            <Row style={{ width: "100%", marginBottom: 5 }} className="flex-row-center-start">
                                <h5 style={{ marginBottom: 0, marginLeft: 10, width: "fit-content" }}>Approvers</h5>
                                {
                                    !approvalCheck ?
                                        <Icon.PlusCircleFill
                                            style={{
                                                color: "var(--theme1_primary)",
                                                fontSize: 20,
                                                width: "fit-content",
                                                cursor: "pointer"
                                            }}
                                            onClick={addApprover}
                                        /> :
                                        <></>
                                }
                            </Row>
                            {
                                
                                approvers
                                .filter(approver => approver.Active === "Y")
                                .map((approver, index) => (
                                    // {console.log(approver)},
                                    <Row key={index} className="flex-row-center-center"
                                        style={{
                                            margin: "10px 0px 10px 0px",
                                            width: "100%"
                                        }}>
                                        <Col md={3}><h6 style={{ marginBottom: 0 }}>Approver {index + 1} </h6></Col>
                                        <Col md={8}>
                                            <Select
                                                value={{
                                                    label: approver["FirmDetailId"] ? `${approver["Username"]}` : "",
                                                    value: approver["FirmDetailId"]
                                                }}
                                                onChange={(e) => {
                                                    console.log(e)
                                                    const apps = approvers.map(app => {
                                                        if (app.Username === approver.Username) {
                                                            const Username = e.label;
                                                            
                                                            return {
                                                                ...app,
                                                                Username: Username,                                                                
                                                                FirmDetailId: e.value,
                                                                Active: "Y"
                                                            };
                                                        }
                                                        return app;
                                                    });
                                            
                                                    setApprovers(apps);
                                                    // setApprovalConfigChanged(true);
                                                }}
                                                options={allApprovalOptions.filter(option => {
                                                    // Filter out options already selected in other approvers
                                                    return !approvers.some(app => 
                                                        app.Username === option.label.split(' - ')[0] &&                                                         
                                                        app.Active === "Y"
                                                    );
                                                })}
                                                className="form-select custom-select"
                                                aria-label="Default"
                                                closeMenuOnSelect={true}
                                                autosize={true}
                                                placeholder="Select approver"
                                                menuPosition="fixed"
                                                classNamePrefix="react-select"
                                            /></Col>
                                        <Col md={1}>
                                            <Icon.DashCircleFill
                                                style={{
                                                    color: "var(--theme1_primary)",
                                                    fontSize: 20,
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => removeApprover(approver.Username, index)} 
                                            />
                                        </Col>
                                    </Row>
                                ))
                            }
                        </>
                        :
                        <></>
                    }
                    </> 
                    : 
                    <>
                        <div
                            className="flex-row-center-center"
                        >
                            <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                                <Bars
                                    height="80"
                                    width="80"
                                    color="var(--theme1_primary)"
                                    ariaLabel="bars-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
        
                                Loading your data
                            </div>
                        </div>
                    </>
                }
                
            </Modal.Body>
            <Modal.Footer className="action-footer">
                <CustomButton
                    progress={approvalSettingApiProgress}
                    onclick={() => saveApprovalSetting()}
                    content={"Save"}
                />
            </Modal.Footer>
        </Modal>
    )
}