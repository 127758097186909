import { useEffect, useState } from 'react';
import { setToLS, getFromLS } from '../utils/storage';
import chart_themes from './chartThemes.json';

export const useChartTheme = () => {
  // const themes = getFromLS('all-themes');
  const [chartTheme, setChartTheme] = useState(chart_themes.data["Tableau Classic 10"]);
  const [chartThemeLoaded, setChartThemeLoaded] = useState(false);

  const setChartMode = mode => {
    setToLS('chart-theme', mode)
    setChartTheme(mode);
  };


  useEffect(() =>{
    const localChartTheme = getFromLS('chart-theme');
    // setToLS('chart-theme', chart_themes.data["Tableau Classic 10"])
    if(localChartTheme){
      setChartTheme(localChartTheme)
    }else{
      setToLS('chart-theme', chart_themes.data["Tableau Classic 10"])
      setChartTheme(chart_themes.data["Tableau Classic 10"])
    }
    // localChartTheme ? setChartTheme(localChartTheme) : setChartTheme(chart_themes.data["Tableau Classic 10"]);
    setChartThemeLoaded(true);
  }, []);

  return { chartTheme, chartThemeLoaded, setChartMode };
};