import React, {
    useMemo,
    useState,
    useEffect,
    useCallback,
    useRef,
} from "react";
import {
    Row,
    Col,
    Button,
    Modal
} from "react-bootstrap";
import { logout } from "../../utils";
import { toast } from "react-toastify";
import { getFromLS } from "../../utils/storage";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { Bars } from "react-loader-spinner";
import Select from 'react-select';
import CustomButton from "../reusable_components/custom_button_component/Button";

const ClientDataMapping = (props) => {
    // console.log("CLIENT DATA MAPPING PROPS: ", props)
    const [data, setData] = useState()
    const [rowData, setRowData] = useState([])
    const [vendorFields, setVendorFields] = useState([])
    const [hubspotFieldOptions, setHubSpotFieldOptions] = useState([{ "label": "", "value": "" }])
    const [hubspotFields, setHubspotFields] = useState([])
    const [safesendFields, setSafesendFields] = useState([])
    const [safesendFieldOptions, setSafeSendFieldOptions] = useState([{ "label": "", "value": "" }])
    const [editedRows, setEditedRows] = useState([]);
    const [fieldMappingJson, setFieldMappingJson] = useState({})
    const [individualMappingId, setIndividualMappingId] = useState()
    const [entityMappingId, setEntityMappingId] = useState()
    const [update, setUpdate] = useState(false)
    const [mappingData, setMappingData] = useState([])
    const [mappingApiData, setMappingApiData] = useState()
    const [clientTypeOptions, setClientTypeOptions] = useState([])
    const [selectedType, setSelectedType] = useState(clientTypeOptions[0])
    const [updateMappingApiProgress, setUpdateMappingApiProgress] = useState(false)
    const [approveModal, setApproveModal] = useState(false)
    const [allMappingAvailable, setAllMappingAvailable] = useState(false)

    useEffect(() => {
        // console.log("MAPPING API DATA: ", mappingApiData)
        if (mappingApiData !== undefined) {
            const optionList = []
            for (let record of mappingApiData) {
                optionList.push({
                    "label": record["ClientType"],
                    "value": record["ClientType"],
                })
            }
            // console.log("OPTION LIT: ", optionList)
            setClientTypeOptions(optionList)
            setSelectedType(optionList[0])
        }
    }, [mappingApiData])

    useEffect(() => {
        // console.log("MAPPING API DATA: ", mappingApiData)
        if (mappingApiData !== undefined && selectedType !== undefined) {
            // if (mappingApiData[0]["MappingId"] !== null) {
            let filteredMappingData = []
            if (selectedType !== undefined) {
                filteredMappingData = mappingApiData.filter(
                    obj => Object.keys(obj).some(key => obj[key] === selectedType.value)
                )
            } else {
                filteredMappingData = mappingApiData.filter(
                    obj => Object.keys(obj).some(key => obj[key] === clientTypeOptions[0].value)
                )
            }
            setIndividualMappingId(filteredMappingData[0]["MappingId"])
            setEntityMappingId(filteredMappingData[0]["MappingId"])
            setMappingData(filteredMappingData[0]["MappingData"])
            const refactoredMappingData = [...filteredMappingData[0]["MappingData"]]
            for (let record of refactoredMappingData) {
                const options = []
                const vendornamemap = {
                    "Hubspotname": "Hubspot CRM",
                    "Safesendname": "SafeSend",
                    "CCHAxcessname": "CCH Axcess Workflow"
                }
                const vendornames = ["Hubspotname", "Safesendname", "CCHAxcessname"]
                for (let [key, value] of Object.entries(record)) {
                    if (vendornames.includes(key)) {
                        if (value !== null) {
                            options.push(vendornamemap[key])
                        } else {
                            continue
                        }
                    } else {
                        continue
                    }
                }
                if (record["Source of truth"] === "") {
                    if (options.length === 0) {
                        record["Source of truth"] = "Cygnus MDM"
                    }
                }
            }
            // console.log("REFACTORED MAPPING DATA: ", refactoredMappingData)
            setRowData(refactoredMappingData)
        }
    }, [selectedType, mappingApiData])

    const FetchMappingData = (form_id) => {
        const token = getFromLS("token")
        const getOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/data_mapping/${form_id !== undefined ? form_id : ''}`, getOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.success === true) {
                    // console.log("MAPPING DATA: ", data.data)
                    if (data.data.length > 0) {
                        setMappingApiData(data.data)
                        if (data.data[0].MappingId !== null) {
                            setUpdate(true)
                        }
                    }
                    else {
                    }
                } else {
                    toast.error(
                        "Error while fetching data: " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch((err) => console.log("Err: ", err));
    }

    // const FetchAllFieldsAndSelectedFieldsData = () => {
    //     const token = getFromLS("token")
    //     const getOptions = {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${token}`,
    //         },
    //     };

    //     fetch(`${process.env.REACT_APP_BASE_URI}/workflow/onboarding/all_available_fields/`, getOptions)
    //         .then((response) => {
    //             if (!response.ok) {
    //                 if (response.status === 401) {
    //                     // Handle unauthorized error
    //                     logout();
    //                     alert("Session ended , Please login back");
    //                 } else {
    //                     throw new Error("Request failed.");
    //                 }
    //             }
    //             return response.json();
    //         })
    //         .then((data) => {
    //             // console.log("DATA: ", data.data)
    //             if (data.success === true) {
    //                 setAllFieldsData(data.data)
    //             } else {
    //                 toast.error(
    //                     "Saving failed, " + data.message,
    //                     {
    //                         position: "bottom-right",
    //                         autoClose: 2500,
    //                         hideProgressBar: true,
    //                         closeOnClick: true,
    //                         pauseOnHover: true,
    //                         draggable: true,
    //                         progress: undefined,
    //                         className: "toastify-color-error",
    //                     }
    //                 );
    //             }
    //         })
    //         .catch((err) => console.log("Err: ", err));

    //     fetch(`${process.env.REACT_APP_BASE_URI}/workflow/onboarding/firm_selected_fields/`, getOptions)
    //         .then((response) => {
    //             if (!response.ok) {
    //                 if (response.status === 401) {
    //                     // Handle unauthorized error
    //                     logout();
    //                     alert("Session ended , Please login back");
    //                 } else {
    //                     throw new Error("Request failed.");
    //                 }
    //             }
    //             return response.json();
    //         })
    //         .then((data) => {
    //             // console.log("DATA: ", data.data)
    //             if (data.success === true) {
    //                 setSelectedFieldIds(data.data[0]["FieldIds"])
    //             } else {
    //                 toast.error(
    //                     "Saving failed, " + data.message,
    //                     {
    //                         position: "bottom-right",
    //                         autoClose: 2500,
    //                         hideProgressBar: true,
    //                         closeOnClick: true,
    //                         pauseOnHover: true,
    //                         draggable: true,
    //                         progress: undefined,
    //                         className: "toastify-color-error",
    //                     }
    //                 );
    //             }
    //         })
    //         .catch((err) => console.log("Err: ", err));
    // }

    const FetchFirmFields = () => {
        const token = getFromLS("token")
        const getOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/onboarding/vendor_fields/`, getOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                // console.log("VENDOR FIELDS DATA: ", data.data)
                if (data.success === true) {
                    setVendorFields(data.data)
                } else {
                    toast.error(
                        "Saving failed, " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch((err) => console.log("Err: ", err));
    }

    const transporter = (pill) => {
        props.transporter(pill);
    };

    const UpdateMapping = () => {
        console.log("UPDATE MAPPING CALLED!")
        setUpdateMappingApiProgress(true)
        const token = getFromLS("token")

        const filteredRows = rowData.filter((_, index) => editedRows.includes(index));

        if (filteredRows.length <= 0){
            props.transporter("client_onboarding_form_config", {
                "config_option": { clientType: selectedType.value, targetFields: mappingData }
            })
            return;        
        }
        
        const payload = {
            "ClientType": selectedType.value,
            "Mappings": filteredRows
        }

        // console.log("PAYLOAD: ", payload)
        const requestOption = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/data_mapping/`, requestOption)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.success === true) {
                    setUpdateMappingApiProgress(true)
                    toast.success(
                        "Mapping successfully updated!",
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-success",
                        }
                    );
                    // console.log("TARGET FIELDS: ", mappingData)
                    // navigate("/client-onboarding-form", { state: { targetFields: mappingData } })
                    props.transporter("client_onboarding_form_config", {
                        "config_option": { clientType: selectedType.value, targetFields: mappingData }
                    })
                } else {
                    setUpdateMappingApiProgress(true)
                    toast.error(
                        "Saving failed, " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch((err) => console.log("Err: ", err));
    }

    useEffect(() => {
        // console.log("FMJSON: ", fieldMappingJson)
        if (Object.keys(fieldMappingJson).length > 0) {
            setData(true)
        }
        const all_sot_mappings = []
        for (let [_, field] of Object.entries(fieldMappingJson)) {
            all_sot_mappings.push(field["source_of_truth"])
        }
        // console.log(all_sot_mappings)
        // console.log(all_sot_mappings.filter(str => str.trim().length === 0))
        if (all_sot_mappings.filter(str => str && str.trim().length === 0).length > 0) {
            setAllMappingAvailable(false);
        } else {
            setAllMappingAvailable(true);
        }        
    }, [fieldMappingJson])

    // useEffect(() => {
    //     console.log("ROW DATA: ", rowData)
    // }, [rowData])


    useEffect(() => {
        const mappingDict = {}
        if (mappingData.length > 0) {
            if (Object.keys(mappingData[0]).length > 0) {
                for (let field of mappingData) {
                    Object.assign(mappingDict, {
                        [field["FieldName"]]: {
                            "source_of_truth": field["Source of truth"]
                        }
                    })
                }
                setFieldMappingJson(mappingDict)
            }
        }
    }, [mappingData])

    useEffect(() => {
        if (Object.keys(vendorFields).length > 0) {
            // console.log("VENDOR FIELDS: ", vendorFields)
            const hfo = []
            const hfv = []
            const sfo = []
            const sfv = []
            for (let field of vendorFields["Hubspot"]) {
                hfo.push({
                    "label": field.DisplayName,
                    "value": field.APIName
                })
                hfv.push(field.APIName)
            }
            // console.log("HFV: ", hfv)
            setHubSpotFieldOptions(hfo)
            setHubspotFields(hfv)
            for (let field of vendorFields["SafeSend"]) {
                sfo.push({
                    "label": field.DisplayName,
                    "value": field.APIName
                })
                sfv.push(field.APIName)
            }
            // console.log("SFV: ", sfv)
            setSafeSendFieldOptions(sfo)
            setSafesendFields(sfv)
        }
    }, [vendorFields])

    useEffect(() => {
        FetchMappingData()
        FetchFirmFields()
    }, [])

    // Setting default grid params
    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    let gridApi;
    const onGridReady = useCallback((params) => {
        gridApi = params.api;
        gridApi.closeToolPanel();
    }, []);

    const defaultColDef = useMemo(
        () => ({
            filter: true,
            resizable: true,
            animateRows: true,
        }),
        []
    );

    // Declaring the api - grid connections
    const [columnDefs, setColumnDefs] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState({ "previewComponent": [] });

    const onSelectionChanged = () => { const selected_rows = gridRef.current.api.getSelectedRows() };

    const CustomNoRowsOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center flex-row-center-center"
                style={{ height: '9%' }}
            >
                <div className="spinner " style={{ fontSize: 14, fontWeight: 500 }}>
                    {props.noRowsMessageFunc()}
                </div>
            </div>
        );
    };

    const noRowsOverlayComponent = useMemo(() => { return CustomNoRowsOverlay }, []);

    const noRowsOverlayComponentParams = useMemo(() => { return { noRowsMessageFunc: () => `No data for client fields yet. Please select fields.` } }, []);

    /**
    * This function is used for formatting the default column names of the grid
    * @param {*} defaultName: Current name from API
    * @returns: Final column name
    */
    const GridColumnTitleRenderer = (defaultName) => {
        const splitWords = defaultName.split("_")
        for (let word in splitWords) {
            splitWords[word] = splitWords[word].charAt(0).toUpperCase() + splitWords[word].slice(1)
        }
        return splitWords.join(" ")
    }

    const FieldNameRenderer = (props) => {
        // console.log("BTN CELL RENDERER: ", props)
        return (
            <>{props.data.FieldName}</>
        )
    }

    const HubspotCellRenderer = (props) => {
        // console.log("HUBSPOT CELL RENDERER: ", props.getValue())
        return (
            <>
                {props.getValue() !== undefined ? props.getValue() : props.data["Hubspotname"]}
            </>
        )
    }
    const SafesendCellRenderer = (props) => {
        return (
            <>
                {props.getValue() !== undefined ? props.getValue() : props.data["Safesendname"]}
            </>
        )
    }
    const CchAxcessCellRenderer = (props) => {
        return (
            <>
                {props.getValue() !== undefined ? props.getValue() : props.data["Safesendname"]}
            </>
        )
    }
    const SourceOfTruthRenderer = (props) => {
        // console.log("SOT PROPS: ", props)
        // const options = []
        // const vendornamemap = {
        //     "Hubspotname": "Hubspot",
        //     "Safesendname": "Safesend",
        //     "CCHAxcessname": "CCH Axcess"
        // }
        // const vendornames = ["Hubspotname", "Safesendname", "CCHAxcessname"]
        // for (let [key, value] of Object.entries(props["data"])) {
        //     if (vendornames.includes(key)) {
        //         if (value !== null) {
        //             options.push(vendornamemap[key])
        //         } else {
        //             continue
        //         }
        //     } else {
        //         continue
        //     }
        // }
        // const value = props["data"]["Source of truth"] !== "" ? props["data"]["Source of truth"] : options.length === 0 ? "Cygnus" : props["data"]["Source of truth"]
        // console.log("VALUE: ", props.getValue())
        return (
            <>
                {props.getValue()}
            </>
        )
    }

    const onCellValueChanged = (event) => {
        setEditedRows([...editedRows, event.rowIndex]);
        // console.log("ITEM CHANGED : ", [...editedRows, event.rowIndex]);
    };
    const vendor_map = {
        "Source Of Truth": "source_of_truth"
    }
    const updateMappingDataSot = (field, sot) => {
        const index = mappingData.findIndex((obj) =>
            obj.FieldName === field)
        mappingData[index]["Source of truth"] = sot
    }

    const onCellValChanged = params => {
        if (params.oldValue !== params.newValue) {
            // console.log("FIELD MAPPING JSON: ", fieldMappingJson)
            // console.log("PARAMS FIELD NAME: ", params.data["FieldName"])
            // console.log("FIELD MAPPING OBJECT: ", fieldMappingJson[params.data["FieldName"]])
            const fieldMappingObject = fieldMappingJson[params.data["FieldName"]]
            fieldMappingObject[vendor_map["Source Of Truth"]] = params.newValue
            // console.log("FIELD MAPPING OBJECT: ", fieldMappingObject)
            Object.assign(fieldMappingJson[params.data["FieldName"]], fieldMappingObject)
            // console.log("FIELD MAPPING JSON: ", fieldMappingJson)
            updateMappingDataSot(params.data["FieldName"], params.newValue)
            // Change the background color of the edited cell
            params.column.colDef.cellStyle = { backgroundColor: 'lightgreen' };
            // Refresh the cells to apply the new style
            params.api.refreshCells({
                force: true,
                columns: [params.column.getId()],
                rowNodes: [params.node]
            });
        }
    };

    const onCellEditingStopped = (event) => {
        // console.log("Event AFTER editing", event, [...editedRows, event.rowIndex]);
        // setEditedRows([...editedRows, event.rowIndex]);
    };




    useEffect(() => {
        // console.log("ROW DATA: ", rowData)
        let colDefs = [{
            field: "FieldName",
            headerName: "Field",
            suppressMenu: true,
            cellRenderer: FieldNameRenderer,
            cellRendererParams: {
                selectedrow: setSelectedRowData
            },
            sizeColumnsToFit: true,
            cellStyle: { "borderRight": "0.5px solid lightgray" }
        },
        {
            field: "Hubspotname",
            headerName: "Hubspot",
            cellEditor: "agSelectCellEditor",
            sizeColumnsToFit: true,
            suppressSizeToFit: true,
            cellRenderer: HubspotCellRenderer,
            onCellValueChanged: onCellValChanged,
            cellStyle: { "borderRight": "0.5px solid lightgray" }
        },
        {
            field: "Safesendname",
            headerName: "SafeSend",
            suppressSizeToFit: true,
            cellEditor: "agSelectCellEditor",
            sizeColumnsToFit: true,
            cellRenderer: SafesendCellRenderer,
            onCellValueChanged: onCellValChanged,
            cellStyle: { "borderRight": "0.5px solid lightgray" }
        },
        {
            field: "CCHAxcessname",
            headerName: "CCH Axcess",
            suppressSizeToFit: true,
            cellEditor: "agSelectCellEditor",
            sizeColumnsToFit: true,
            cellRenderer: CchAxcessCellRenderer,
            onCellValueChanged: onCellValChanged,
            cellStyle: { "borderRight": "0.5px solid lightgray" }
        },
        {
            field: "SourceOfTruth",
            headerName: "Source Of Truth",
            cellEditor: "agSelectCellEditor",
            editable: true,
            cellEditorParams: (params) => {
                // console.log("CELL EDITOR PARAMS: ", params)
                const options = ["Cygnus MDM"]
                const vendornamemap = {
                    "Hubspotname": "Hubspot CRM",
                    "Safesendname": "SafeSend",
                    "CCHAxcessname": "CCH Axcess Workflow"
                }
                const vendornames = ["Hubspotname", "Safesendname", "CCHAxcessname"]
                for (let [key, value] of Object.entries(params["data"])) {
                    if (vendornames.includes(key)) {
                        if (value !== null) {
                            options.push(vendornamemap[key])
                        } else {
                            continue
                        }
                    } else {
                        continue
                    }
                }
                return {
                    values: options
                }
            },
            sizeColumnsToFit: true,
            suppressSizeToFit: true,
            cellRenderer: SourceOfTruthRenderer,
            onCellValueChanged: onCellValChanged
        }
        ]
        setColumnDefs(colDefs)
    }, [hubspotFields, safesendFields, fieldMappingJson, rowData])

    const ApproveModal = (props) => {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                backdropClassName="custom-backdrop-save"
                backdrop="static"
                centered
                scrollable="false"
                style={{
                    maxHeight: 500,
                    overflow: "auto",
                    top: "20%"
                }}
            >
                <Modal.Header closeButton>
                    <h5 style={{ marginBottom: 0, marginRight: 20, marginLeft: 10 }}>Are you sure you want to save this mapping?</h5>
                </Modal.Header>
                {
                    !allMappingAvailable ?
                        <Modal.Body>
                            <h6 style={{ marginBottom: 0, marginRight: 20, marginLeft: 10 }}>You have not completed the mapping for all the fields.</h6>
                        </Modal.Body> :
                        <></>
                }
                <Modal.Footer className="flex-row-space-center" style={{ width: "100%" }}>
                    <Button className="custom-button" style={{
                        width: "fit-content",
                        display: "inline-block",
                        padding: "5px 10px",
                    }} onClick={() => {
                        props.submit()
                        props.onHide()
                    }}>
                        Save
                    </Button>
                    <Button style={{
                        width: "fit-content",
                        display: "inline-block",
                        padding: "5px 10px",
                        color: "var(--theme1_tertiary)",
                        background: "transparent",
                        border: 0,
                        fontSize: 12
                    }} onClick={() => props.onHide()}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }


    return (
        <>
            <ApproveModal
                show={approveModal}
                submit={() => UpdateMapping()}
                onHide={() => setApproveModal(false)}
            />
            {data
                ?
                <>
                    <Row className='no-gutter page-wrapper'>
                        <Col md={6} className="flex-row-space-center" style={{ padding: "0px 20px 0px 0px" }}>
                            <p className="display-6" style={{ height: "fit-content", marginBottom: 0, width: "fit-content", float: "right" }}>Client Field Mapping</p> <p style={{ width: "fit-content" }}></p>
                        </Col>
                        <Col md={5} className="flex-row-center-end">
                            <div className="form-select-wrapper" style={{ width: 300, marginLeft: 20, marginRight: 10, fontSize: 14, float: "right" }}>
                                <p style={{ width: 150, fontWeight: 600, marginBottom: 0 }}>Client type:</p>
                                <Select
                                    value={selectedType}
                                    onChange={(event) => setSelectedType(event)}
                                    options={clientTypeOptions}
                                    className="form-select custom-select"
                                    aria-label="Default"
                                    closeMenuOnSelect={true}
                                    autosize={true}
                                    placeholder="Select type of client"
                                    menuPosition="fixed"
                                    classNamePrefix="react-select"
                                />
                            </div>
                        </Col>
                        <Col md={1}>
                            <CustomButton
                                progress={updateMappingApiProgress}
                                onclick={() => setApproveModal(true)}
                                content={"Save"}
                            />
                        </Col>
                    </Row>
                    <Row className='no-gutter' style={{ height: "85vh" }}>
                        <Col md={12}>
                            <div className="ag-theme-balham" style={gridStyle}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    onGridReady={onGridReady}
                                    alwaysShowHorizontalScroll={false}
                                    alwaysShowVerticalScroll={false}
                                    rowHeight={25}
                                    rowSelection={"single"}
                                    suppressContextMenu={true}
                                    onFirstDataRendered={onFirstDataRendered}
                                    onCellValueChanged={(item) => onCellValueChanged(item)}
                                    onCellEditingStopped={(event) => onCellEditingStopped(event)}
                                    // noRowsOverlayComponent={noRowsOverlayComponent}
                                    // noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                                    suppressRowHoverHighlight={true}
                                    singleClickEdit={true}
                                    domLayout='autoHeight'
                                />
                            </div>
                        </Col>
                    </Row>
                </>
                :
                <div
                    className="flex-row-center-center"
                    style={{ marginTop: "20%" }}
                >
                    <div className="spinner " style={{ fontSize: 14, fontWeight: 500 }}>
                        <Bars
                            height="80"
                            width="80"
                            color="var(--theme1_primary)"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />

                        Loading your data
                    </div>
                </div>
            }
        </>
    )
}

export default ClientDataMapping;