import React from "react";
import {
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip,
    Modal
} from "react-bootstrap";
import { useMemo, useState, useCallback, useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { logout } from "../../../utils/index.js";
import { ThreeCircles } from "react-loader-spinner";
import Select from "react-select"
import * as Icon from "react-bootstrap-icons";

export const TemplateMappingsPage = (props) => {
    const [rowHeight, setRowHeight] = useState(25);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [templateGroups, setTemplateGroups] = useState([]);
    const [addEditGroupModal, setAddEditGroupModal] = useState(false);
    const [sendAddGroup, setSendAddGroup] = useState(false);
    const [selectedExistingGroupDetails, setSelectedExistingGroupDetails] = useState()

    let gridApi;
    // Callback when the grid is ready
    const onGridReady = useCallback((params) => {
        gridApi = params.api;
        gridApi.closeToolPanel();
    }, []);

    // Reference to the AgGrid component
    const gridRef = useRef();

    // Style for the AgGrid
    const gridStyle = useMemo(() => ({ height: "78vh", width: "100%" }), []);

    // Callback to handle sort change
    const onSortChanged = (e) => {
        e.api.refreshCells();
    };

    // Default column definition for AgGrid
    const defaultColDef = useMemo(
        () => ({
            sortable: true,
            filter: true,
            resizable: true,
            animateRows: true,
        }),
        []
    );

    //All available pages
    const [allAvailablePages, setAllAvailablePages] = useState([])
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    const getAllWizardForms = useCallback(() => {
        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/wizard_pages/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                console.log("All AVAILABLE PAGES: ", data)
                setAllAvailablePages(data.data)
            });
    }, []);

    useEffect(() => {
        getAllWizardForms()
    }, [])

    const GroupPageMappingRenderer = (gpmrprops) => {
        const pages = gpmrprops.value.map((page) => {
            const foundPage = allAvailablePages.find(obj => obj.WizardPageId === page.WizardPageId)
            return foundPage!==undefined?foundPage["TemplateName"]:null
        })
        return (
            pages.join(", ")
        )

    }

    const EditBtnCellRenderer = (ebcrprops) => {
        const editClick = () => {
            setSelectedExistingGroupDetails(ebcrprops.node.data)
            setAddEditGroupModal(true)
        }
        return (
            <><OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="button-tooltip">
                        Edit group details
                    </Tooltip>
                }
                error={true}
            >
                <Button className="icon-button" style={{
                    padding: "0px", margin: "0px",
                    width: "30%",
                    pointerEvents: "all"
                }}
                    onClick={() => editClick()}>
                    <Icon.PencilSquare />
                </Button>
            </OverlayTrigger></>
        )
    }

    const CreatedDateRenderer = (created_date_props) => {
        const formatDateTime = (dateTimeString) => {
            // Convert the input string to a Date object in the specified timezone offset
            const date = new Date(dateTimeString + '-05:52'); // Append the timezone offset to the input string

            // Adjust to user's local timezone
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0');

            // Format hours, minutes, and seconds
            let hour = date.getHours();
            const minute = String(date.getMinutes()).padStart(2, '0');
            const second = String(date.getSeconds()).padStart(2, '0');

            // Determine AM/PM notation
            const ampm = hour >= 12 ? 'PM' : 'AM';
            hour = hour % 12;
            hour = hour ? hour : 12; // Hour '0' should be '12'
            const formattedHour = String(hour).padStart(2, '0');

            // Format as "YYYY-MM-DD h:mm:ss A"
            return `${year}-${month}-${day} ${formattedHour}:${minute}:${second} ${ampm}`;
        };

        return (
            <span>
                {formatDateTime(created_date_props.data["CreatedAt"])}
            </span>
        )
    }

    const defaultTemplateMappingColumnDefs = [
        {
            headerName: "Group Name",
            field: "GroupName",
            minWidth: 360,
            sortable: false,
            checkboxSelection: true
        },
        {
            headerName: "Group Page Mapping",
            field: "Pages",
            cellRenderer: GroupPageMappingRenderer,
            minWidth: 500,
            sortable: false
        },
        {
            headerName: "Created Date",
            field: "CreatedAt",
            cellRenderer: CreatedDateRenderer,
            minWidth: 465,
            sortable: false,
        },
        {
            headerName: "Action",
            field: "",
            cellRenderer: EditBtnCellRenderer,
            pinned: "right",
            maxWidth: 80,
            minWidth: 80,
            sortable: false
        }
    ];

    // Column definitions for AgGrid
    const [templateMappingsColumnDefs, setTemplateMappingsColumnDefs] = useState(defaultTemplateMappingColumnDefs);

    useEffect(() => {
        setTemplateMappingsColumnDefs(defaultTemplateMappingColumnDefs)
    }, [allAvailablePages])

    const onSelectionChanged = useCallback(async (event) => {

    }, []);

    /**
     * Custom overlay component for no rows.
     * @param {Object} props - Props for the overlay component.
     * @returns Overlay component for displaying a message when no rows are present.
     */
    const CustomNoRowsOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center flex-row-center-center"
                style={{ height: '9%' }}
            >
                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                    {props.noRowsMessageFunc()}
                </div>
            </div>
        );
    };

    const noRowsOverlayComponent = useMemo(() => { return CustomNoRowsOverlay }, []);

    const noRowsOverlayComponentParams = useMemo(() => { return { noRowsMessageFunc: () => !refreshGrid ? `No data available`:`Loading your data` } }, [refreshGrid]);

    const toastBasicConfig = {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };

    const toastSuccessConfig = {
        ...toastBasicConfig,
        className: "toastify-color-success",
    };

    const toastErrorConfig = {
        ...toastBasicConfig,
        className: "toastify-color-error",
    };

    const addGroupButtonClick = () => {
        setAddEditGroupModal(true)
    }

    useEffect(() => {
        fetchGroupData();
    }, []);

    const fetchGroupData = useCallback(async () => {
        // Reset state
        // resetState();

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };

        setRefreshGrid(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement_letter_wizard/template_wizard_group_mappings`, requestOptions);

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized error
                    logout();
                    alert("Session ended , Please login back");
                } else {
                    throw new Error("Request failed.");
                }
            }

            const data = await response.json();
            console.log(data)
            const details = data.data;
            setTemplateGroups(details);

        } catch (error) {
            console.error('Error fetching template groups data:', error);
        } finally {
            setRefreshGrid(false);
        }
    }, []);

    const [saveTemplateApiFlag, setSaveTemplateApiTag] = useState(false)
    const saveTemplateGroup = (data, selectedPages, onHide) => {
        console.log("selectedPages: ", selectedPages)
        setSaveTemplateApiTag(true)
        const requestBody = {
            "groupid": data.id,
            "groupname": data.name,
            "selectedpages": [...new Set(selectedPages.map((item) => item.value))]
        }
        console.log("requestBody: ", requestBody)
        onHide();
        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement_letter_wizard/template_wizard_group_mappings`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(requestBody)
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }

                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    toast.success(
                        "Added group and mapping successful!",
                        toastSuccessConfig
                    );
                } else {
                    toast.error(
                        `Add group and mapping failed: ${data.message}`,
                        toastErrorConfig
                    );
                }
            })
            .catch(error => {
                // Handle error
                console.error('Error:', error);
                toast.error(
                    "Error in adding group",
                    toastErrorConfig
                );
            })
            .finally(() => {
                setSaveTemplateApiTag(false)
                setSelectedExistingGroupDetails(undefined)
                fetchGroupData()
                onHide()
            });

    }

    return (
        <>          
                
            <Row className='no-gutter'>
                <p className="display-6" style={{ height: "fit-content", width: "fit-content", padding: 0, marginBottom: 0 }}>
                    Wizard Group Mapping
                </p>
            </Row>
            {addEditGroupModal
                &&
                (<AddEditGroupModal
                    show={addEditGroupModal}
                    onHide={() => {
                        setAddEditGroupModal(false);
                    }}
                    saveGroup={saveTemplateGroup}
                    saveTemplateApiFlag={saveTemplateApiFlag}
                    allavailablepages={allAvailablePages}
                    existingGroupData={selectedExistingGroupDetails}
                    resetselecteddata = {() => setSelectedExistingGroupDetails(undefined)}
                />)
            }
            <Row className='no-gutter'>
                <Col md={12} className="d-flex justify-content-end" style={{ float: "right" }}>
                    <div style={{ marginBottom: "0rem", marginRight: "1rem", marginTop: "-2.1rem" }}>
                        <Button
                            onClick={addGroupButtonClick}
                            className="custom-button"
                        >
                            <p style={{ marginBottom: 0, marginTop: 0 }}>Add Group</p>
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row className='no-gutter'>
                <div className="ag-theme-balham" style={gridStyle}>
                    <AgGridReact
                        key={refreshGrid}
                        ref={gridRef}
                        rowData={templateGroups}
                        columnDefs={templateMappingsColumnDefs}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        alwaysShowHorizontalScroll={false}
                        alwaysShowVerticalScroll={false}
                        rowHeight={rowHeight}
                        rowSelection={"single"}
                        suppressContextMenu={true}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                        onSortChanged={onSortChanged}
                        onSelectionChanged={onSelectionChanged}
                    />
                </div>
            </Row>      
        </>

    )
}

const PageSelector = (props) => {
    const [pageOptions, setPageOptions] = useState(props.pageoptions)
    useEffect(() => {
        // console.log("PAGE OPTIONS: ", props.pageoptions)
        setPageOptions(props.pageoptions)
    }, [props.pageoptions])
    
    const [selectedPages, setSelectedPages] = useState(
        props.pageoptions.length > 0
            ? [{ id: 1, label: props.pageoptions[0].label, value: props.pageoptions[0].value }]
            : [{}]
    );
    
    useEffect(() => {
      if(props.selectedpages !== undefined && props.selectedpages.length > 0){
        setSelectedPages(props.selectedpages)
      }
    }, [props.selectedpages])
    
    // useEffect(() => {
    //   console.log("selectedPages: ", selectedPages)
    // }, [selectedPages])
    

    const addPage = () => {
        const newPage = { id: selectedPages.length + 1, label: "", value: "" };
        setSelectedPages([...selectedPages, newPage]);
        props.setselectedpages([...selectedPages, newPage]);
    };

    const updatePage = (id, updatedData) => {
        console.log("UPDATED DATA: ", updatedData)
        const updatedItems = selectedPages.map(item =>
            item.id === id ? { ...item, ...updatedData } : item
        );
        setSelectedPages(updatedItems);
        console.log("UPDATED ITEMS: ", updatedItems)
        props.setselectedpages(updatedItems);
    };

    const deletePage = (id) => {
        const filteredItems = selectedPages.filter(item => item.id !== id);
        setSelectedPages(filteredItems);
        props.setselectedpages(filteredItems)
    };

    return (
        <>
            <div className="flex-row-center-center" style={{ marginBottom: 10 }}>
                <label style={{ fontWeight: 500, fontSize: 14 }}>Template Page Mapping</label>
                <Icon.PlusCircleFill color="var(--theme1_primary)" style={{ fontSize: 20, marginLeft: 20 }} onClick={() => addPage()} />
            </div>
            {
                selectedPages.map((page) =>
                    <div className="flex-row-center-center" style={{ margin: "5px 0px" }}>
                        <Select
                            onChange={(e) => updatePage(page.id, e)}
                            value={pageOptions.find(obj => obj.value === page.value)}
                            options={pageOptions}
                            className="intent-select custom-select"
                            aria-label="Default"
                            closeMenuOnSelect={true}
                            autosize={true}
                            placeholder="Select pages"
                            menuPosition="fixed"
                            classNamePrefix="react-select"
                        // menuIsOpen
                        />
                        <Icon.DashCircleFill color="var(--theme1_primary)" style={{ fontSize: 20, marginLeft: 20 }} onClick={() => deletePage(page.id)} />
                    </div>)
            }
        </>
    )
}

export const AddEditGroupModal = ({ show, onHide, saveGroup, saveTemplateApiFlag, allavailablepages, existingGroupData, resetselecteddata }) => {
    const [groupId, setGroupId] = useState(existingGroupData !== undefined ? existingGroupData.WizardGroupID: undefined)
    const [groupName, setGroupName] = useState(existingGroupData !== undefined ? existingGroupData.GroupName: "");
    const [selectedPages, setSelectedPages] = useState(existingGroupData !== undefined ? existingGroupData.Pages :[])
    const [pageOptions, setPageOptions] = useState([{}])

    useEffect(() => {
        // console.log("existingGroupData: ", existingGroupData)
      if(existingGroupData !== undefined){
        const pages = []
        for(let [index, page] of existingGroupData.Pages.entries()){
            pages.push({
                "id": index+1,
                "label": page["WizardPageId"],
                "value": page["WizardPageId"]
            })
        }
        setSelectedPages(pages)
        setGroupId(existingGroupData.WizardGroupID)
        setGroupName(existingGroupData.GroupName)
      }
    }, [existingGroupData])
    
    useEffect(() => {
        // console.log("ALL AVAILABLE PAGES: ", allavailablepages)
        const optionList = []
        for (let page of allavailablepages) {
            optionList.push({
                "label": page.TemplateName,
                "value": page.WizardPageId
            })
        }
        setPageOptions(optionList)
    }, [allavailablepages])

    const addGroupName = (e) => {
        setGroupName(e.target.value)
    }

    return (

        <Modal
            show
            onHide
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            scrollable="false"
            style={{
                maxHeight: 500,
                overflow: "auto",
                top: "20%"
            }}
        >
            <Modal.Header>
                <h5 style={{ marginBottom: 0, marginRight: 20, marginLeft: 10 }}>Add Template Group</h5>
            </Modal.Header>
            <Modal.Body className={"flex-column-start-center"} style={{ padding: "0px 15px" }}>
                <Row className="no-gutter" style={{ marginBottom: 20, width: "100%" }}>
                    <div className={"flex justify-content-center align-items-center"} style={{ marginBottom: 20 }}>
                        <label style={{ fontWeight: 500, fontSize: 8 }}>Group Name</label>
                        <input type="text" className="form-control move-left custom-input"
                            placeholder={'Enter Group Name'}
                            value={groupName}
                            onChange={addGroupName}
                            autoComplete="nope"
                        />

                    </div>
                    <PageSelector
                        pageoptions={pageOptions}
                        selectedpages= {selectedPages}
                        setselectedpages={(pages) => setSelectedPages(pages)}
                    />
                </Row>
            </Modal.Body>
            <Modal.Footer className="action-footer">
                <Row className='no-gutter' style={{ padding: 0, margin: 0 }}>
                    <Col md={6} style={{ width: "fit-content" }}>
                        <Button
                            onClick={() => saveGroup({"id":groupId, "name":groupName}, selectedPages, onHide)}
                            className={"custom-button"}
                            style={{ maxHeight: "30px" }}
                        >   {
                                saveTemplateApiFlag ?
                                    <div style={{ marginRight: 6 }}>
                                        <ThreeCircles
                                            height="15"
                                            width="15"
                                            color="#4fa94d"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel="three-circles-rotating"
                                            outerCircleColor="var(--theme1_tertiary)"
                                            innerCircleColor="var(--theme1_secondary)"
                                            middleCircleColor="var(--theme1_primary)"
                                        />
                                    </div>
                                    :
                                    <p className="icon-wrapper" style={{ marginBottom: 0, marginTop: 0 }}>
                                        OK
                                    </p>
                            }
                        </Button>
                    </Col>
                    <Col md={6} className="flex-row-space-center" style={{ padding: "0px 10px 0px 0px", width: "fit-content" }}>
                        <Button
                            onClick={() => { 
                                onHide()
                                resetselecteddata()
                            }}
                            className="custom-button"
                        >
                            <p className="icon-wrapper" style={{ marginBottom: 0, marginTop: 0 }}>
                                Cancel
                            </p>
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}