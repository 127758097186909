import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import InputBox from './input_components/InputBox';
import { FormContext } from '../reusable_components/input_fields/FormContext'
import Element from '../reusable_components/input_fields/Input';
import { v4 } from "uuid";
import * as Icon from "react-bootstrap-icons";
import styled from 'styled-components';
import { logout } from "../../utils";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { getFromLS } from '../../utils/storage';
import { useNavigate } from 'react-router-dom'
import CustomButton from '../reusable_components/custom_button_component/Button';

const ColumnContainer = styled.div`
  margin: 8px;
  border: 0px solid #bbb;
  border-radius: 2px;
  width: 100%;
  background-color: #fbfbfc;
  display: flex;
  flex-direction: column;
`;

// const TEMPLATE_NAME_MAPPER = {
//     "Client Onboarding": client_onboarding,
//     "Task Creation": task_creation,
//     "Staff Creation": staff_creation,
//     "Custom": custom
// }

const applogo = {
    "Cygnus": require("../../pages/vendor_images/undefined.png"),
    "HubSpot": require("../../pages/vendor_images/HBSPT.png"),
    "SafeSend": require("../../pages/vendor_images/SFSND.png")
}

const CreateWizardFormModal = (props) => {
    const [wizardPageName, setWizardPageName] = useState(props.wizardformname)
    const [wizardPageNameFlag, setWizardPageNameFlag] = useState(false)
    useEffect(() => {
        if (!wizardPageNameFlag) {
            if (props.wizardformname !== undefined) {
                setWizardPageName(props.wizardformname)
                setWizardPageNameFlag(true)
            }
        }
    }, [props.wizardformname])

    const [flag, setFlag] = useState(props.flag)
    useEffect(() => {
        setFlag(props.flag)
    }, [props.flag])

    useEffect(() => {
        props.setwizardformname(wizardPageName)
    }, [wizardPageName])

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            // backdropClassName="custom-backdrop"
            backdrop="static"
            centered
            className='form-modal'
            scrollable="false"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <Row className='no-gutter'>
                    <div>
                        <label className="form-label move-left">Wizard form name</label>
                        <input type="text" className="form-control move-left custom-input"
                            placeholder={'Enter a name for your form'}
                            value={wizardPageName}
                            onChange={(event) => setWizardPageName(event.target.value)}
                        />
                    </div>
                </Row>
            </Modal.Body>
            <Modal.Footer className="action-footer">
                <Button className="custom-button" style={{ padding: "10px 20px" }} onClick={() => props.createwizardform()} >
                    {flag ? "Update" : "Create form"}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ConfirmationModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            // backdropClassName="custom-backdrop"
            backdrop="static"
            centered
            className='form-modal'
            scrollable="false"
        >
            <Modal.Header closeButton>
                <h4><Icon.ExclamationOctagonFill color='orange' style={{ fontSize: 30, margin: 20 }} /> Have you finalized all the required changes?</h4>
            </Modal.Header>
            <Modal.Body style={{ padding: "0px 20px" }}>
                <Row className='no-gutter'>
                    <h6 style={{ lineHeight: 2 }}>Once the template is finalized and saved you will not be able to add or delete any fields in the template. Please make sure the structure is as required.</h6>
                </Row>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "space-between", margin: "0px 10px 10px 10px" }}>
                <div>
                    <Button className="custom-button" style={{ padding: "10px 20px" }} onClick={() => {
                        props.savewizardform()
                        props.onHide()
                    }} >
                        Save
                    </Button>
                </div>
                <div>
                    <Button className="custom-button" style={{ padding: "10px 20px" }} onClick={() => props.onHide()} >
                        Cancel
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

const WizardFormLayout = (props) => {
    // console.log("WIZXARD FORM LAYOUT PROPS: ", props)
    let drag_data_format = {
        fields: {
        },
        columns: {
            'column': {
                id: 'column',
                fieldIds: [],
            },
        }
    }
    const navigate = useNavigate()
    const [initialData, setInitialData] = useState(drag_data_format)
    const [fields, setFields] = useState([])
    const [previewComponents, setPreviewComponents] = useState([])
    const [createWizardFormModalShow, setCreateWizardFormModalShow] = useState(false)
    const [confirmationModalSHow, setConfirmationModalShow] = useState(false)
    const [fullWindowModalShow, setFullWindowModalShow] = useState(false)
    const [wizardPageName, setWizardPageName] = useState("")
    const [wizardPageId, setWizardPageId] = useState()
    const [update, setUpdate] = useState(false)
    const [final, setFinal] = useState(false)
    const [templateStatusController, setTemplateStatusController] = useState(false)
    const [buttonFlag, setButtonFlag] = useState(false)
    const [saveWizardFormApiProgress, setWizardSaveFormApiProgress] = useState(false)
    // Setting HEADER select options for TITLE input type
    const [selectedFieldOptions, setSelectedFieldOptions] = useState([
        {
            value: "",
            label: "",
            id: "",
        }
    ])
    useEffect(() => {
        const token = getFromLS("token")
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/firm_template_fields/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.success === true) {
                    // console.log("SELECTED FIELD OPTIONS: ", JSON.parse(data["data"][0]["SelectedFields"]))
                    setSelectedFieldOptions(data["data"]["SelectedFields"])
                } else {
                    toast.error(
                        "Failed to fetch selected fields: " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch((err) => console.log("Err: ", err));
    }, [])

    const [fieldList, setFieldList] = useState([""])
    useEffect(() => {
        if (props.fields.length > 0) {
            setFieldList(props.fields)
        }
    }, [props.fields])

    /**
     * Setting the first field by default on first load
     */
    useEffect(() => {
        if (fieldList === undefined) {
            // THIS IS JUST A FAIL SAFE
            setCreateWizardFormModalShow(true)
            const id = v4()
            drag_data_format.columns["column"].fieldIds.push(id)
            Object.assign(drag_data_format.fields, {
                [id]: {
                    "id": id, "schema": [
                        {
                            "id": id,
                            "label": "",
                            "mandatory": false,
                            "hidden": false,
                            "disabled": false,
                            "placeholder": "",
                            "type": "text",
                            "example": null,
                            "radiogroup": null,
                            "pivot": true,
                            "pivotreverse": false,
                            "category": "H1",
                            "value": "",
                            "options": [{ "id": id, "label": "", "value": "" }],
                            "width": 12,
                            "margins": { "top": 5, "right": 0, "bottom": 5, "left": 0 }
                        }
                    ]
                }
            })
            setInitialData(drag_data_format)
        } else {
            if (fieldList.length > 0) {
                // console.log("PROPS for LAYOUT:", props.createwizardformoption)
                if (Object.keys(props.createwizardformoption).length > 0) {
                    setCreateWizardFormModalShow(false)
                    setWizardPageName(props.createwizardformoption.template_name)
                    setFinal(props.createwizardformoption.status === "Draft" ? false : true)
                    setTemplateStatusController(props.createwizardformoption.status)
                    setInitialData(props.createwizardformoption.initial_data)
                    if (props.createwizardformoption.id !== undefined) {
                        setUpdate(true)
                        setWizardPageId(props.createwizardformoption.id)
                    }
                    setButtonFlag(true)
                } else {
                    setButtonFlag(false)
                    // console.log("FIELDS: ", fieldList)
                    if (props.fields.length > 0) {
                        for (let field of fieldList) {
                            const id = v4()
                            const options = []
                            if (field.FieldId.toString() === '25') {
                                const optionsJson = JSON.parse(field.Options)
                                for (let state of Object.keys(optionsJson)) {
                                    for (let city of optionsJson[state]) {
                                        options.push(
                                            { "id": city, "label": city, "value": city }
                                        )
                                    }
                                }
                            } else if (field.FieldId.toString() === '26') {
                                const optionsJson = JSON.parse(field.Options)
                                for (let state of Object.keys(optionsJson)) {
                                    options.push(
                                        { "id": state, "label": state, "value": state }
                                    )
                                }
                            } else if (field.FieldId.toString() === '29') {
                                const optionsJson = JSON.parse(field.Options)
                                for (let code of optionsJson) {
                                    options.push(
                                        { "id": code, "label": code, "value": code }
                                    )
                                }
                            }

                            drag_data_format.columns["column"].fieldIds.push(id)
                            Object.assign(drag_data_format.fields, {
                                [id]: {
                                    "id": id, "schema": [
                                        {
                                            "id": id,
                                            "allfields_id": field.FieldId,
                                            "allfields_code": field.FieldCode,
                                            "allfields_category": field.Category,
                                            "source": applogo[field["Source of truth"]],
                                            "label": field.FieldName,
                                            "mandatory": false,
                                            "hidden": false,
                                            "disabled": false,
                                            "placeholder": "",
                                            "type": field.FieldType,
                                            "example": null,
                                            "radiogroup": null,
                                            "pivot": true,
                                            "pivotreverse": false,
                                            "category": "H1",
                                            "value": "",
                                            "options": options,
                                            "width": 12,
                                            "margins": { "top": 5, "right": 0, "bottom": 5, "left": 0 }
                                        }
                                    ]
                                }
                            })
                        }
                        setInitialData(drag_data_format)
                    } else {
                        // THIS IS JUST A FAIL SAFE
                        const id = v4()
                        drag_data_format.columns["column"].fieldIds.push(id)
                        Object.assign(drag_data_format.fields, {
                            [id]: {
                                "id": id, "schema": [
                                    {
                                        "id": id,
                                        "allfields_id": v4(),
                                        "allfields_code": "custom",
                                        "allfields_category": "custom",
                                        "label": "",
                                        "mandatory": false,
                                        "hidden": false,
                                        "disabled": false,
                                        "placeholder": "",
                                        "type": "text",
                                        "example": null,
                                        "radiogroup": null,
                                        "pivot": true,
                                        "pivotreverse": false,
                                        "category": "H1",
                                        "value": "",
                                        "options": [{ "id": id, "label": "", "value": "" }],
                                        "width": 12,
                                        "margins": { "top": 5, "right": 0, "bottom": 5, "left": 0 }
                                    }
                                ]
                            }
                        })
                        setInitialData(drag_data_format)
                    }
                }
            }
        }
    }, [fieldList])

    // useEffect(() => {
    //     console.log("INITIAL DATA: ", initialData)
    // }, [initialData])

    /**
     * Update fields and preview components everytime initialData changes
     */
    useEffect(() => {
        // console.log("INITIAL DATA: ", initialData.columns["column"].fieldIds.map(fieldId => initialData.fields[fieldId]))
        setFields(initialData.columns["column"].fieldIds.map(fieldId => initialData.fields[fieldId]))
        const preview = []
        if (Object.keys(initialData.fields).length > 0) {
            for (let fieldId of initialData.columns["column"].fieldIds) {
                const schema = initialData.fields[fieldId]["schema"]
                preview.push(...schema)
            }
        }
        setPreviewComponents(preview)
    }, [initialData])

    /**
     * Create a new component and add it to the initial data
     */
    const addInputBox = () => {
        const id = v4()
        const newInitialData = Object.assign({}, initialData)
        newInitialData.columns["column"].fieldIds.push(id)
        Object.assign(newInitialData.fields, {
            [id]: {
                "id": id, "schema": [
                    {
                        "id": id,
                        "allfields_id": v4(),
                        "allfields_code": "custom_field",
                        "allfields_category": "custom",
                        "label": "",
                        "mandatory": false,
                        "hidden": false,
                        "approval": false,
                        "disabled": false,
                        "placeholder": "",
                        "type": "text",
                        "example": null,
                        "radiogroup": null,
                        "pivot": true,
                        "pivotreverse": false,
                        "category": "H1",
                        "value": "",
                        "options": [{ "id": id, "label": "", "value": "" }],
                        "width": 12,
                        "margins": { "top": 5, "right": 0, "bottom": 5, "left": 0 }
                    }
                ]
            }
        })
        setInitialData(newInitialData)
    }

    const saveWizardForm = () => {
        setWizardSaveFormApiProgress(true)
        if (update) {
            let payloadFormat;
            payloadFormat = {
                "WizardPageId": wizardPageId,
                "WizardPageName": wizardPageName,
                "InputStructure": initialData,
                "PreviewStructure": previewComponents,
                "Status": final ? "Final" : "Draft"
            }
            // console.log("PAYLOAD FORMAT: ", payloadFormat)
            const token = getFromLS("token")
            const postOptions = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payloadFormat),
            };

            fetch(`${process.env.REACT_APP_BASE_URI}/workflow/wizard_pages/`, postOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.success === true) {
                        setWizardSaveFormApiProgress(false)
                        toast.success(
                            wizardPageName + " successfully updated!",
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-success",
                            }
                        );
                    } else {
                        setWizardSaveFormApiProgress(false)
                        toast.error(
                            "Saving failed, " + data.message,
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-error",
                            }
                        );
                    }
                })
                .catch((err) => console.log("Err: ", err));
        } else {
            let payloadFormat;
            payloadFormat = {
                "WizardPageName": wizardPageName,
                "InputStructure": initialData,
                "PreviewStructure": previewComponents,
                "Status": final ? "Final" : "Draft"
            }
            console.log("PAYLOAD FORMAT: ", payloadFormat)
            const token = getFromLS("token")
            const postOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payloadFormat),
            };

            fetch(`${process.env.REACT_APP_BASE_URI}/workflow/wizard_pages/`, postOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.success === true) {
                        setWizardSaveFormApiProgress(false)
                        setWizardPageId(data.data.WizardPageId)
                        toast.success(
                            wizardPageName + " successfully saved!",
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-success",
                            }
                        );
                    } else {
                        setWizardSaveFormApiProgress(false)
                        toast.error(
                            "Saving failed, " + data.message,
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-error",
                            }
                        );
                    }
                })
                .catch((err) => console.log("Err: ", err));
        }
    }

    /**
     * Update the values of fields in the PREVIEW form
     * @param {*} field_id: ID of field to be updated 
     * @param {*} event: The trigger event of input in preview form
     */
    const handleChange = (field_id, event) => {
        const newList = [...previewComponents]
        newList.forEach(field => {
            const { type, id } = field;
            if (field_id === id) {
                console.log("FIELD: ", field)
                console.log("EVENT: ", event)
                switch (type) {
                    case 'checkbox':
                        field['value'] = event.target.checked;
                        break;

                    case 'radio':
                        for (let fld of newList) {
                            if (fld["radiogroup"] === field["radiogroup"]) {
                                fld["value"] = false
                            }
                        }
                        field['value'] = event.target.checked;
                        break;

                    case 'select':
                        field['value'] = event.value;
                        break;

                    case 'file':
                        field['value'] = event[0];
                        break;

                    case 'date':
                        field['value'] = event;
                        break;

                    default:
                        field['value'] = event.target.value;
                        break;
                }
            }
            const newListTwo = [...newList]
            setPreviewComponents(newListTwo)
            console.log("PC:::::: ", newListTwo)
        })
    }

    /**
     * Replace the schema with received component 
     * @param {*} field_id: ID of field to be updated
     * @param {*} components: Field component 
     */
    const updateFieldSchema = (field_id, components) => {
        const newInitialData = Object.assign({}, initialData)
        if (Object.keys(newInitialData.fields).length > 0) {
            newInitialData.fields[field_id]["schema"] = components
            setInitialData(newInitialData)
        }
    }

    /**
     * Remove the input box
     * @param {*} inputboxid: The id of input box to be deleted
     */
    const removeInputBox = (inputboxid) => {
        const newInitialData = Object.assign({}, initialData)
        delete newInitialData.fields[inputboxid]
        const index = newInitialData.columns["column"].fieldIds.findIndex((obj) => obj === inputboxid)
        if (index !== -1) {
            newInitialData.columns["column"].fieldIds.splice(index, 1)
        }
        setInitialData(newInitialData)
    }

    /**
     * Dragging logic to reorder the input boxes 
     * @param {*} result: The properties of draggable and droppable components in DragDropContext
     * @returns: Updates the initial data with updated order
     */
    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result;
        const initialDataCopy = JSON.parse(JSON.stringify(initialData))
        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const column = initialDataCopy.columns[source.droppableId];
        const newFieldIds = Array.from(column.fieldIds);
        newFieldIds.splice(source.index, 1);
        newFieldIds.splice(destination.index, 0, draggableId);

        const newColumn = {
            ...column,
            fieldIds: newFieldIds,
        };

        const newState = {
            ...initialDataCopy,
            columns: {
                ...initialDataCopy.columns,
                [newColumn.id]: newColumn,
            },
        };
        setInitialData(newState);
    }

    const createWizardForm = () => {
        setCreateWizardFormModalShow(false)
    }



    const FullWindowModal = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
                className="add-wizard-modal"
                scrollable="false"
            >
                <Modal.Header className="preview-modal-header" closeButton>
                </Modal.Header>
                <Modal.Body className="preview-modal">
                    <Row className='no-gutter'>
                        <Col md={12}>
                                <div className="circle-wrapper">
                                    <div
                                        className={`circle current`}
                                        style={{ width: 50, height: 50 }}
                                    >
                                        #
                                    </div>
                                </div>
                                <h6 className='text-center' style={{ marginTop: 15 }}>{wizardPageName}</h6>
                                <FormContext.Provider value={{ handleChange }}>
                                    <Form className='no-padding'>
                                        <Row className='no-gutter'>
                                            {
                                                previewComponents &&
                                                previewComponents.map((field, i) =>
                                                    <Element
                                                        key={i}
                                                        field={field}
                                                    />
                                                )
                                            }
                                        </Row>
                                    </Form>
                                </FormContext.Provider>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="action-footer">
                </Modal.Footer>
            </Modal>
        )
    }

    const Switch = ({ isToggled, onClick }) => {
        return (
            <label className="switch">
                <input type="checkbox" checked={isToggled} onChange={onClick} style={{ appearance: "none" }} />
                <span className="super-admin-slider super-admin-round"></span>
            </label>
        );
    };

    return (
        <>
            <CreateWizardFormModal
                show={createWizardFormModalShow}
                onHide={() => setCreateWizardFormModalShow(false)}
                wizardformname={wizardPageName}
                setwizardformname={(name) => setWizardPageName(name)}
                flag={buttonFlag}
                createwizardform={() => createWizardForm()}
            />
            <ConfirmationModal
                show={confirmationModalSHow}
                onHide={() => setConfirmationModalShow(false)}
                savewizardform={() => saveWizardForm()}
            />
            <FullWindowModal
                show={fullWindowModalShow}
                onHide={() => setFullWindowModalShow(false)}
            />
            <Row className="no-gutter page-wrapper">
                <div className="navigator" style={{ position: "absolute", width: "fit-content", right: 25, marginTop: "-7rem" }}>
                    <Button className="nav-direction" onClick={() => props.transporter("wizard_form_builder")}><Icon.CaretLeftFill />Wizard Forms </Button>
                </div>
                <Col md={6} className='flex-column-center-center'>
                    <div className='flex-row-space-center' style={{ width: "100%", padding: "10px 0px" }}>
                        <h3 style={{ width: "fit-content", marginBottom: 10 }}>{wizardPageName === "" ? "Untitled form" : wizardPageName}</h3>
                        <Icon.Pencil style={{ fontSize: 20, cursor: "pointer", width: "fit-content", margin: "0px 10px 0px 0px" }} onClick={() => setCreateWizardFormModalShow(true)} />
                    </div>
                    <Row className='flex-row-center-center' style={{ width: "100%", padding: "10px 0px" }}>
                        <Col md={6}>
                            {
                                templateStatusController !== "Final" ?
                                    <Button className="custom-button"
                                        style={{
                                            display: "inline-block",
                                            padding: "10px 10px",
                                        }}
                                        onClick={() => addInputBox()} >
                                        <Icon.PlusCircleFill style={{ fontSize: 20, margin: "0px 5px 0px 0px" }} />
                                        New field
                                    </Button>
                                    :
                                    <></>
                            }
                        </Col>
                        <Col md={6} className='flex-row-center-end'>
                            <div className="toggler flex-column-center-center" style={{ marginRight: 10 }}>
                                <label style={{ fontWeight: 700, fontSize: 10 }}>{final ? "Final" : "Draft"}</label>
                                <Switch
                                    isToggled={final}
                                    onClick={event => templateStatusController !== "Final" ? setFinal(event.target.checked) : ""}
                                ></Switch>
                            </div>
                            <CustomButton
                                progress={false}
                                onclick={() => final ? setConfirmationModalShow(true) : saveWizardForm()}
                                content={"Save"}
                            />
                            {/* <Button className="custom-button"
                                style={{
                                    padding: "10px 10px",
                                    float: "right"
                                }}
                                onClick={() => saveWizardForm()} >
                                Save
                            </Button> */}
                        </Col>
                    </Row>
                </Col>
                <Col md={6} className='flex-row-space-center'>
                    <h4></h4>
                    <h4 style={{ margin: "50px 0px 0px 0px", textAlign: "center" }}>Wizard Page Preview</h4>
                    <Button className="custom-button" style={{ padding: "10px 10px", margin: "50px 0px 0px 0px" }} onClick={() => setFullWindowModalShow(true)} >
                        <Icon.ArrowUpRightSquareFill style={{ fontSize: 20, width: "fit-content", margin: "0px 10px 0px 0px" }} />
                        Show in pop-up
                    </Button>
                </Col>
            </Row>
            <Row className="no-gutter">
                {
                    initialData !== undefined ?
                        <Col md={4} className="yscrollable">
                            <DragDropContext
                                onDragEnd={onDragEnd}
                            >
                                <ColumnContainer
                                    key={initialData.columns["column"].id}
                                    column={initialData.columns["column"]}
                                >
                                    <Droppable droppableId={"column"}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                isDraggingOver={snapshot.isDraggingOver}
                                            >
                                                {fields.map((field, index) =>
                                                    <Draggable key={field.id} draggableId={field.id} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                className='flex-row-center-start draggable-inputbox-wrapper'
                                                                style={{ margin: "10px 0px" }}
                                                                ref={provided.innerRef}
                                                                isDragging={snapshot.isDragging}
                                                                {...provided.draggableProps}
                                                            >
                                                                <InputBox
                                                                    inputboxid={field.id}
                                                                    existingfieldschema={field.schema}
                                                                    updatefieldschema={(components) => updateFieldSchema(field.id, components)}
                                                                    key={field.id}
                                                                    removeinputbox={(inputboxid) => removeInputBox(inputboxid)}
                                                                    provided={provided}
                                                                    templatestatus={templateStatusController}
                                                                    selectedfieldoptions={selectedFieldOptions}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </ColumnContainer>
                            </DragDropContext>
                        </Col>
                        :
                        <></>
                }
                <Col md={8} className="yscrollable flex-row-start-center">
                    <div className='wizard-page-builder-preview'>
                        <div className="circle-wrapper">
                            <div
                                className={`circle current`}
                                style={{ width: 50, height: 50 }}
                            >
                                #
                            </div>
                        </div>
                        <h6 className='text-center' style={{ marginTop: 15 }}>{wizardPageName}</h6>
                        <FormContext.Provider value={{ handleChange }}>
                            <Form className='no-padding'>
                                <Row className='no-gutter'>
                                    {
                                        previewComponents &&
                                        previewComponents.map((field, i) =>
                                            <Element
                                                key={i}
                                                field={field}
                                            />
                                        )
                                    }
                                </Row>
                            </Form>
                        </FormContext.Provider>
                    </div>
                </Col>
            </Row >
        </>
    )
}

export default WizardFormLayout;