import React, {
    useState,
    useMemo,
    useCallback,
    useRef,
    useEffect
} from "react";

import {
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import { ThreeCircles, Bars } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AgGridReact } from "ag-grid-react";
import { logout } from "../../../utils/index.js";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import * as Icon from "react-bootstrap-icons";
import '../EngagementLetter.css';
import { createPortal } from "react-dom";
import CreateTemplateModal from '../create_template_modal/CreateTemplateModal.js';
import TemplatePreviewModal from '../template_preview_modal/TemplatePreviewModal.js';
import AddNewWizardModal from "../add_new_wizard_modal/AddNewWizardModal.js";
import UploadSpreadsheetModal from "../upload_spreadsheet_modal/UploadSpreadsheetModal.js"
import Select from "react-select";

const EngagementLetterDetailsPage = (props) => {
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [engagementProcessedTemplateDetails, setEngagementProcessedTemplateDetails] = useState([]);
    const [templateName, setTemplateName] = useState(localStorage.getItem('doc_original_file_name'));
    const [templateId, setTemplateId] = useState(localStorage.getItem('template_id'));
    const [sendForSignatureStatus, setSendForSignatureStatus] = useState(false);
    const [sendForApprovalStatus, setSendForApprovalStatus] = useState(false);
    const [createTemplateModalShow, setCreateTemplateModalShow] = useState(false);
    const [docFileName, setDocFileName] = useState("");
    const [spreadsheetFileUploading, setSpreadsheetFileUploading] = useState(false);
    const [addNewWizardShow, setAddNewWizardShow] = useState(false);
    const [uploadSpreadsheetModal, setUploadSpreadsheetModalShow] = useState(false);
    const [addNewWizardSaveFlag, setAddNewWizardSaveFlag] = useState(false);
    const [engagementLetterTemplateOptions, setEngagementLetterTemplateOptions] = useState([])
    const [templatePreviewModal, setTemplatePreviewModal] = useState(false);
    const [pdfFileUrl, setPdfFileUrl] = useState();

    const toastBasicConfig = {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };

    const toastSuccessConfig = {
        ...toastBasicConfig,
        className: "toastify-color-success",
    };

    const toastErrorConfig = {
        ...toastBasicConfig,
        className: "toastify-color-error",
    };

    const fetchEngagementProcessedTemplatePageData = useCallback(async () => {
        // Reset state
        resetState();

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };

        setRefreshGrid(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/processed_templates/`, requestOptions);

            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized error
                    logout();
                    alert("Session ended , Please login back");
                } else {
                    throw new Error("Request failed.");
                }
            }

            const data = await response.json();
            console.log(data)
            const details = data.data;

            setEngagementProcessedTemplateDetails(details);

        } catch (error) {
            console.error('Error fetching engagement processed template page data:', error);
        } finally {
            setRefreshGrid(false);
        }
    }, []);

    const send_for_signature = () => {
        // Get the AgGridReact instance from the ref
        const gridApi = gridRef.current.api;

        // Get selected rows
        const selectedRows = gridApi.getSelectedRows();

        // Fetch ProcessedTemplateId for selected ClientIds using the map
        const selectedProcessedTemplateIds = selectedRows.map(row => row.ProcessedTemplateId);

        if (selectedProcessedTemplateIds.length <= 0) {
            toast.error(
                "Please select one or more letters before sending for signature.",
                toastErrorConfig
            );
        }

        const incompleteStatusFound = selectedRows.some(row => 
            !row.Status.includes("Completed")
        );

        if (incompleteStatusFound) {
            toast.error(
                "Mail merge status is not completed for one or more selected letters. Complete the mail merge before sending for signature",
                toastErrorConfig
            );
            return;
        }

        const isApproved = selectedRows.some(row => row.ApprovalStatus !== "Approved");

        if (isApproved) {
            toast.error(
                "One or more selected letters are not approved. Please complete the approval process before sending for signature.",
                toastErrorConfig
            );
            return;
        }
    
        const requestBody = {
            "ProcessedTemplateIds": selectedProcessedTemplateIds
        }

        setSendForSignatureStatus(true);

        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/send_processed_template_for_signature/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(requestBody)
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized error
                    logout();
                    alert("Session ended , Please login back");
                } else {
                    throw new Error("Request failed.");
                }
            }

            return response.json();
        })
        .then((data) => {
            if (data.success) {

                toast.success(
                    "Send for signature successful!",
                    toastSuccessConfig
                );
            } else {
                toast.error(
                    `Send for signature failed: ${data.message}`,
                    toastErrorConfig
                );
            }
        })
        .catch(error => {
            // Handle error
            console.error('Error:', error);
        })
        .finally(() => {
            setSendForSignatureStatus(false);
        });
    };

    const send_for_approval = () => {
        // Get the AgGridReact instance from the ref
        const gridApi = gridRef.current.api;

        // Get selected rows
        const selectedRows = gridApi.getSelectedRows();
        console.log("selectedRows", selectedRows);

        if (selectedRows.length <= 0) {
            toast.error(
                "Please select one or more letters before sending for approval.",
                toastErrorConfig
            );

            return;
        }

        const incompleteStatusFound = selectedRows.some(row => 
            !row.Status.includes("Completed")
        );

        if (incompleteStatusFound) {
            toast.error(
                "Mail merge status is not completed for one or more selected letters. Complete the mail merge before sending for approval.",
                toastErrorConfig
            );
            return;
        }

        const isApproved = selectedRows.some(row => row.ApprovalStatus === "Approved");

        if (isApproved) {
            toast.error(
                "One or more selected letters are already approved. Modify the engagement letter to resend for approval.",
                toastErrorConfig
            );
            return;
        }

        const requestBody = selectedRows

        setSendForApprovalStatus(true);

        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/send_processed_template_for_approval/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(requestBody)
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 401) {
                    // Handle unauthorized error
                    logout();
                    alert("Session ended , Please login back");
                } else {
                    throw new Error("Request failed.");
                }
            }

            return response.json();
        })
        .then((data) => {
            if (data.success) {

                toast.success(
                    "Send for approval successful!",
                    toastSuccessConfig
                );
            } else {
                toast.error(
                    `Send for approval failed: ${data.message}`,
                    toastErrorConfig
                );
            }
        })
        .catch(error => {
            // Handle error
            console.error('Error:', error);
        })
        .finally(() => {
            setSendForApprovalStatus(false);
        });
    }

    /**
     * Button cell renderer for the grid.
     * @param {Object} props - Props for the button cell renderer.
     * @returns Button for downloading and viewing the engagement letter.
     */
    const BtnCellRenderer = (props) => {
        const getEngagementLetter = useCallback(async (fileName, containerName) => {
            const getRequestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            };

            return await fetch(`${process.env.REACT_APP_BASE_URI}/mdm/download/blob/?FileName=${fileName}&ContainerName=${containerName}`, getRequestOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.blob();
                }).then((blob) => {
                    return blob;
                })
        }, []);

        const downloadEngagementLetterClick = async () => {
            const blob = await getEngagementLetter(props.data.FileName, "engagementletterfiles");
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'engagement_letter.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        const downloadESignedEngagementLetterClick = async () => {
            try {
                const blob = await getEngagementLetter(props.data.FileName, "signeddocumentfiles");
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'eSigned_engagement_letter.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                toast.error(
                    "The file is not ready yet. Please try again later.",
                    toastErrorConfig
                );
            }
        };

        const viewClick = async () => {
            try {
                const blob = await getEngagementLetter(props.data.FileName, "engagementletterfiles");
                const url = window.URL.createObjectURL(blob);

                setPdfFileUrl(url);

                setTemplatePreviewModal(true);
            } catch (error) {
                console.error('Error viewing engagement letter:', error);
            }
        };

        const editClick = () => {
            localStorage.setItem('processed_template_id', props.data.ProcessedTemplateId);

            const docFilename = props.data.DocFileName;
            const filenameWithoutExtension = docFilename.substring(0, docFilename.lastIndexOf('.')) || docFilename;
            localStorage.setItem("key", filenameWithoutExtension);

            setDocFileName(docFilename);

            localStorage.setItem('letter_file_name', props.data.FileName);

            localStorage.setItem('doc_original_file_name', undefined);

            localStorage.setItem('recipients', props.data.Recipients);
            localStorage.setItem('signatureFields', props.data.FrontendSignatureDataList);


            // localStorage.setItem('template_id', props.data.TemplateId);
            localStorage.setItem('isEdit', JSON.stringify(true));
            localStorage.setItem('edit_type', "EngagementLetter");

            const filteredClientData = Object.fromEntries(
                Object.entries(props.data).filter(([key, value]) =>
                  key.toLowerCase().includes('signer') && key.toLowerCase().includes('email')
                )
            );
              
            localStorage.setItem('client_details', JSON.stringify(filteredClientData));

            setCreateTemplateModalShow(true);
        }

        const not_allow_statuses = ["Initialized", "Processing", "In-Process"]
        
        const enable_buttons = !not_allow_statuses.includes(props.data.Status);

        return (
            <div className="icon-cell-container" style={{ justifyContent: "flex-start", gap: "15px" }}>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="button-tooltip">
                            {enable_buttons
                                ? "Edit letter"
                                : "Document not ready"
                            }
                        </Tooltip>
                    }
                    error={true}
                >
                    <div style={{
                        cursor: enable_buttons ? "pointer" : "not-allowed",
                        margin: "0px",
                        width: "14%"
                    }}>
                        <Button className="icon-button" style={{
                            pointerEvents: (
                                enable_buttons ? "all" : "none"
                            ),
                            padding: "0px", margin: "0px",
                            width: "100%", height: "100%"
                        }}
                            onClick={() => editClick()}>
                            <Icon.PencilSquare />
                        </Button>
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="button-tooltip">
                            {enable_buttons
                                ? "View document"
                                : "Document not ready"
                            }
                        </Tooltip>
                    }
                    error={true}
                >
                    <div style={{
                        cursor: enable_buttons ? "pointer" : "not-allowed",
                        margin: "0px",
                        width: "17%"
                    }}>
                        <Button className="icon-button" style={{
                            pointerEvents: (
                                enable_buttons ? "all" : "none"
                            ),
                            padding: "0px", margin: "0px",
                            width: "100%", height: "100%"
                        }}
                            onClick={() => viewClick()}>
                            <span className="file-icon" style={{ width: "max-content", height: "max-content" }}>
                                <img width="100%" height="100%" src="https://img.icons8.com/fluency-systems-regular/100/visible--v1.png" alt="visible--v1" />
                            </span>
                        </Button>
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="button-tooltip">
                            {enable_buttons
                                ? "Download document"
                                : "Document not ready"
                            }
                        </Tooltip>
                    }
                    error={true}
                >
                    <div style={{
                        cursor: enable_buttons ? "pointer" : "not-allowed",
                        margin: "0px",
                        width: "14%"
                    }}>
                        <Button className="icon-button" style={{
                            pointerEvents: (
                                enable_buttons ? "all" : "none"
                            ),
                            padding: "0px", margin: "0px",
                            width: "100%", height: "100%"
                        }}
                            onClick={() => downloadEngagementLetterClick()}>
                            <Icon.Download />
                        </Button>
                    </div>
                </OverlayTrigger>

                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="button-tooltip">
                            {props.data.SignatureStatus === "ESigned"
                                ? "Download eSigned document"
                                : "Document not eSigned"
                            }
                        </Tooltip>
                    }
                    error={true}
                >
                    <div style={{
                        cursor: props.data.SignatureStatus === "ESigned" ? "pointer" : "not-allowed",
                        width: "16%"
                    }}>
                        <Button className="icon-button" style={{
                            pointerEvents: (
                                props.data.SignatureStatus === "ESigned" ? "all" : "none"
                            ),
                            padding: "0px 0px 3px 0px", margin: "0px",
                            width: "100%", height: "100%"
                        }}
                            onClick={() => downloadESignedEngagementLetterClick()}>
                            <span className="file-icon" style={{ width: "max-content", height: "max-content" }}>
                                <img width="100%" height="100%" src="https://img.icons8.com/ios/100/agreement.png" alt="agreement" />
                            </span>
                        </Button>
                    </div>
                </OverlayTrigger>
            </div>
        );
    }

    const CreatedDateRenderer = (created_date_props) => {
        const formatDateTime = (dateTimeString) => {
            // Convert the input string to a Date object in the specified timezone offset
            const date = new Date(dateTimeString + '-05:52'); // Append the timezone offset to the input string

            // Adjust to user's local timezone
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0');

            // Format hours, minutes, and seconds
            let hour = date.getHours();
            const minute = String(date.getMinutes()).padStart(2, '0');
            const second = String(date.getSeconds()).padStart(2, '0');

            // Determine AM/PM notation
            const ampm = hour >= 12 ? 'PM' : 'AM';
            hour = hour % 12;
            hour = hour ? hour : 12; // Hour '0' should be '12'
            const formattedHour = String(hour).padStart(2, '0');

            // Format as "YYYY-MM-DD h:mm:ss A"
            return `${year}-${month}-${day} ${formattedHour}:${minute}:${second} ${ampm}`;
        };

        return (
            <span>
                {formatDateTime(created_date_props.data["CreatedDate"])}
            </span>
        )
    }

    const ApprovalStatusRenderer = (props) => {
        const [showPopup, setShowPopup] = useState(false);
        const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
        const buttonRef = useRef(null);
        const [approversData, setApproversData] = useState([]);
        const [approvalStatusRefreshGrid, setApprovalStatusRefreshGrid] = useState(false);

        const fetchApprovalPageData = useCallback(() => {

            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            };

            const params = `?ProcessedTemplateId=${props.data.ProcessedTemplateId}`;
            console.log(params)

            setApprovalStatusRefreshGrid(true);

            fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/processed_template_approval_details/${params}`, requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    const details = data.data;

                    setApproversData(details);
                })
                .finally(() => {
                    setApprovalStatusRefreshGrid(false);
                });
        }, []);

        // Function to update popup position
        const updatePopupPosition = () => {
            if (buttonRef.current) {
                const rect = buttonRef.current.getBoundingClientRect();
                setPopupPosition({
                    top: rect.bottom + window.scrollY - 5, // Calculate the position relative to the viewport and page
                    left: rect.left + window.scrollX - 485, // Adjust the left position to move it to the left side
                });
            }
        };

        useEffect(() => {
            if (showPopup) {
                fetchApprovalPageData();
                updatePopupPosition();
            }
        }, [showPopup]);

        const ApproverStatusRenderer = (approver_status_props) => {
            const { ApprovalStatus, Message } = approver_status_props.data;
            console.log("ApprovalStatus", ApprovalStatus)
            return (
                <div className="label-icon">
                    <span style={{ flexGrow: 1, textAlign: "left" }}>
                        {ApprovalStatus}
                    </span>
                    <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id="button-tooltip">
                                {Message ? Message : ApprovalStatus}
                            </Tooltip>
                        }
                        error={true}
                    >
                        <Button className="icon-button" style={{ padding: "0px 0px 0px 10px", margin: "0px" }}>
                            <Icon.QuestionCircleFill className="info-icon" />
                        </Button>

                    </OverlayTrigger>
                </div>
            );
        };

        const columnDefs = [
            { headerName: "Approver Name", field: "ApproverName" },
            { headerName: "Approver Role", field: "ApproverRole" },
            { headerName: "Approval Status", field: "ApprovalStatus", cellRenderer: ApproverStatusRenderer },
        ];

        return (
            props.data["ApprovalStatus"] !== ""
                ?
                <>
                    <div className="label-icon">
                        <span style={{ flexGrow: 1, textAlign: "left" }}>
                            {props.data["ApprovalStatus"]}
                        </span>

                        {props.data["ApprovalStatus"] ? (
                            <div
                                onMouseEnter={() => setShowPopup(true)}
                                onMouseLeave={() => setShowPopup(false)}
                                ref={buttonRef}
                            >
                                <Button className="icon-button" >
                                    <Icon.QuestionCircleFill className="info-icon" />
                                </Button>
                            </div>
                        )
                            :
                            null
                        }
                    </div>

                    {showPopup && createPortal(
                        <div
                            style={{
                                position: "absolute",
                                top: popupPosition.top,
                                left: popupPosition.left,
                                zIndex: 1000,
                                width: "500px",
                                border: "1px solid #ccc",
                                backgroundColor: "white",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                            onMouseEnter={() => setShowPopup(true)}
                            onMouseLeave={() => setShowPopup(false)}
                        >
                            <div className="ag-theme-balham" style={{ height: "200px", width: "100%" }}>
                                {!approvalStatusRefreshGrid
                                    ?
                                    <>
                                        <AgGridReact
                                            rowData={approversData}
                                            columnDefs={columnDefs}
                                            defaultColDef={{ flex: 1 }}
                                            suppressContextMenu={true}
                                            suppressCellFocus={true}
                                            onFirstDataRendered={onFirstDataRendered}
                                            noRowsOverlayComponent={noRowsOverlayComponent}
                                            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                                        />
                                    </>
                                    :
                                    <div
                                        className="icon-cell-container"
                                    >
                                        <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                                            <Bars
                                                height="80"
                                                width="80"
                                                color="var(--theme1_primary)"
                                                ariaLabel="bars-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />

                                            Loading your data
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>,
                        document.body
                    )}
                </>
                :
                null
        );
    };

    const SpreadsheetFileNameRenderer = (file_name_props) => {
        const MailMergeSpreadsheetId = file_name_props.data.MailMergeSpreadsheetId;

        const fileName = file_name_props.data["SpreadSheetOriginalFileName"];
        const hasExtension = fileName.includes('.');

        const downloadSpreadSheet = useCallback(() => {
            const getRequestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            };

            fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/download/spreadsheet/${MailMergeSpreadsheetId}`, getRequestOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.blob();
                }).then((blob) => {
                    const href = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
        }, []);

        return hasExtension ? (
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="button-tooltip">
                        Click to download
                    </Tooltip>
                }
                error={true}
            >
                <a
                    className="icon-button"
                    style={{ padding: 0, margin: 0, justifyContent: "left" }}
                    onClick={() => downloadSpreadSheet()}
                >
                    <span className="download-links">
                        {removeFileExtension(fileName)}
                    </span>
                </a>
            </OverlayTrigger>
        ) : (
            <span style={{ padding: 0, margin: 0, justifyContent: "left" }}>
                {fileName}
            </span>
        );
    }

    const TemplateFileNameRenderer = (file_name_props) => {
        const template_id = file_name_props.data.TemplateId;

        const downloadTemplate = useCallback((template_id, file_name) => {
            const getRequestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            };

            fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/download/template/?TemplateId=${template_id}`, getRequestOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.blob();
                }).then((blob) => {
                    const href = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', file_name);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
        }, []);

        return (
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="button-tooltip">
                        Click to download
                    </Tooltip>
                }
                error={true}
            >
                <div style={{ padding: 0, margin: 0 }}>
                    <a className="icon-button" style={{ padding: 0, justifyContent: "left" }} onClick={() => downloadTemplate(template_id, file_name_props.data["TemplateOriginalFileName"])}>
                        <span className="download-links">
                            {removeFileExtension(file_name_props.data["TemplateOriginalFileName"])}
                        </span>
                    </a>
                </div>
            </OverlayTrigger>
        )
    }

    const FileNameHeader = (props) => {
        const eMenu = useRef(null);
        const eLabel = useRef(null);
        const eText = useRef(null);
        const eSortAsc = useRef(null);
        const eSortDesc = useRef(null);
        const eSortMixed = useRef(null);

        const onMenuClicked = () => {
            props.showColumnMenu(eMenu.current);
        };

        const onSortClicked = (event) => {
            // In this example, we do multi-sort if Shift key is pressed
            props.progressSort(event.shiftKey);
        };

        const getSortIconClass = () => {
            switch (props.sort) {
                case 'asc':
                    return 'ag-sort-ascending-icon';
                case 'desc':
                    return 'ag-sort-descending-icon';
                case 'mixed':
                    return 'ag-sort-mixed-icon';
                default:
                    return '';
            }
        };

        return (
            <div className="label-icon">
                <div ref={eLabel} className="ag-header-cell-label" role="presentation" onClick={onSortClicked}>
                    <props.IconComponent style={{ marginRight: '5px' }} />
                    <span ref={eText} className="ag-header-cell-text">{props.headerName}</span>
                    <span className="ag-sort-indicator-container">
                        <span
                            ref={eSortAsc}
                            className={`ag-sort-indicator-icon ${props.sort === 'asc' ? '' : 'ag-hidden'}`}
                            aria-hidden="true"
                        >
                            <span className="ag-icon ag-icon-asc" unselectable="on" role="presentation"></span>
                        </span>
                        <span
                            ref={eSortDesc}
                            className={`ag-sort-indicator-icon ${props.sort === 'desc' ? '' : 'ag-hidden'}`}
                            aria-hidden="true"
                        >
                            <span className="ag-icon ag-icon-desc" unselectable="on" role="presentation"></span>
                        </span>
                        <span
                            ref={eSortMixed}
                            className={`ag-sort-indicator-icon ${props.sort === 'mixed' ? '' : 'ag-hidden'}`}
                            aria-hidden="true"
                        >
                            <span className="ag-icon ag-icon-mixed" unselectable="on" role="presentation"></span>
                        </span>
                    </span>
                </div>
                <span ref={eMenu} className="ag-header-icon ag-header-cell-menu-button" aria-hidden="true" onClick={onMenuClicked}>
                    <span className="ag-icon ag-icon-menu" unselectable="on" role="presentation"></span>
                </span>
            </div>
        );
    };



    const SignatureStatusRenderer = (signature_status_props) => {
        const { SignatureStatus, SignatureMessage } = signature_status_props.data;

        return (
            SignatureStatus !== ""
                ?
                <>
                    <div className="label-icon">
                        <span style={{ flexGrow: 1, textAlign: "left" }}>
                            {SignatureStatus}
                        </span>
                        <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip id="button-tooltip">
                                    {SignatureMessage ? SignatureMessage : SignatureStatus}
                                </Tooltip>
                            }
                            error={true}
                        >
                            <Button className="icon-button" style={{ padding: "0px 0px 0px 10px", margin: "0px" }}>
                                <Icon.QuestionCircleFill className="info-icon" />
                            </Button>

                        </OverlayTrigger>
                    </div>
                </>
                :
                null
        );
    };

    const MailMergeStatusRenderer = (mailmerge_status_props) => {
        const { Status, MailMergeMessage } = mailmerge_status_props.data;
        
        // Function to render the message with <br> for line breaks
        const formatMessage = (message) =>
        message.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));

        return (
            Status !== ""
                ?
                <>
                    <div className="label-icon">
                        <span style={{ flexGrow: 1, textAlign: "left" }}>
                            {Status}
                        </span>
                        <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip id="button-tooltip" style={{ textAlign: "left" }}>
                                    {MailMergeMessage ? formatMessage(MailMergeMessage) : Status}
                                </Tooltip>
                            }
                            error={true}
                        >
                            <Button className="icon-button" style={{ padding: "0px 0px 0px 10px", margin: "0px" }}>
                                <Icon.QuestionCircleFill className="info-icon" />
                            </Button>

                        </OverlayTrigger>
                    </div>
                </>
                :
                null
        );
    };

    const defaultColumnDefs = [
        {
            headerName: "Client Id",
            field: "ClientId",
            minWidth: 150,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            pinned: "left",
            valueGetter: (params) => {
                if (params.data?.Status === "Initialized") {
                    return "Not available yet";
                }
                return params.data?.ClientId || "Not available";
            }
        },
        {
            headerName: "Client Name",
            field: "ClientName",
            minWidth: 150,
            valueGetter: (params) => {
                if (params.data?.Status === "Initialized") {
                    return "Not available yet";
                }
                return params.data?.ClientName || "Not available";
            }
        },
        {
            headerName: "Client Email",
            field: "Signer1Email",
            minWidth: 150,
            valueGetter: (params) => {
                if (params.data?.Status === "Initialized") {
                    return "Not available yet";
                }
                return params.data?.Signer1Email || "Not available";
            }
        },
        {
            headerName: "Template Name",
            field: "TemplateOriginalFileName",
            cellRenderer: TemplateFileNameRenderer,
            headerComponent: FileNameHeader,
            headerComponentParams: {
                headerName: "Template Name",
                IconComponent: Icon.FileEarmarkWordFill,
                menuIcon: "fa-bars"
            },
            minWidth: 200,
        },
        {
            headerName: "Spreadsheet Name",
            field: "SpreadSheetOriginalFileName",
            cellRenderer: SpreadsheetFileNameRenderer,
            headerComponent: FileNameHeader,
            headerComponentParams: {
                headerName: "Spreadsheet Name",
                IconComponent: Icon.FileEarmarkExcelFill,
                menuIcon: "fa-bars"
            },
            minWidth: 200,
        },
        {
            headerName: "Mailmerge Status",
            field: "Status",
            minWidth: 150,
            cellRenderer: MailMergeStatusRenderer
        },
        {
            headerName: "Approval Status",
            field: "ApprovalStatus",
            minWidth: 150,
            cellRenderer: ApprovalStatusRenderer
        },
        {
            headerName: "Signature Status",
            field: "SignatureStatus",
            minWidth: 150,
            cellRenderer: SignatureStatusRenderer
        },
        {
            headerName: "Created Date",
            field: "CreatedDate",
            minWidth: 180,
            cellRenderer: CreatedDateRenderer,
        },
        {
            headerName: "Action",
            field: "",
            maxWidth: 130,
            minWidth: 130,
            sortable: false,
            cellRenderer: BtnCellRenderer,
            pinned: "right"
        }
    ];
    

    // Column definitions for AgGrid
    const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);

    const resetState = () => {
        setRefreshGrid(false);
        setEngagementProcessedTemplateDetails([]);
        setColumnDefs(defaultColumnDefs);
    };

    const fetchTemplateApprovalConfigDetails = (templateId) => {
        return new Promise((resolve, reject) => {
            let token = localStorage.getItem("token");
            const getRequestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            };

            if (templateId !== undefined) {
                fetch(
                    `${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/template_approval_configuration_details/?TemplateId=${templateId}`,
                    getRequestOptions
                )
                    .then((response) => {
                        if (!response.ok) {
                            if (response.status === 401) {
                                logout();
                                alert("Session ended , Please login back");
                            } else {
                                reject(new Error("Request failed."));
                            }
                        }
                        return response.json();
                    })
                    .then((result) => {
                        resolve(result["data"]);
                    })
                    .catch((error) => reject(error));
            } else {
                reject(new Error("Template ID is undefined"));
            }
        });
    };


    useEffect(() => {
        fetchEngagementProcessedTemplatePageData();
    }, []);
    // Reference to the AgGrid component
    const gridRef = useRef();

    /**
     * Callback to size columns to fit after the first data rendering.
     * @param {Object} params - Parameters for the callback.
     */
    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    // Style for the AgGrid
    const gridStyle = useMemo(() => ({ height: "85vh", width: "100%" }), []);

    // State and callback for the grid API
    const [gridApi, setGridApi] = useState(undefined);
    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        params.api.closeToolPanel();
    }, []);


    // Default column definition for AgGrid
    const defaultColDef = useMemo(
        () => ({
            sortable: true,
            filter: true,
            resizable: true,
            animateRows: true,
            filter: true,
            headerComponentParams: {
                menuIcon: "fa-bars",
            },
        }),
        []
    );

    /**
     * Custom overlay component for no rows.
     * @param {Object} props - Props for the overlay component.
     * @returns Overlay component for displaying a message when no rows are present.
     */
    const CustomNoRowsOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center flex-row-center-center"
                style={{ height: '9%' }}
            >
                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                    {props.noRowsMessageFunc()}
                </div>
            </div>
        );
    };

    const noRowsOverlayComponent = useMemo(() => { return CustomNoRowsOverlay }, []);

    const noRowsOverlayComponentParams = useMemo(() => { return { noRowsMessageFunc: () => !refreshGrid ? `No data available`:`Loading your data` } }, [refreshGrid]);

    const removeFileExtension = (filename) => {
        return filename.replace(/\.[^/.]+$/, "");
    };

    useEffect(() => {
      console.log("options: ", engagementLetterTemplateOptions)
    }, [engagementLetterTemplateOptions])
    

    // JSX for the page component
    return (
        <div className="engagement-letter-details-page">
            {createTemplateModalShow && (
                <CreateTemplateModal
                    docFileName={docFileName}
                    show={createTemplateModalShow}
                    onHide={() => {
                        setCreateTemplateModalShow(false);
                        fetchEngagementProcessedTemplatePageData();
                    }}
                />
            )}
            <UploadSpreadsheetModal
                show={uploadSpreadsheetModal}
                onHide={() => setUploadSpreadsheetModalShow(false)}
                refresh={() => fetchEngagementProcessedTemplatePageData()}
                engagementlettertemplates={engagementLetterTemplateOptions}
                needtemplatedetails={false}
                setfileuploading={(flag) => setSpreadsheetFileUploading(flag)}
            />

            <AddNewWizardModal
                show={addNewWizardShow}
                onHide={() => setAddNewWizardShow(false)}
                clientmasterdata={undefined}
                engagementlettertemplates={(elt)=> setEngagementLetterTemplateOptions(elt)}
                refresh={() => fetchEngagementProcessedTemplatePageData()}
                setapiflag={(flag) => setAddNewWizardSaveFlag(flag)}
            />

            {templatePreviewModal && (
                <TemplatePreviewModal
                    show={templatePreviewModal}
                    pdfFileUrl={pdfFileUrl}
                    onHide={() => setTemplatePreviewModal(false)}
                />
            )}
            <Row className='no-gutter'>
                <p className="display-6" style={{ height: "fit-content", width: "fit-content", padding: 0, marginBottom: 0 }}>
                    Engagement Letter Details
                </p>
            </Row>
            <Row className='no-gutter'>
                <Col md={12} className="d-flex justify-content-end" style={{ float: "right" }}>
                    <div style={{ marginBottom: "0rem", marginRight: "1rem", marginTop: "-2.1rem" }}>
                        <Button
                            onClick={() => setAddNewWizardShow(true)}
                            className="custom-button"
                        >
                            {
                                addNewWizardSaveFlag ?
                                    <div style={{ marginRight: 6 }}>
                                        <ThreeCircles
                                            height="15"
                                            width="15"
                                            color="#4fa94d"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel="three-circles-rotating"
                                            outerCircleColor="var(--theme1_tertiary)"
                                            innerCircleColor="var(--theme1_secondary)"
                                            middleCircleColor="var(--theme1_primary)"
                                        />
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ marginBottom: 0, marginTop: 0 }}>Add</p>
                        </Button>
                    </div>
                    <div style={{ marginBottom: "0rem", marginRight: "1rem", marginTop: "-2.1rem" }}>
                        <Button
                            onClick={() => setUploadSpreadsheetModalShow(true)}
                            className="custom-button"
                        >
                            {
                                spreadsheetFileUploading ?
                                    <div style={{ marginRight: 6 }}>
                                        <ThreeCircles
                                            height="15"
                                            width="15"
                                            color="#4fa94d"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel="three-circles-rotating"
                                            outerCircleColor="var(--theme1_tertiary)"
                                            innerCircleColor="var(--theme1_secondary)"
                                            middleCircleColor="var(--theme1_primary)"
                                        />
                                    </div>
                                    :
                                    null
                            }
                            <p style={{ marginBottom: 0, marginTop: 0 }}>Upload Spreadsheet</p>
                        </Button>
                    </div>
                    <div style={{ marginBottom: "0rem", marginRight: "1rem", marginTop: "-2.1rem" }}>
                        <Button
                            onClick={send_for_approval}
                            className="custom-button"
                        >
                            {sendForApprovalStatus ? (
                                <>
                                    <div style={{ marginRight: 6 }}>
                                        <ThreeCircles
                                            height="15"
                                            width="15"
                                            color="#4fa94d"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel="three-circles-rotating"
                                            outerCircleColor="#4fa94d"
                                            innerCircleColor="#4fa94d"
                                            middleCircleColor="#4fa94d"
                                        />
                                    </div>
                                    <p style={{ marginBottom: 0, marginTop: 0 }}>
                                        Sending...
                                    </p>
                                </>
                            ) : (
                                <p style={{ marginBottom: 0, marginTop: 0 }}>Send For Approval</p>
                            )}
                        </Button>
                    </div>
                    <div style={{ marginBottom: "0rem", marginRight: "1rem", marginTop: "-2.1rem" }}>
                        <Button
                            onClick={send_for_signature}
                            className="custom-button"
                        >
                            {sendForSignatureStatus ? (
                                <>
                                    <div style={{ marginRight: 6 }}>
                                        <ThreeCircles
                                            height="15"
                                            width="15"
                                            color="#4fa94d"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel="three-circles-rotating"
                                            outerCircleColor="#4fa94d"
                                            innerCircleColor="#4fa94d"
                                            middleCircleColor="#4fa94d"
                                        />
                                    </div>
                                    <p style={{ marginBottom: 0, marginTop: 0 }}>
                                        Sending...
                                    </p>
                                </>
                            ) : (
                                <p style={{ marginBottom: 0, marginTop: 0 }}>Send For Signature</p>
                            )}
                        </Button>
                    </div>
                    <div style={{ marginBottom: "0rem", marginTop: "-1.75rem" }}>
                        {
                            refreshGrid ?
                                <div>
                                    <ThreeCircles
                                        height="20"
                                        width="20"
                                        color="#4fa94d"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel="three-circles-rotating"
                                        outerCircleColor="var(--theme1_tertiary)"
                                        innerCircleColor="var(--theme1_secondary)"
                                        middleCircleColor="var(--theme1_primary)"
                                    />
                                </div>
                                :
                                <Icon.ArrowCounterclockwise onClick={() => fetchEngagementProcessedTemplatePageData()}
                                    style={{
                                        fontSize: 20,
                                        float: "right",
                                        border: "0px",
                                        cursor: "pointer"
                                    }}
                                />
                        }
                    </div>
                </Col>
            </Row>
            <Row className='no-gutter'>
                <Col md={12}>
                    <div className="ag-theme-balham" style={gridStyle}>
                        <AgGridReact
                            key={refreshGrid}
                            ref={gridRef}
                            rowData={engagementProcessedTemplateDetails}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                            alwaysShowHorizontalScroll={false}
                            alwaysShowVerticalScroll={false}
                            rowHeight={25}
                            rowSelection={"multiple"}
                            suppressContextMenu={true}
                            suppressCellFocus={true}
                            onFirstDataRendered={onFirstDataRendered}
                            noRowsOverlayComponent={noRowsOverlayComponent}
                            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                        />
                    </div>
                </Col>
            </Row>
            <ToastContainer />
        </div>
    )
};

export default EngagementLetterDetailsPage;