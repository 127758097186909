import React from "react";
import { useState, useEffect } from "react";
import {
    Row,
    Col,
    Modal,
} from "react-bootstrap";
import { Bars } from "react-loader-spinner";
import './TemplatePreviewModal.css';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import "bootstrap/dist/css/bootstrap.min.css";

const TemplatePreviewModal = (props) => {
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [pdfLoaded, setPdfLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        if (props.show) {
            console.log(props.pdfFileUrl)
        } else {
        }
    }, [props.show]);

    const resetState = () => {
        setRefreshGrid(false);
    };

    const handleClose = () => {
        props.onHide();
    };

    const handlePdfLoadSuccess = () => {
        setPdfLoaded(true);
    };

    const handlePageChange = (e) => {
        setCurrentPage(e.currentPage);
    };

    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <Modal
            {...props}
            onHide={handleClose}
            id="template-preview-modal"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            scrollable="false"
            className="template-preview-modal"
        >
            <Modal.Header className="preview-modal-header" closeButton>
            </Modal.Header>
            <Modal.Body className="preview-modal">
                <>
                {!refreshGrid
                    ?
                    <>
                        <Row className=''>
                            <Col md={12} style={{ padding: 0, margin: 0 }}>
                                <div className='pdf-viewer'>
                                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                                        <Viewer
                                            defaultScale={SpecialZoomLevel.ActualSize}
                                            scrollMode="Page"
                                            fileUrl={props.pdfFileUrl}
                                            plugins={[defaultLayoutPluginInstance]}
                                            onDocumentLoad={handlePdfLoadSuccess}
                                            onPageChange={handlePageChange}
                                        />
                                    </Worker>
                                </div>
                            </Col>
                        </Row>
                    </>
                    :
                    <div
                        className="flex-row-center-center"
                        style={{ marginTop: "20%" }}
                    >
                        <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                            <Bars
                                height="80"
                                width="80"
                                color="var(--theme1_primary)"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />

                            Loading your data
                        </div>
                    </div>
                }
                </>
            </Modal.Body>
            <Modal.Footer className="action-footer">
            </Modal.Footer>
        </Modal>
    )
}

export default TemplatePreviewModal;
