import React, {
    useState,
    useEffect
} from "react";
import {
    Row,
    Col,
    Modal,
    Form,
    Button
} from "react-bootstrap";
import { logout } from "../../../utils";
import { FormContext } from "../../reusable_components/input_fields/FormContext";
import Element from '../../reusable_components/input_fields/Input';
import './UploadSpreadsheetModal.css';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import * as Icon from "react-bootstrap-icons";
import { ThreeCircles } from "react-loader-spinner";
import Select from 'react-select';
import ExistingClientSelector from "./ExistingClientSelector";

const AddNewWizardModal = (props) => {
    const [wizardData, setWizardData] = useState([])
    const [selectedFields, setSelectedFields] = useState([])
    const [message, setMessage] = useState("")
    const [wizardDataResetCopy, setWizardDataResetCopy] = useState([])
    const [activePage, setActivePage] = useState(0)
    const [previewComponents, setPreviewComponents] = useState([])
    const [errors, setErrors] = useState([])
    const [engagementLetterTemplateOptions, setEngagementLetterTemplateOptions] = useState([])
    const [engagementLetterTemplate, setEngagementLetterTemplate] = useState({ "lable": "", "value": "" })
    const [clientAllData, setClientData] = useState(props.engagementrelateddata)
    const [clientMasterData, setClientMasterData] = useState(props.clientmasterdata)
    const [engagementLetterFor, setEngagementLetterFor] = useState()

    useEffect(() => {
        console.log("engagementLetterFor", engagementLetterFor)
    }, [engagementLetterFor])

    const removeFileExtension = (filename) => {
        return filename.replace(/\.[^/.]+$/, "");
    };
    // useEffect(() => {
    //     if (props.wizardata !== undefined) {
    //         setWizardData(JSON.parse(JSON.stringify(props.wizardata)))
    //         console.log("WIZARD DATA: ", JSON.parse(JSON.stringify(props.wizardata)))
    //         setWizardDataResetCopy(JSON.parse(JSON.stringify(props.wizardata)))
    //     }
    // }, [props.wizardata])

    useEffect(() => {
        if (props.clientmasterdata !== undefined) {
            console.log("CLIENT MASTER ID: ", props.clientmasterdata)
            setClientMasterData(props.clientmasterdata)
        }
    }, [props.clientmasterdata])

    useEffect(() => {
        console.log("CLIENT MASTER DATA: ", clientMasterData)
        if (clientMasterData !== undefined) {
            const fetchClientDetails = async () => {
                const postOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    body: JSON.stringify({
                        "ClientMasterId": clientMasterData.ClientMasterId,
                    }),
                };
                fetch(`${process.env.REACT_APP_BASE_URI}/workflow/form/response/`, postOptions)
                    .then((response) => {
                        if (!response.ok) {
                            if (response.status === 401) {
                                // Handle unauthorized error
                                logout();
                                alert("Session ended , Please login back");
                            } else {
                                throw new Error("Request failed.");
                            }
                        }
                        return response.json();
                    })
                    .then((data) => {
                        setClientData(data.data)
                    }
                    );
            }
            fetchClientDetails()
        }
    }, [clientMasterData])


    // useEffect(() => {
    //     if (props.selectedfields !== undefined) {
    //         // console.log("SELECTED FIELDS PASSED TO WIZARD: ", props.selectedfields)
    //         setSelectedFields(props.selectedfields)
    //     }
    // }, [props.selectedfields])


    // useEffect(() => {
    //     setMessage(props.message)
    // }, [props.message])


    // useEffect(() => {
    //     setEngagementLetterTemplateId(props.templateid)
    // }, [props.templateid])
    useEffect(() => {
        const fetchEngagementTemplateData = async () => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            };

            fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/templates/`, requestOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    const options = []
                    if (data.data !== undefined) {
                        console.log("ENGAGEMENT LETTER TEMPLATE DETAILS: ", data.data)
                        for (let template of data.data) {
                            options.push({
                                "label": removeFileExtension(template["TemplateOriginalFileName"] !== null ? template["TemplateOriginalFileName"] : ""),
                                "value": template.TemplateId
                            })
                        }
                        setEngagementLetterTemplateOptions(options)
                        props.engagementlettertemplates(options)
                    }
                })
                .finally(() => { });
        }
        fetchEngagementTemplateData()
    }, [])

    useEffect(() => {
        if (engagementLetterTemplate.value !== "" && engagementLetterTemplate.value !== undefined) {
            const fetchWizardFormDetails = async (templateId) => {
                return new Promise((resolve, reject) => {
                    let token = localStorage.getItem("token");
                    const getRequestOptions = {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    };

                    if (templateId !== undefined) {
                        fetch(
                            `${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/wizard_page_templates/?TemplateId=${templateId}`,
                            getRequestOptions
                        )
                            .then((response) => {
                                if (!response.ok) {
                                    if (response.status === 401) {
                                        logout();
                                        alert("Session ended , Please login back");
                                    } else {
                                        reject(new Error("Request failed."));
                                    }
                                }
                                return response.json();
                            })
                            .then((result) => {
                                resolve(result["data"]);
                            })
                            .catch((error) => reject(error));
                    } else {
                        reject(new Error("Template ID is undefined"));
                    }
                });
            };

            const addNewWizard = async () => {
                const templateId = engagementLetterTemplate.value;
                console.log("templateId", templateId)
                setMessage("Loading your wizard")
                const wizardFormDetails = await fetchWizardFormDetails(templateId);
                console.log("WIZARD FORM DETAILS: ", wizardFormDetails)
                const formatted_pages = []
                if (wizardFormDetails.length !== 0) {
                    for (let [index, page] of wizardFormDetails.entries()) {
                        formatted_pages.push({
                            data: JSON.parse(page["PreviewStructure"]),
                            flag: false,
                            id: index,
                            pageName: page["TemplateName"]
                        })
                    }
                } else {
                    setMessage("This feature has not been configured for the selected template. Please complete the configuration to use this feature.")
                }
                console.log("FORMATTED PAGES: ", formatted_pages)
                setWizardData(formatted_pages)
                setWizardDataResetCopy(JSON.parse(JSON.stringify(formatted_pages)))
            }
            addNewWizard()
        }
    }, [engagementLetterTemplate])

    /**
    * Update fields and preview components everytime initialData changes
    */
    useEffect(() => {
        if (wizardData !== undefined && wizardData !== null && wizardData.length > 0 && clientAllData !== undefined) {
            if (wizardData[activePage]["data"]) {
                console.log("CLIENTS DATA: ", clientAllData)
                for (let component of wizardData[activePage]["data"]) {
                    for (let [key, value] of Object.entries(clientAllData[0])) {
                        console.log("Preview Component Label: ", component.label)
                        console.log("Preview Component Value: ", component.value)
                        console.log("Client Data Key: ", key)
                        console.log("Client Data Value: ", value)
                        if (component.label === key) {
                            component.value = value
                        }
                    }
                }
                setPreviewComponents(wizardData[activePage]["data"])
            }
        }
    }, [activePage, wizardData, clientAllData])

    const [saveWizardApiFlag, setSaveWizardApiFlag] = useState()

    /**
     * Update the values of fields in the PREVIEW form
     * @param {*} field_id: ID of field to be updated 
     * @param {*} event: The trigger event of input in preview form
     */
    const saveWizardData = () => {
        let payload_format = { "TemplateId": engagementLetterTemplate.value }
        for (let page of wizardData) {
            for (let field of page.data) {
                if (field.type !== "header") {
                    payload_format[[field.label]] = field.value
                }
            }
        }
        // console.log("PAYLOAD FORMAT: ", payload_format)
        setSaveWizardApiFlag(true)
        props.setapiflag(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(payload_format)
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/mail_merge/`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success === true) {
                    toast.success(
                        "Response saved successfully!",
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            className: "toastify-color-success",
                        }
                    );
                    props.refresh()
                    props.onHide()
                    props.setapiflag(false)
                    setSaveWizardApiFlag(false)
                    handleClose()
                } else {
                    toast.error(
                        "Failed to save response: " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                    props.onHide()
                    props.setapiflag(false)
                    setSaveWizardApiFlag(false)
                    handleClose()
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const handleClose = () => {
        console.log("WIZARD RESET", wizardDataResetCopy)
        setWizardData([]);
        setEngagementLetterTemplate({ "lable": "", "value": "" })
        setClientMasterData(undefined)
        setMessage("")
        setEngagementLetterFor(undefined)
        setActivePage(0)
        props.setapiflag(false);
        props.onHide();
    }

    // Toggle flag for demonstration (update colors)
    const toggleFlag = (index) => {
        setWizardData((prevItems) =>
            prevItems.map((item, i) =>
                i === index ? { ...item, flag: true } : item
            )
        );
    };


    /**
     * Update the values of fields in the PREVIEW form
     * @param {*} field_id: ID of field to be updated 
     * @param {*} event: The trigger event of input in preview form
     */
    const handleChange = (field_id, event) => {
        const newList = [...previewComponents]
        newList.forEach(field => {
            const { type, id } = field;
            if (field_id === id) {
                switch (type) {
                    case 'checkbox':
                        field['value'] = event.target.checked;
                        break;

                    case 'radio':
                        for (let fld of newList) {
                            if (fld["radiogroup"] === field["radiogroup"]) {
                                fld["value"] = false
                            }
                        }
                        field['value'] = event.target.checked;
                        break;

                    case 'select':
                        field['value'] = event.value;
                        break;

                    case 'file':
                        field['value'] = event[0];
                        break;

                    case 'date':
                        field['value'] = event;
                        break;

                    default:
                        field['value'] = event.target.value;
                        break;
                }
            }
            const newListTwo = [...newList]
            setPreviewComponents(newListTwo)
        })
    }

    const PageValidationChecker = (data) => {
        const error_list = []
        for (let record of data) {
            if (record.mandatory && record.value === "") {
                error_list.push(record.label)
            }
        }

        if (error_list.length === 0) {
            toggleFlag(activePage)
            setActivePage(activePage + 1)
        }
        return error_list
    }

    const validateAndNext = () => {
        console.log("PREVIEW")
        console.log("ACTIVE PAGE: ", activePage + 1)
        const errs = PageValidationChecker(previewComponents)
        setErrors(errs)
    }

    return (
        <Modal
            {...props}
            onHide={() => handleClose()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className={"add-wizard-modal"}
        >
            <Modal.Header closeButton style={{ padding: 0, margin: 0 }}>
            </Modal.Header>
            <Modal.Body style={{ padding: 0, margin: 0 }}>
                {
                    clientMasterData === undefined && engagementLetterTemplate.value.length === 0 &&
                    <Row className="no-gutter flex-row-center-center" style={{ padding: "20px 20px 20px 20px" }}>
                        <h5>
                            Who would you like to create an engagement letter for?
                        </h5>
                        <div className="flex-row-start-start">
                            <br />
                            <div class="form-check-inline">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="separate_el"
                                    value="separate_el"
                                    onClick={() => setEngagementLetterFor('separate_el')}
                                />
                                <label class="form-check-label" for="separate_el">New Client</label>
                            </div>
                            <div class="form-check-inline">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="existing_client_el"
                                    value="existing_client_el"
                                    onClick={() => setEngagementLetterFor('existing_client_el')}
                                />
                                <label class="form-check-label" for="existing_client_el">Existing Client</label>
                            </div>
                        </div>
                        {
                            engagementLetterFor === "existing_client_el" &&
                            <Col md={12}>
                                <ExistingClientSelector
                                    setmasterdata={(data) => setClientMasterData(data)}
                                />
                            </Col>
                        }
                    </Row>
                }

                {
                    clientMasterData !== undefined && engagementLetterTemplate.value.length === 0 &&
                    <div style={{marginLeft: 20, marginRight: 10}} className="flex-row-center-start">
                        <div style={{marginRight: 10, marginTop: 5}} ><h6>Selected Client: </h6></div>
                        <div className='selected-client'>{clientMasterData["Client Name"]}</div>
                    </div>
                }

                {engagementLetterTemplate.value.length === 0 &&
                    <Row style={{ padding: 20 }}>
                        <label className="form-label move-left">Select engagement letter template</label>
                        <Select
                            options={engagementLetterTemplateOptions}
                            value={engagementLetterTemplate}
                            onChange={(event) => {
                                setEngagementLetterTemplate(event);
                            }}
                            placeholder={"Select template"}
                            aria-label="Default"
                            autosize={false}
                            className='custom-select '
                            classNamePrefix="react-select"
                        />
                    </Row>
                }

                {
                    wizardData.length === 0 ?
                        <div className="wizard-loader-container" style={engagementLetterTemplate.value.length !== 0?{height: 300}:{}}>
                            {
                                message.includes("Loading") || message.includes("Fetching") ?
                                    <div className="fit-width" style={{ marginRight: 20 }}>
                                        <ThreeCircles
                                            height="40"
                                            width="40"
                                            color="#4fa94d"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                            ariaLabel="three-circles-rotating"
                                            outerCircleColor="var(--theme1_tertiary)"
                                            innerCircleColor="var(--theme1_secondary)"
                                            middleCircleColor="var(--theme1_primary)"
                                        />
                                    </div>
                                    :
                                    message.length !== 0 ?
                                        <Icon.ExclamationOctagonFill color="orange" style={{ fontSize: 40, marginRight: 20, marginTop: -10 }} />
                                        : <></>
                            }
                            <div className="fit-width">
                                {message.length !== 0 &&
                                    <h6 style={{ lineHeight: 1.5, marginBottom: 20 }}>{message}</h6>
                                }
                            </div>
                        </div>
                        :
                        <>
                            <Row className="no-gutter wizard-page-connector">
                                {wizardData.map((item, index) => (
                                    <div className="circle-wrapper" key={item.id}>
                                        {/* Circle with index */}
                                        <div
                                            className={`circle ${item.flag ? 'active' : ''} ${index === activePage ? 'current' : ''}`}
                                            // style={{ width: `calc(750px/${wizardData.length * 3})`, height: `calc(750px/${wizardData.length * 3})` }}
                                            style={{ width: 50, height: 50 }}
                                        >
                                            {item.flag ? (index === activePage ? index + 1 : <Icon.CheckAll style={{ fontSize: 30 }} />) : index + 1}
                                        </div>
                                        {/* Line connecting circles */}
                                        {index < wizardData.length - 1 && <div className={`line  ${item.flag ? 'active' : ''}`} />}
                                    </div>
                                ))}
                            </Row>
                            <Row className="no-gutter">
                                <div className="current-page-container">
                                    <h6 className="current-page-name">{wizardData[activePage].pageName}</h6>
                                </div>
                            </Row>
                            <Row className="no-gutter wizard-page-form-container">
                                <FormContext.Provider value={{ handleChange }}>
                                    <Form className='no-padding'>
                                        <Row>
                                            {
                                                previewComponents &&
                                                previewComponents.map((field, i) =>
                                                    <Element
                                                        key={i}
                                                        field={field}
                                                    />
                                                )
                                            }
                                        </Row>
                                    </Form>
                                </FormContext.Provider>
                            </Row>
                            <Row className="no-gutter wizard-page-buttons-container">
                                {errors.length !== 0 ?
                                    <div className="wizard-form-validation-error-container">
                                        {/* The {errors.map((item, index)=> index<errors.length-1 ? <b>{item}, </b>: <b>{item}</b>)} are required fields, please make sure all required fields are filled before moving on to the next step. */}
                                        * are required fields
                                    </div> :
                                    <></>
                                }
                                <div className="fit-width">
                                    {
                                        activePage !== 0 ?
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                onClick={() => {
                                                    console.log("ACTIVE PAGE: ", activePage - 1)
                                                    setActivePage(activePage - 1)
                                                }}
                                                className="custom-button"
                                            >
                                                Back
                                            </Button> : <></>
                                    }
                                </div>
                                <div className="fit-width">
                                    {
                                        activePage !== wizardData.length - 1 ?
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                onClick={() => validateAndNext()}
                                                className="custom-button"
                                            >
                                                Next
                                            </Button> :
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                onClick={() => saveWizardData()}
                                                className="custom-button"
                                            >
                                                {
                                                    saveWizardApiFlag ?
                                                        <div style={{ marginRight: 6 }}>
                                                            <ThreeCircles
                                                                height="15"
                                                                width="15"
                                                                color="#4fa94d"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                                visible={true}
                                                                ariaLabel="three-circles-rotating"
                                                                outerCircleColor="var(--theme1_tertiary)"
                                                                innerCircleColor="var(--theme1_secondary)"
                                                                middleCircleColor="var(--theme1_primary)"
                                                            />
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                <p style={{ marginBottom: 0, marginTop: 0 }}>Submit</p>
                                            </Button>
                                    }
                                </div>
                            </Row>
                        </>
                }
            </Modal.Body>
        </Modal>
    )
}

export default AddNewWizardModal;