import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { ThreeCircles } from "react-loader-spinner";
import './Button.css'

const CustomButton = (props) => {
    // console.log("CUSTOM BUTTON PROPS: ", props)
    const [progress, setProgress] = useState(props.progress)
    const [content, setContent] = useState(props.content)
    useEffect(() => {
        setProgress(props.progress)
    }, [props.progress])

    useEffect(() => {
        setContent(props.content)
    }, [props.content])


    return (
        <div style={{width:"fit-content"}}>
            <Button
                type="submit"
                onClick={(e) => props.onclick(e)}
                className={!progress ? "custom-button" : "custom-button-api-progress"}
                >
                {
                    !progress ?
                        content
                        :
                        <>
                            {content} &nbsp;
                            <ThreeCircles
                                height="20"
                                width="20"
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="three-circles-rotating"
                                outerCircleColor="var(--theme1_primary)"
                                innerCircleColor="var(--theme1_secondary)"
                                middleCircleColor="var(--theme1_tertiary)"
                            />
                        </>
                }
            </Button>
        </div>
    );
};

export default CustomButton;
