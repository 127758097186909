import React, { useContext } from 'react'
import { Col, Image } from "react-bootstrap";
import { FormContext } from '../FormContext';
import "./CustomElements.css"

const Checkbox = ({ id, source, label, value, disabled, pivot, pivotreverse, mandatory, hidden, approval, show, md, margins }) => {
    const check_value = value
    const check_pivot = pivot
    const check_pivot_reverse = pivotreverse
    const letShow = show === undefined ? true : show
    const { handleChange } = useContext(FormContext)
    const marginTop = margins["top"] !== undefined && margins["top"] !== null ? margins["top"] : 5
    const marginRight = margins["right"] !== undefined && margins["right"] !== null ? margins["right"] : 5
    const marginBottom = margins["bottom"] !== undefined && margins["bottom"] !== null ? margins["bottom"] : 5
    const marginLeft = margins["left"] !== undefined && margins["left"] !== null ? margins["left"] : 5
    // console.log("MD: ", md)
    const onChange = (event) => {
        event.stopPropagation()
        // console.log("CHECKBOX event: ", event)
        handleChange(id, event)
    }
    return (
        <>
            {
                letShow ? (
                    check_pivot_reverse
                        ?
                        <Col
                            md={md !== undefined ? md : 12}
                            className={check_pivot ? "pivotreverse-check-holder" : "custom-select-holder"}
                            style={
                                hidden ?
                                    { display: "None", visibility: "hidden" } :
                                    { margin: `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px` }}
                        >
                            <label
                                className="custom-checkbox-label move-left"
                                htmlFor="exampleCheck1"
                            >
                                {
                                    source ? <Image
                                        src={source}
                                        style={{ width: 15, height: 15, borderRadius: 5, marginRight: 5 }}
                                    /> : ""
                                }
                                {label}
                                {
                                    mandatory ? <span style={{ color: "red", marginLeft: 5 }}>*</span> : ""
                                }
                            </label>
                            <input
                                type="checkbox"
                                className={
                                    check_pivot ?
                                        "form-check-input move-left custom-check-input pivot-check" :
                                        "form-check-input move-left custom-check-input"
                                }
                                id="exampleCheck1"
                                checked={check_value}
                                onChange={event => onChange(event)}
                                disabled={disabled}
                                required={mandatory}
                                autoComplete="new-password"
                            />
                        </Col>
                        :
                        <Col
                            md={md !== undefined ? md : 12}
                            className={
                                check_pivot ? "pivot-check-holder" : "custom-select-holder"
                            }
                            style={
                                hidden ?
                                    { display: "None", visibility: "hidden" } :
                                    { margin: `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px` }
                            }
                        >
                            <label
                                className="custom-checkbox-label move-left"
                                htmlFor="exampleCheck1"
                            >
                                {
                                    source ? <Image
                                        src={source}
                                        style={{ width: 15, height: 15, borderRadius: 5, marginRight: 5 }}
                                    /> : ""
                                }
                                {label}
                                {
                                    mandatory ?
                                        <span style={{ color: "red", marginLeft: 5 }}>*</span> : ""} &nbsp;{approval ? "(for approval)"
                                            : ""
                                }
                            </label>
                            <input
                                type="checkbox"
                                className={
                                    check_pivot ?
                                        "form-check-input move-left custom-check-input pivot-check" :
                                        "form-check-input move-left custom-check-input"
                                }
                                id="exampleCheck1"
                                checked={check_value}
                                onChange={event => onChange(event)}
                                disabled={disabled}
                                required={mandatory}
                                autoComplete="new-password"
                            />
                        </Col>
                )
                    :
                    <></>
            }
        </>
    )
}

export default Checkbox