import React, {
    useState,
    useEffect
} from "react";
import {
    Row,
    Modal,
    Form
} from "react-bootstrap";
import { getFromLS } from "../../../utils/storage";
import { FormContext } from "../../reusable_components/input_fields/FormContext";
import Element from '../../reusable_components/input_fields/Input';
import { v4 } from "uuid";
import './UploadSpreadsheetModal.css';
import { toast } from "react-toastify";
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';

const UploadSpreadsheetModal = (props) => {
    const token = getFromLS("token")
    const [elements, setElements] = useState([{
        "id": v4(),
        "label": "Upload Spreadsheet File",
        "mandatory": false,
        "approval": false,
        "disabled": false,
        "placeholder": "Upload Spreadsheet File",
        "type": "file",
        "example": null,
        "pivot": true,
        "pivotreverse": false,
        "value": "",
        "width": 12
    }]);

    const handleChange = (field_id, event) => {
        const newElements = [...elements]
        newElements.forEach(field => {
            const { type, id } = field;
            if (field_id === id) {
                switch (type) {
                    case 'file':
                        field['value'] = event[0];
                        break;

                    default:
                        field['value'] = event.target.value;
                        break;
                }
            }
            const newFullSchema = [...newElements]
            setElements(newFullSchema)
        });
    }

    /**
     * Update the values of fields in the PREVIEW form
     * @param {*} field_id: ID of field to be updated 
     * @param {*} event: The trigger event of input in preview form
     */
    const populateTemplate = (file) => {
        props.setfileuploading(true);

        const templateId = engagementLetterTemplate.id;

        console.log(templateId);

        const formData = new FormData();
        formData.append('spreadsheet_file', file);
        formData.append('template_id', templateId);

        const requestOptions = {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/spreadsheet_file_details/`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success === true) {
                    toast.success(
                        "File Initialized successfully!",
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            className: "toastify-color-success",
                        }
                    );
                    props.refresh()
                    props.onHide()
                    props.setfileuploading(false)
                } else {
                    toast.error(
                        "File Initialization Failed, " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                    props.onHide()
                    props.setfileuploading(false)
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        if (elements[0]["value"] !== "") {
            populateTemplate(elements[0]["value"])
        }
    }, [elements])

    const [engagementLetterTemplateOptions, setEngagementLetterTemplateOptions] = useState()
    const [engagementLetterTemplate, setEngagementLetterTemplate] = useState({'label':"", "value":""})
    useEffect(() => {
        // console.log("EL OPTIONS: ", props.engagementlettertemplates)
      setEngagementLetterTemplateOptions(props.engagementlettertemplates)
    }, [props.engagementlettertemplates])
    

    const handleClose = () => {
        props.setfileuploading(false);
        props.onHide();
    }

    return (
        <Modal
            {...props}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            className={"upload-spreadsheet-modal"}
        >
            <Modal.Header closeButton style={{ padding: 0, margin: 0 }}>
            </Modal.Header>
            <Modal.Body style={{ padding: 0, margin: 0 }}>
                <Row style={{ padding: 20 }}>
                    <label className="form-label move-left">Select engagement letter template</label>
                    <Select
                        options={engagementLetterTemplateOptions}
                        value={engagementLetterTemplate}
                        onChange={(event) => {
                            setEngagementLetterTemplate(event);
                        }}
                        placeholder={"Select template"}
                        aria-label="Default"
                        autosize={false}
                        className='custom-select '
                        classNamePrefix="react-select"
                    />
                </Row>
                <FormContext.Provider value={{ handleChange }}>
                    <Form>
                        <Row className="no-gutter">
                            {
                                elements &&
                                elements.map((field, i) =>
                                    <Element
                                        key={i}
                                        field={field}
                                        md={12}
                                    />)
                            }
                        </Row>
                    </Form>
                </FormContext.Provider>
            </Modal.Body>
        </Modal>
    )
}

export default UploadSpreadsheetModal;