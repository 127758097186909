import React, { useContext } from 'react'
import { FormContext } from '../FormContext';
import { Col, Image } from "react-bootstrap";
import "./CustomElements.css"

const Numberinput = ({ id, source, label, placeholder, value, example, disabled, mandatory, hidden, md, margins }) => {
    const { handleChange } = useContext(FormContext)
    const marginTop = margins["top"] !== undefined && margins["top"] !== null ? margins["top"] : 5
    const marginRight = margins["right"] !== undefined && margins["right"] !== null ? margins["right"] : 5
    const marginBottom = margins["bottom"] !== undefined && margins["bottom"] !== null ? margins["bottom"] : 5
    const marginLeft = margins["left"] !== undefined && margins["left"] !== null ? margins["left"] : 5
    return (
        <Col md={md} className="pad-trl-10" style={hidden ? { display: "None", visibility: "hidden" } : { margin: `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px` }}>
            {label !== null ? <label htmlFor="exampleInputEmail1" className="form-label move-left">{source ? <Image src={source} style={{ width: 15, height: 15, borderRadius: 5, marginRight: 5 }} /> : ""}{label}{mandatory ? <span style={{ color: "red", marginLeft: 5 }}>*</span> : ""}</label> : <></>}
            {/* <InputNumber defaultValue={10} 
            // className="form-control move-left custom-input" 
            // onChange={event => handleChange(id, event)}
             /> */}
            <input type="number" className="form-control move-left custom-input" id="exampleInputEmail1" aria-describedby="emailHelp"
                placeholder={placeholder ? placeholder : 'Enter a number'}
                value={value}
                onChange={event => handleChange(id, event)}
                disabled={disabled}
                required={mandatory}
                autoComplete="new-password"
            />
        </Col>
    )
}

export default Numberinput