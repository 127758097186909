import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import InputBox from './input_components/InputBox';
import { FormContext } from '../reusable_components/input_fields/FormContext'
import Element from '../reusable_components/input_fields/Input';
import Select from "react-select";
import { v4 } from "uuid";
import * as Icon from "react-bootstrap-icons";
import styled from 'styled-components';
import { logout } from "../../utils";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { getFromLS } from '../../utils/storage';
import { useNavigate } from 'react-router-dom'
import { ApprovalSettingsModal } from '../approval_config/ApprovalSettingsModal';
import CustomButton from '../reusable_components/custom_button_component/Button';

const ColumnContainer = styled.div`
  margin: 8px;
  border: 0px solid #bbb;
  border-radius: 2px;
  width: 100%;
  background-color: #fbfbfc;
  display: flex;
  flex-direction: column;
`;

// const TEMPLATE_NAME_MAPPER = {
//     "Client Onboarding": client_onboarding,
//     "Task Creation": task_creation,
//     "Staff Creation": staff_creation,
//     "Custom": custom
// }

const applogo = {
    "Cygnus": require("../../pages/vendor_images/undefined.png"),
    "HubSpot": require("../../pages/vendor_images/HBSPT.png"),
    "SafeSend": require("../../pages/vendor_images/SFSND.png")
}

const CreateFormModal = (props) => {
    const [formName, setFormName] = useState(props.formname)
    const [formNameFlag, setFormNameFlag] = useState(false)
    useEffect(() => {
        if (!formNameFlag) {
            if (props.formname !== undefined) {
                setFormName(props.formname)
                setFormNameFlag(true)
            }
        }
    }, [props.formname])

    const [flag, setFlag] = useState(props.flag)
    useEffect(() => {
        setFlag(props.flag)
    }, [props.flag])

    const [purposeOptions, setPurposeOptions] = useState([
        { "label": "Client Onboarding", "value": "Client Onboarding" },
        { "label": "Task Creation", "value": "Task Creation" },
        { "label": "Staff Creation", "value": "Staff Creation" },
        { "label": "Engagement Letter", "value": "Engagement Letter" },
        { "label": "Custom", "value": "Custom" }
    ])
    const [selectedPurpose, setSelectedPurpose] = useState(props.formpurpose !== undefined ? props.formpurpose : purposeOptions[0])
    const [purposeFlag, setPurposeFlag] = useState(false)
    const [templateOptions, setTemplateOptions] = useState([{ "label": "", "value": "" }])
    const [selectedTemplate, setSelectedTemplate] = useState(props.formtemplate !== undefined ? props.formtemplate : templateOptions[0])
    const [templateFlag, setTemplateFlag] = useState(false)

    useEffect(() => {
        if (!purposeFlag) {
            if (props.formpurpose !== undefined) {
                setSelectedPurpose(props.formpurpose)
                setPurposeFlag(true)
            } else {
                setSelectedPurpose(purposeOptions[0])
                props.setformpurpose(purposeOptions[0])
            }
        }
    }, [props.formpurpose])

    useEffect(() => {
        if (!templateFlag) {
            if (props.formtemplate !== undefined) {
                setSelectedTemplate(props.formtemplate)
                setTemplateFlag(true)
            } else {
                setSelectedPurpose(templateOptions[0])
            }
        }
    }, [props.formtemplate])

    const [allTemplates, setAllTemplates] = useState({
        "Client Onboarding": [],
        "Staff Creation": [],
        "Task Creation": []
    })
    useEffect(() => {
        if (props.templates !== undefined) {
            setAllTemplates(props.templates)
        }
    }, [props.templates])

    useEffect(() => {
        props.setformname(formName)
    }, [formName])

    useEffect(() => {
        props.setformpurpose(selectedPurpose)
    }, [selectedPurpose])

    useEffect(() => {
        props.settemplate(selectedTemplate)
    }, [selectedTemplate])

    useEffect(() => {
        // console.log("ALL TEMPLATES: ", allTemplates)
        if (selectedPurpose["value"] !== "" && selectedPurpose["value"] !== "Custom" && allTemplates !== undefined && allTemplates !== null && allTemplates[selectedPurpose["value"]] !== undefined &&  allTemplates[selectedPurpose["value"]].length !== 0) {
            const options = []
            for (let template of allTemplates[selectedPurpose["value"]]) {
                options.push({ "label": template["FormName"], "value": template["FormName"] })
            }
            setTemplateOptions(options)
        }
    }, [selectedPurpose, allTemplates])


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            // backdropClassName="custom-backdrop"
            backdrop="static"
            centered
            className='form-modal'
            scrollable="false"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <Row className='no-gutter'>
                    <Col md={selectedPurpose["value"] === "Custom" ? 8 : 4}>
                        <div>
                            <label className="form-label move-left">Form name</label>
                            <input type="text" className="form-control move-left custom-input"
                                placeholder={'Enter a name for your form'}
                                value={formName}
                                onChange={(event) => setFormName(event.target.value)}
                            />
                        </div>
                    </Col>
                    <Col md={4} className="custom-select-holder">
                        <label className="form-label move-left">Form purpose</label>
                        <Select
                            options={purposeOptions}
                            value={selectedPurpose}
                            defaultValue={purposeOptions[0]}
                            onChange={(event) => setSelectedPurpose(event)}
                            placeholder={""}
                            aria-label="Default"
                            autosize={true}
                            className='custom-select '
                            classNamePrefix="react-select"
                        />
                    </Col>
                    {
                        selectedPurpose["value"] !== "Custom" ?
                            <Col md={4} className="custom-select-holder">
                                <label className="form-label move-left">Form template</label>
                                <Select
                                    options={templateOptions}
                                    value={selectedTemplate}
                                    defaultValue={templateOptions[0]}
                                    onChange={(event) => setSelectedTemplate(event)}
                                    placeholder={""}
                                    aria-label="Default"
                                    autosize={true}
                                    className='custom-select '
                                    classNamePrefix="react-select"
                                />
                            </Col>
                            : <></>
                    }
                </Row>
            </Modal.Body>
            <Modal.Footer className="action-footer">
                <Button className="custom-button" style={{ padding: "10px 20px" }} onClick={() => props.createform(selectedPurpose.value, selectedTemplate.value)} >
                    {flag ? "Update" : "Create form"}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ConfirmationModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            // backdropClassName="custom-backdrop"
            backdrop="static"
            centered
            className='form-modal'
            scrollable="false"
        >
            <Modal.Header closeButton>
                <h4><Icon.ExclamationOctagonFill color='orange' style={{ fontSize: 30, margin: 20 }} /> Have you finalized all the required changes?</h4>
            </Modal.Header>
            <Modal.Body style={{ padding: "0px 20px" }}>
                <Row className='no-gutter'>
                    <h6 style={{ lineHeight: 2 }}>Once the template is finalized and saved you will not be able to add or delete any fields in the template. Please make sure the structure is as required.</h6>
                </Row>
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "space-between", margin: "0px 10px 10px 10px" }}>
                <div>
                    <Button className="custom-button" style={{ padding: "10px 20px" }} onClick={() => {
                        props.savewizardform()
                        props.onHide()
                    }} >
                        Save
                    </Button>
                </div>
                <div>
                    <Button className="custom-button" style={{ padding: "10px 20px" }} onClick={() => props.onHide()} >
                        Cancel
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}


const FormLayout = (props) => {
    // console.log("PROPS: ", props)
    let drag_data_format = {
        fields: {
        },
        columns: {
            'column': {
                id: 'column',
                fieldIds: [],
            },
        }
    }
    const navigate = useNavigate()
    const [initialData, setInitialData] = useState(drag_data_format)
    const [fields, setFields] = useState([])
    const [previewComponents, setPreviewComponents] = useState([])
    const [createFormModalShow, setCreateFormModalShow] = useState(false)
    const [fullWindowModalShow, setFullWindowModalShow] = useState(false)
    const [formName, setFormName] = useState("")
    const [formId, setFormId] = useState()
    const [selectedTemplate, setSelectedTemplate] = useState()
    const [selectedPurpose, setSelectedPurpose] = useState()
    const [allTemplates, setAllTemplates] = useState({
        "Client Onboarding": [],
        "Staff Creation": [],
        "Task Creation": []
    })
    const [update, setUpdate] = useState(false)
    const [final, setFinal] = useState(false)
    const [buttonFlag, setButtonFlag] = useState(false)
    const [approvalSettingModal, setApprovalSettingModal] = useState(false)
    const [mappingApiData, setMappingApiData] = useState()
    const [clientTypeOptions, setClientTypeOptions] = useState([])
    const [selectedType, setSelectedType] = useState(clientTypeOptions[0])
    const [saveFormApiProgress, setSaveFormApiProgress] = useState(false)
    const [confirmationModalShow, setConfirmationModalShow] = useState(false)
    const [templateStatusController, setTemplateStatusController] = useState(false)
    const [selectedFieldOptions, setSelectedFieldOptions] = useState([
        {
            value: "",
            label: "",
            id: "",
        }
    ])
    useEffect(() => {
        const token = getFromLS("token")
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/templates/grouped/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.success === true) {
                    setAllTemplates(data["data"])
                } else {
                    toast.error(
                        "Failed to fetch templates: " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch((err) => console.log("Err: ", err));
    }, [])

    useEffect(() => {
        const token = getFromLS("token")
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/firm_template_fields/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.success === true) {
                    // console.log("SELECTED FIELD OPTIONS: ", JSON.parse(data["data"][0]["SelectedFields"]))
                    setSelectedFieldOptions(data["data"]["SelectedFields"])
                } else {
                    toast.error(
                        "Failed to fetch selected fields: " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch((err) => console.log("Err: ", err));
    }, [])


    const [fieldList, setFieldList] = useState([])
    useEffect(() => {
        if (props.fields.length > 0) {
            setFieldList(props.fields)
        } else {
            FetchMappingData()
        }
    }, [props.fields])
    useEffect(() => {
        if (props.clienttype !== undefined) {
            const optionList = []
            optionList.push({
                "label": props.clienttype,
                "value": props.clienttype,
            })
            console.log("OPTION LIT: ", optionList)
            setClientTypeOptions(optionList)
            setSelectedType(optionList[0])
        }
    }, [props.clienttype])


    /**
     * Setting the first field by default on first load
     */
    useEffect(() => {
        if (fieldList === undefined) {
            // THIS IS JUST A FAIL SAFE
            setCreateFormModalShow(true)
            const id = v4()
            drag_data_format.columns["column"].fieldIds.push(id)
            Object.assign(drag_data_format.fields, {
                [id]: {
                    "id": id, "schema": [
                        {
                            "id": id,
                            "label": "",
                            "mandatory": false,
                            "hidden": false,
                            "disabled": false,
                            "placeholder": "",
                            "type": "text",
                            "example": null,
                            "radiogroup": null,
                            "pivot": true,
                            "pivotreverse": false,
                            "category": "H1",
                            "value": "",
                            "options": [{ "id": id, "label": "", "value": "" }],
                            "width": 12,
                            "margins": { "top": 5, "right": 0, "bottom": 5, "left": 0 }
                        }
                    ]
                }
            })
            setInitialData(drag_data_format)
        } else {
            if (fieldList.length > 0) {
                // console.log("PROPS for LAYOUT:", props.createformoption)
                if (Object.keys(props.createformoption).length > 0) {
                    setCreateFormModalShow(false)
                    setFormName(props.createformoption.form_name)
                    setFinal(props.createformoption.status === "Draft" ? false : true)
                    setTemplateStatusController(props.createformoption.status)
                    setSelectedPurpose({ "label": props.createformoption.form_purpose, "value": props.createformoption.form_purpose })
                    setSelectedTemplate({ "label": props.createformoption.form_template, "value": props.createformoption.form_template })
                    setInitialData(props.createformoption.initial_data)
                    if (props.createformoption.id !== undefined) {
                        setUpdate(true)
                        setFormId(props.createformoption.id)
                    }
                    setButtonFlag(true)
                } else {
                    setButtonFlag(false)
                    // console.log("FIELDS: ", fieldList)
                    if (props.fields.length > 0) {
                        for (let field of fieldList) {
                            const id = v4()
                            const options = []
                            if (field.ClientAllFieldsId.toString() === '25') {
                                const optionsJson = JSON.parse(field.Options)
                                for (let state of Object.keys(optionsJson)) {
                                    for (let city of optionsJson[state]) {
                                        options.push(
                                            { "id": city, "label": city, "value": city }
                                        )
                                    }
                                }
                            } else if (field.ClientAllFieldsId.toString() === '26') {
                                const optionsJson = JSON.parse(field.Options)
                                for (let state of Object.keys(optionsJson)) {
                                    options.push(
                                        { "id": state, "label": state, "value": state }
                                    )
                                }
                            } else if (field.ClientAllFieldsId.toString() === '29') {
                                const optionsJson = JSON.parse(field.Options)
                                for (let code of optionsJson) {
                                    options.push(
                                        { "id": code, "label": code, "value": code }
                                    )
                                }
                            }

                            drag_data_format.columns["column"].fieldIds.push(id)
                            Object.assign(drag_data_format.fields, {
                                [id]: {
                                    "id": id, "schema": [
                                        {
                                            "id": id,
                                            "allfields_id": field.ClientAllFieldsId,
                                            "allfields_code": field.FieldCode,
                                            "allfields_category": field.Category,
                                            "source": applogo[field["Source of truth"]],
                                            "label": field.FieldName,
                                            "mandatory": false,
                                            "hidden": false,
                                            "disabled": false,
                                            "placeholder": "",
                                            "type": field.FieldType,
                                            "example": null,
                                            "radiogroup": null,
                                            "pivot": true,
                                            "pivotreverse": false,
                                            "category": "H1",
                                            "value": "",
                                            "options": options,
                                            "width": 12,
                                            "margins": { "top": 5, "right": 0, "bottom": 5, "left": 0 }
                                        }
                                    ]
                                }
                            })
                        }
                        setInitialData(drag_data_format)
                    } else {
                        // THIS IS JUST A FAIL SAFE
                        const id = v4()
                        drag_data_format.columns["column"].fieldIds.push(id)
                        Object.assign(drag_data_format.fields, {
                            [id]: {
                                "id": id, "schema": [
                                    {
                                        "id": id,
                                        "allfields_id": v4(),
                                        "allfields_code": "custom",
                                        "allfields_category": "custom",
                                        "label": "Untitled",
                                        "mandatory": false,
                                        "hidden": false,
                                        "disabled": false,
                                        "placeholder": "",
                                        "type": "text",
                                        "example": null,
                                        "radiogroup": null,
                                        "pivot": true,
                                        "pivotreverse": false,
                                        "category": "H1",
                                        "value": "",
                                        "options": [{ "id": id, "label": "Untitled", "value": "" }],
                                        "width": 12,
                                        "margins": { "top": 5, "right": 0, "bottom": 5, "left": 0 }
                                    }
                                ]
                            }
                        })
                        setInitialData(drag_data_format)
                    }
                }
            }
        }
    }, [fieldList])

    // useEffect(() => {
    //     console.log("INITIAL DATA: ", initialData)
    // }, [initialData])

    /**
     * Update fields and preview components everytime initialData changes
     */
    useEffect(() => {
        // console.log("INITIAL DATA: ", initialData.columns["column"].fieldIds.map(fieldId => initialData.fields[fieldId]))
        setFields(initialData.columns["column"].fieldIds.map(fieldId => initialData.fields[fieldId]))
        const preview = []
        if (Object.keys(initialData.fields).length > 0) {
            for (let fieldId of initialData.columns["column"].fieldIds) {
                const schema = initialData.fields[fieldId]["schema"]
                preview.push(...schema)
            }
        }
        setPreviewComponents(preview)
    }, [initialData])

    /**
     * Create a new component and add it to the initial data
     */
    const addInputBox = () => {
        const id = v4()
        const newInitialData = Object.assign({}, initialData)
        newInitialData.columns["column"].fieldIds.push(id)
        Object.assign(newInitialData.fields, {
            [id]: {
                "id": id, "schema": [
                    {
                        "id": id,
                        "allfields_id": v4(),
                        "allfields_code": "custom_field",
                        "allfields_category": "custom",
                        "label": "Untitled",
                        "mandatory": false,
                        "hidden": false,
                        "approval": false,
                        "disabled": false,
                        "placeholder": "",
                        "type": "text",
                        "example": null,
                        "radiogroup": null,
                        "pivot": true,
                        "pivotreverse": false,
                        "category": "H1",
                        "value": "",
                        "options": [{ "id": id, "label": "Untitled", "value": "" }],
                        "width": 12,
                        "margins": { "top": 5, "right": 0, "bottom": 5, "left": 0 }
                    }
                ]
            }
        })
        setInitialData(newInitialData)
    }

    const saveForm = () => {
        setSaveFormApiProgress(true)
        if (update) {
            let payloadFormat;
            payloadFormat = {
                "FirmFormId": formId,
                "FormName": formName,
                "FormDescription": "",
                "FormPurpose": selectedPurpose.value,
                "InputStructure": initialData,
                "PreviewStructure": previewComponents,
                "ClientType": selectedType.value,
                "Status": final ? "Final" : "Draft"
            }
            // console.log("PAYLOAD FORMAT: ", payloadFormat)
            const token = getFromLS("token")
            const postOptions = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payloadFormat),
            };

            fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/`, postOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.success === true) {
                        setSaveFormApiProgress(false)
                        toast.success(
                            formName + " successfully updated!",
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-success",
                            }
                        );
                    } else {
                        setSaveFormApiProgress(false)
                        toast.error(
                            "Saving failed, " + data.message,
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-error",
                            }
                        );
                    }
                })
                .catch((err) => console.log("Err: ", err));
        } else {
            let payloadFormat;
            payloadFormat = {
                "FormName": formName,
                "FormDescription": "",
                "FormPurpose": selectedPurpose.value,
                "InputStructure": initialData,
                "PreviewStructure": previewComponents,
                "ClientType": selectedType.value,
                "Status": final ? "Final" : "Draft"
            }
            // console.log("PAYLOAD FORMAT: ", payloadFormat)
            const token = getFromLS("token")
            const postOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payloadFormat),
            };

            fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/`, postOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.success === true) {
                        setSaveFormApiProgress(false)
                        setFormId(data.data.FirmFormId)
                        toast.success(
                            formName + " successfully saved!",
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-success",
                            }
                        );
                    } else {
                        setSaveFormApiProgress(false)
                        toast.error(
                            "Saving failed, " + data.message,
                            {
                                position: "bottom-right",
                                autoClose: 2500,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                className: "toastify-color-error",
                            }
                        );
                    }
                })
                .catch((err) => console.log("Err: ", err));
        }
    }

    /**
     * Update the values of fields in the PREVIEW form
     * @param {*} field_id: ID of field to be updated 
     * @param {*} event: The trigger event of input in preview form
     */
    const handleChange = (field_id, event) => {
        const newList = [...previewComponents]
        newList.forEach(field => {
            const { type, id } = field;
            if (field_id === id) {
                console.log("FIELD: ", field)
                console.log("EVENT: ", event)
                switch (type) {
                    case 'checkbox':
                        field['value'] = event.target.checked;
                        break;
                    case 'radio':
                        for (let fld of newList) {
                            if (fld["radiogroup"] === field["radiogroup"]) {
                                fld["value"] = false
                            }
                        }
                        field['value'] = event.target.checked;
                        break;

                    case 'select':
                        field['value'] = event.value;
                        break;

                    case 'file':
                        field['value'] = event[0];
                        break;

                    case 'date':
                        field['value'] = event;
                        break;

                    default:
                        field['value'] = event.target.value;
                        break;
                }
            }
            const newListTwo = [...newList]
            setPreviewComponents(newListTwo)
        })
    }

    /**
     * Replace the schema with received component 
     * @param {*} field_id: ID of field to be updated
     * @param {*} components: Field component 
     */
    const updateFieldSchema = (field_id, components) => {
        const newInitialData = Object.assign({}, initialData)
        if (Object.keys(newInitialData.fields).length > 0) {
            newInitialData.fields[field_id]["schema"] = components
            setInitialData(newInitialData)
        }
    }

    /**
     * Remove the input box
     * @param {*} inputboxid: The id of input box to be deleted
     */
    const removeInputBox = (inputboxid) => {
        const newInitialData = Object.assign({}, initialData)
        delete newInitialData.fields[inputboxid]
        const index = newInitialData.columns["column"].fieldIds.findIndex((obj) => obj === inputboxid)
        if (index !== -1) {
            newInitialData.columns["column"].fieldIds.splice(index, 1)
        }
        setInitialData(newInitialData)
    }

    /**
     * Dragging logic to reorder the input boxes 
     * @param {*} result: The properties of draggable and droppable components in DragDropContext
     * @returns: Updates the initial data with updated order
     */
    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result;
        const initialDataCopy = JSON.parse(JSON.stringify(initialData))
        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const column = initialDataCopy.columns[source.droppableId];
        const newFieldIds = Array.from(column.fieldIds);
        newFieldIds.splice(source.index, 1);
        newFieldIds.splice(destination.index, 0, draggableId);

        const newColumn = {
            ...column,
            fieldIds: newFieldIds,
        };

        const newState = {
            ...initialDataCopy,
            columns: {
                ...initialDataCopy.columns,
                [newColumn.id]: newColumn,
            },
        };
        setInitialData(newState);
    }

    const createForm = (purpose, template) => {
        // console.log("PURPOSE: ", purpose)
        // console.log("TEMPLATE: ", template)
        const data = allTemplates[purpose].filter((t) => t["FormName"] === template)
        if (data.length > 0) {
            // console.log("DATA: ", JSON.parse(data[0]["InputStructure"]))
            setInitialData(JSON.parse(data[0]["InputStructure"]))
        }
        setCreateFormModalShow(false)
    }



    const FullWindowModal = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
                className="full-modal"
                scrollable="false"
            >
                <Modal.Header className="preview-modal-header" closeButton>
                </Modal.Header>
                <Modal.Body className="preview-modal">
                    <Row className='no-gutter'>
                        <Col md={1}></Col>
                        <Col md={10}>
                            <h4 style={{ margin: "10px 0px 20px 0px", textAlign: "center" }}>Preview</h4>
                            <FormContext.Provider value={{ handleChange }}>
                                <Form className='no-padding'>
                                    <Row className='no-gutter'>
                                        {
                                            previewComponents &&
                                            previewComponents.map((field, i) =>
                                                <Element
                                                    key={i}
                                                    field={field}
                                                />
                                            )
                                        }
                                    </Row>
                                </Form>
                            </FormContext.Provider>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="action-footer">
                </Modal.Footer>
            </Modal>
        )
    }

    const Switch = ({ isToggled, onClick }) => {
        return (
            <label className="switch">
                <input type="checkbox" checked={isToggled} onChange={onClick} style={{ appearance: "none" }} />
                <span className="super-admin-slider super-admin-round"></span>
            </label>
        );
    };

    useEffect(() => {
        console.log("MAPPING API DATA: ", mappingApiData)
        if (mappingApiData !== undefined) {
            const optionList = []
            for (let record of mappingApiData) {
                optionList.push({
                    "label": record["ClientType"],
                    "value": record["ClientType"],
                })
            }
            console.log("OPTION LIT: ", optionList)
            setClientTypeOptions(optionList)
            setSelectedType(optionList[0])
        }
    }, [mappingApiData])

    useEffect(() => {
        console.log("MAPPING API DATA: ", mappingApiData)
        if (mappingApiData !== undefined && selectedType !== undefined) {
            if (mappingApiData[0]["MappingId"] !== null) {
                let filteredMappingData = []
                if (selectedType !== undefined) {
                    filteredMappingData = mappingApiData.filter(
                        obj => Object.keys(obj).some(key => obj[key] === selectedType.value)
                    )
                } else {
                    filteredMappingData = mappingApiData.filter(
                        obj => Object.keys(obj).some(key => obj[key] === clientTypeOptions[0].value)
                    )
                }

                console.log("FILTERED MAPPING DATA: ", filteredMappingData)
                setFieldList(filteredMappingData[0]["MappingData"])
            } else {
                setFieldList(mappingApiData[0]["MappingData"])
            }
        }
    }, [selectedType, mappingApiData])

    const FetchMappingData = (form_id) => {
        const token = getFromLS("token")
        const getOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/data_mapping/${form_id !== undefined ? form_id : ''}`, getOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.success === true) {
                    // console.log("MAPPING DATA: ", data.data)
                    if (data.data.length > 0) {
                        setMappingApiData(data.data)
                    }
                } else {
                    toast.error(
                        "Error while fetching data: " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch((err) => console.log("Err: ", err));
    }

    return (
        <>
            <CreateFormModal
                show={createFormModalShow}
                onHide={() => setCreateFormModalShow(false)}
                formname={formName}
                formpurpose={selectedPurpose}
                setformname={(name) => setFormName(name)}
                setformpurpose={(purpose) => setSelectedPurpose(purpose)}
                formtemplate={selectedTemplate}
                templates={allTemplates}
                settemplate={(template) => setSelectedTemplate(template)}
                flag={buttonFlag}
                createform={(purpose, template) => createForm(purpose, template)}
            />
            <FullWindowModal
                show={fullWindowModalShow}
                onHide={() => setFullWindowModalShow(false)}
            />
            <Row className="no-gutter page-wrapper">
                <div className="navigator" style={{ position: "absolute", width: "fit-content", right: 25, float: "right", marginTop: -120 }}>
                    {
                        props.transporter !== undefined ?
                            <Button className="nav-direction" onClick={() => props.transporter('my_forms')}><Icon.CaretLeftFill />My forms </Button>
                            :
                            <></>
                    }
                </div>
                <Col md={6} className='flex-column-center-center'>
                    <div className='flex-row-space-center' style={{ width: "100%", padding: "10px 0px" }}>
                        <h3 style={{ width: "fit-content", marginBottom: 10 }}>{formName === "" ? "Untitled form" : formName}</h3>
                        <Icon.Pencil style={{ fontSize: 20, cursor: "pointer", width: "fit-content", margin: "0px 10px 0px 0px" }} onClick={() => setCreateFormModalShow(true)} />
                    </div>
                    <Row className='flex-row-center-center' style={{ width: "100%", padding: "10px 0px" }}>
                        <Col md={6}>
                            {
                                templateStatusController !== "Final" ?
                                    <Button className="custom-button"
                                        style={{
                                            display: "inline-block",
                                            padding: "10px 10px",
                                        }}
                                        onClick={() => addInputBox()} >
                                        <Icon.PlusCircleFill style={{ fontSize: 20, margin: "0px 5px 0px 0px" }} />
                                        New field
                                    </Button> :
                                    <></>
                            }
                        </Col>
                        <Col md={3}>
                            {/* <label className="form-label move-left">Type</label> */}
                            <Select
                                options={clientTypeOptions}
                                value={selectedType}
                                onChange={(event) => setSelectedType(event)}
                                placeholder={""}
                                aria-label="Default"
                                autosize={true}
                                className='custom-select '
                                classNamePrefix="react-select"
                            />
                        </Col>
                        <Col md={3} className='flex-row-center-end'>
                            <div className="toggler flex-column-center-center" style={{ marginRight: 10 }}>
                                <label style={{ fontWeight: 700, fontSize: 10 }}>{final ? "Final" : "Draft"}</label>
                                <Switch
                                    isToggled={final}
                                    onClick={event => templateStatusController !== "Final" ? setFinal(event.target.checked) : ""}
                                ></Switch>
                            </div>
                            <CustomButton
                                progress={saveFormApiProgress}
                                onclick={() => saveForm()}
                                content={"Save"}
                            />
                            {/* <Button className="custom-button"
                                style={{
                                    padding: "10px 10px",
                                    float: "right"
                                }}
                                onClick={() => saveForm()} >
                                Save
                            </Button> */}
                        </Col>
                    </Row>
                </Col>
                <Col md={6} className='flex-row-space-center'>
                    <h4></h4>
                    <h4 style={{ margin: "50px 0px 0px 0px", textAlign: "center" }}>Preview</h4>
                    <Button className="custom-button" style={{ padding: "10px 10px", margin: "50px 0px 0px 0px" }} onClick={() => setFullWindowModalShow(true)} >
                        <Icon.ArrowUpRightSquareFill style={{ fontSize: 20, width: "fit-content", margin: "0px 10px 0px 0px" }} />
                        Show in full window
                    </Button>
                </Col>
            </Row>
            <Row className="no-gutter">
                {
                    initialData !== undefined ?
                        <Col md={4} className="yscrollable">
                            <DragDropContext
                                onDragEnd={onDragEnd}
                            >
                                <ColumnContainer
                                    key={initialData.columns["column"].id}
                                    column={initialData.columns["column"]}
                                >
                                    <Droppable droppableId={"column"}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                isDraggingOver={snapshot.isDraggingOver}
                                            >
                                                {fields.map((field, index) =>
                                                    <Draggable key={field.id} draggableId={field.id} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                className='flex-row-center-start draggable-inputbox-wrapper'
                                                                style={{ margin: "10px 0px" }}
                                                                ref={provided.innerRef}
                                                                isDragging={snapshot.isDragging}
                                                                {...provided.draggableProps}
                                                            >
                                                                <InputBox
                                                                    inputboxid={field.id}
                                                                    existingfieldschema={field.schema}
                                                                    updatefieldschema={(components) => updateFieldSchema(field.id, components)}
                                                                    key={field.id}
                                                                    removeinputbox={(inputboxid) => removeInputBox(inputboxid)}
                                                                    provided={provided}
                                                                    templatestatus={templateStatusController}
                                                                    selectedfieldoptions={selectedFieldOptions}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </ColumnContainer>
                            </DragDropContext>
                        </Col>
                        :
                        <></>
                }
                <Col md={8} className="yscrollable">
                    <FormContext.Provider value={{ handleChange }}>
                        <Form className='no-padding'>
                            <Row className='no-gutter'>
                                {
                                    previewComponents &&
                                    previewComponents.map((field, i) =>
                                        <Element
                                            key={i}
                                            field={field}
                                        />
                                    )
                                }
                            </Row>
                        </Form>
                    </FormContext.Provider>
                </Col>
            </Row >
        </>
    )
}

export default FormLayout;