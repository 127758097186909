import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { Image } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
const Container = styled.div`
  border: 1px solid #f1f3f4;
  border-radius: 2px;
  padding: 8px;
  background-color: ${props => (props.isDragging ? '#e1e3e4' : '#f1f3f4')};
  width: fit-content;
  height: fit-content;
  margin: 10px 10px 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Field = (props) => {
    // console.log("FIELD: ", props)
    const removeFromTarget = () => {
        props.onremove(props.field)
    }

    const addToTarget = () => {
        props.onadd(props.field)
    }

    return (
        <Draggable draggableId={props.field.id} index={props.index}>
            {(provided, snapshot) => (
                <Container
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                >
                    <div {...provided.dragHandleProps} className="field-dragger">
                        <Image src={props.field.sourceimg} style={{ width: 20, height: 20, marginRight: 10, borderRadius: 5 }} />
                    </div>
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 500, hide: 100 }}
                        overlay={
                            <Tooltip id="button-tooltip">
                                This field belongs to {props.field.category} category
                            </Tooltip>
                        }
                        error={true}
                    >
                        <p style={{ padding: 0, margin: 0 }}>{props.field.field_name}</p>
                    </OverlayTrigger>
                    {
                        props.field.selected
                            ?
                            <Icon.DashCircleFill style={{ borderRadius: 100, color: "var(--theme1_primary)", background: "#fff", marginLeft: 20, fontSize: 15, cursor: "pointer" }}
                                onClick={() => removeFromTarget()}
                            />
                            :
                            <Icon.PlusCircleFill style={{ borderRadius: 100, color: "var(--theme1_primary)", background: "#fff", marginLeft: 20, fontSize: 15, cursor: "pointer" }}
                                onClick={() => addToTarget()}
                            />
                    }

                </Container>
            )}
        </Draggable>
    );
}
