import React, {
    useRef,
    useCallback,
    useMemo,
    useState,
    useEffect
} from "react";
import {
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { logout } from "../../utils";
import { toast } from "react-toastify";
import * as Icon from "react-bootstrap-icons";
import { Bars } from "react-loader-spinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { ApprovalSettingsModal } from "./ApprovalSettingsModal";
import CygnusFeatureJoyride from "../feature_joyride/FeatureJoyride";
// import { CygnusHelperModal } from "../reusable_components/helper_modal/CygnusHelperModal";


const ApprovalConfig = (props) => {
    const [dataSource, setDataSource] = useState();
    const [approvalSettingModal, setApprovalSettingModal] = useState(false)
    const [clientTypeId, setClientTypeId] = useState(null)
    const [helperModalShow, setHelperModalShow] = useState(false)


    // PAGE TOUR
    const [run, setRun] = useState(false);
    const steps = [
        {
            target: ".setting-gear-step",
            content: "Click on this gear icon to open a popup for configuring the settings for approval.",
            placement: "left",
            disableBeacon: true, // Prevents auto-start until element is present
        },
        {
            target: ".helper-step",
            content: "You can click on this help icon anytime you want more details on the features of this page.",
            placement: "bottom",
            disableBeacon: true, // Prevents auto-start until element is present
        },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            const element = document.querySelector(".setting-gear-step");
            if (element) {
                console.log("Target element found");
                setRun(true); // Start Joyride only after the element is available
                clearInterval(interval);
            } else {
                console.log("Waiting for target element...");
            }
        }, 300); // Check every 300ms

        return () => clearInterval(interval); // Cleanup on unmount
    }, []); // Add dependencies if necessary

    useEffect(() => {
        FetchApprovalConfiguration()
    }, [])

    const FetchApprovalConfiguration = () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/approval_config/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((dt) => {
                const data = dt.data
                if (data.length !== 0) {
                    const source_data = [{
                        "Category": "Individual clients created in Cygnus Master Client Data Manager (MDM)",
                        "Client Type": "Individual Client",
                        "Approval": data[0].ApprovalConfig ? data[0].ApprovalConfig : "No",
                        "ClientTypeId": data[0].ClientTypeId
                    },
                    {
                        "Category": "Entity clients created in in Cygnus Master Client Data Manager (MDM)",
                        "Client Type": "Entity Client",
                        "Approval": data[1].ApprovalConfig ? data[1].ApprovalConfig : "No",
                        "ClientTypeId": data[1].ClientTypeId
                    },
                    {
                        "Category": "Individual clients created in external CRM and imported to MDM",
                        "Client Type": "External Individual Client",
                        "Approval": data[2].ApprovalConfig ? data[2].ApprovalConfig : "No",
                        "ClientTypeId": data[2].ClientTypeId
                    },
                    {
                        "Category": "Entity clients created in external CRM and imported to MDM",
                        "Client Type": "External Entity Client",
                        "Approval": data[3].ApprovalConfig ? data[3].ApprovalConfig : "No",
                        "ClientTypeId": data[3].ClientTypeId
                    }
                    ]
                    setDataSource(source_data)
                }else{
                    const source_data = [{
                        "Category": "Individual clients created in Cygnus Master Client Data Manager (MDM)",
                        "Client Type": "Individual Client",
                        "Approval": "No",
                        "ClientTypeId": 1
                    },
                    {
                        "Category": "Entity clients created in in Cygnus Master Client Data Manager (MDM)",
                        "Client Type": "Entity Client",
                        "Approval":"No",
                        "ClientTypeId": 2
                    },
                    {
                        "Category": "Individual clients created in external CRM and imported to MDM",
                        "Client Type": "External Individual Client",
                        "Approval": "No",
                        "ClientTypeId": 3
                    },
                    {
                        "Category": "Entity clients created in external CRM and imported to MDM",
                        "Client Type": "External Entity Client",
                        "Approval": "No",
                        "ClientTypeId": 4
                    }
                    ]
                    setDataSource(source_data)
                }
            });
    }
    const approvalConfigGridRef = useRef();
    const containerStyle = useMemo(() => ({ width: "100%", height: "auto" }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const defaultColDef = useMemo(
        () => ({
            sortable: true,
            filter: true,
            resizable: true,
            animateRows: true,
        }),
        []
    );

    const onFirstDataRendered = useCallback((params) => {
        approvalConfigGridRef.current.api.sizeColumnsToFit();
    }, []);

    const [columnDefs, setColumnDefs] = useState([]);

    const approvalSettingRenderer = (props) => {
        return (
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="button-tooltip">
                        Approval Setting
                    </Tooltip>
                }
                error={true}
            >
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Icon.GearFill
                        style={{ fontSize: 15, color: "var(--theme1_primary)", cursor: "pointer" }}
                        onClick={() => {
                            setClientTypeId(props.data['ClientTypeId'])
                            setApprovalSettingModal(true)
                            console.log(clientTypeId);
                        }}
                        className="setting-gear-step"

                    />
                </div>
            </OverlayTrigger>
        )
    }

    useEffect(() => {
        console.log("TOUR ASKED TO BE RUN!")
    }, [run])

    useEffect(() => {
        if (dataSource !== undefined) {
            setColumnDefs([
                { field: "Client Type", suppressSizeToFit: true },
                { field: "Category", headerName: "Description", sizeColumnsToFit: true },
                {
                    field: "Action",
                    maxWidth: 70,
                    cellRenderer: approvalSettingRenderer,
                    suppressSizeToFit: true,
                    pinned: "right",
                    suppressMenu: true,
                    cellStyle: { textAlign: 'center' }
                }

            ])
        }
    }, [dataSource])

    return (
        <>
            <Row className='no-gutter page-wrapper'>
                <Col md={12} className="flex-row-center-start">
                    <p className="display-6" style={{ height: "fit-content", marginBottom: 0, width: "fit-content" }}>Approval Configuration</p>
                </Col>
            </Row>
            {approvalSettingModal &&
                <ApprovalSettingsModal
                    clienttypeid={clientTypeId}
                    onHide={() => setApprovalSettingModal(false)}
                />
            }
            {/* <CygnusHelperModal
                show={helperModalShow}
                onHide={() => setHelperModalShow(false)}
                runfeaturetutorial={(status) => setRun(status)}
                header={"Approval Configuration"}
                content={"content..."}
            /> */}

            <Row className="no-gutter">
                {dataSource !== undefined ?
                    <div style={containerStyle} className="vendor-details-grid">
                        <div className="ag-theme-balham" style={gridStyle}>
                            <AgGridReact
                                ref={approvalConfigGridRef}
                                rowData={dataSource}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                alwaysShowHorizontalScroll={false}
                                alwaysShowVerticalScroll={false}
                                onFirstDataRendered={onFirstDataRendered}
                                rowHeight={40}
          suppressRowHoverHighlight={true}
                                domLayout='autoHeight'
                            />
                        </div>
                    </div>
                    :
                    <div
                        className="flex-row-center-center"
                        style={{ marginTop: "20%" }}
                    >
                        <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                            <Bars
                                height="80"
                                width="80"
                                color="var(--theme1_primary)"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />

                            Loading your data
                        </div>
                    </div>
                }
            </Row>
        </>
    )
}

export default ApprovalConfig;