import React from "react";
import {useState, useEffect } from "react";
import {
    Row,
    Col,
} from "react-bootstrap";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { Bars } from "react-loader-spinner";
import '../EngagementLetter.css';
import { ToastContainer } from "react-toastify";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import EngagementLetterDetailsPage from '../engagement_letter_details_page/EngagementLetterDetailsPage.js';
import ApprovalDetailsPage from '../approval_details_page/ApprovalDetailsPage.js';
import MyTemplatesPage from "../my_templates_page/MyTemplatesPage.js";

const EngagementLetterMainPage = (props) => {
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [userRole, setUserRole] = useState(localStorage.getItem("usertype"));
    const [activeTab, setActiveTab] = useState(userRole === "FirmAdmin" ? "templates" : "approval_details");
    
    useEffect(() => {
        handleSelect(activeTab);
    }, []);

    const handleSelect = (key) => {
        setActiveTab(key);
    }

    // JSX for the page component
    return (
        <div className="engagement-letter-main-page">
            {!refreshGrid
                ?
                <>
                    <Row className='no-gutter'>
                        <p className="display-6" style={{ height: "fit-content", width: "fit-content", padding: 0, marginBottom: 0 }}>
                            Engagement Letter
                        </p>
                    </Row>
                    <Row className='no-gutter'>
                        <Col md={12}>
                            <Tabs
                                activeKey={activeTab}
                                onSelect={handleSelect}
                            >
                                { userRole === "FirmAdmin" &&
                                    <Tab eventKey="templates" title="Templates" className="tabs" onClick={() => console.log("activeTab")}>
                                        {activeTab === "templates" &&
                                            <MyTemplatesPage />
                                        }
                                    </Tab>
                                }

                                { userRole === "FirmAdmin" &&
                                    <Tab eventKey="client_details" title="Client Details" className="tabs">
                                        {activeTab === "client_details" &&
                                            <EngagementLetterDetailsPage  />
                                        }
                                    </Tab>
                                }

                                {
                                    <Tab eventKey="approval_details" title="Approval Details" className="tabs">
                                        {activeTab === "approval_details" &&
                                            <ApprovalDetailsPage />
                                        }
                                    </Tab>
                                }
                            </Tabs>
                        </Col>
                    </Row>
                    <ToastContainer />
                </>
                :
                <div
                    className="flex-row-center-center"
                    style={{ marginTop: "20%" }}
                >
                    <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                        <Bars
                            height="80"
                            width="80"
                            color="var(--theme1_primary)"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />

                        Loading your data
                    </div>
                </div>
            }
        </div>
    );
};

export default EngagementLetterMainPage;