import React, {
    useMemo,
    useState,
    useEffect,
    useCallback,
    useRef,
} from "react";
import {
    Row,
    Col,
    Button,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { setToLS } from "../../../utils/storage";
import { logout } from "../../../utils";
import * as Icon from "react-bootstrap-icons";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { PreviewFullWindowModal } from "./PreviewFullWindowModal";
import { RecordsFullWindowModal } from "./RecordsFullWindowModal";
import { Bars } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";


const MyFormsGrid = (my_forms_grid_props) => {
    // Setting default grid params
    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const usertype = localStorage.getItem("usertype");
    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);
    const navigate = useNavigate()
    const defaultColDef = useMemo(
        () => ({
            filter: true,
            resizable: true,
            animateRows: true,
        }),
        []
    );

    const ActionsOnFormRenderer = (action_on_form_props) => {
        // console.log("ACTIO ON FORMS PROPS: ", action_on_form_props.data)
        useEffect(() => {
            my_forms_grid_props.setformtitle(action_on_form_props.data.FormName)
            my_forms_grid_props.setformdescription(action_on_form_props.data.FormPurpose)
        }, [action_on_form_props])

        const editForm = () => {
            my_forms_grid_props.transporter("client_onboarding_form_config", {
                "config_option": {
                    clientType: action_on_form_props.data.ClientType, "createform_option":
                    {
                        "id": action_on_form_props.data.FirmFormId,
                        "templateid": action_on_form_props.data.FirmFormsTemplateId,
                        "form_name": action_on_form_props.data.FormName,
                        "form_purpose": action_on_form_props.data.FormPurpose,
                        "initial_data": action_on_form_props.data.InputStructure,
                        "preview_data": action_on_form_props.data.PreviewStructure,
                    }
                }
            })
        }

        return (
            <div className="flex-row-space-center" style={{ width: 100 }}>
                {/* {usertype === "SuperAdmin" ? */}
                <>
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id="button-tooltip">
                                Edit form structure
                            </Tooltip>
                        }
                        error={true}
                    >
                        <Button onClick={() => editForm()} style={{ cursor: "pointer", backgroundColor: "transparent", border: "0px solid white" }}>
                            <Icon.PencilSquare style={{ color: "var(--theme1_primary)" }} />
                        </Button>
                    </OverlayTrigger>
                </>
            </div>
        )
    }

    // Declaring the api - grid connections
    const [columnDefs, setColumnDefs] = useState(
        usertype === "SuperAdmin" ? [

            {
                field: "FormName",
                headerName: "Form Name",
            },
            {
                field: "FormPurpose",
                headerName: "Description"
            },
            {
                field: "",
                headerName: "Actions",
                cellRenderer: ActionsOnFormRenderer,
                maxWidth: 100,
                width: 100
            }
        ] : [

            {
                field: "FormName",
                headerName: "Form Name",
            },
            {
                field: "FormPurpose",
                headerName: "Description"
            },
            // {
            //     field: "",
            //     headerName: "Records",
            //     cellRenderer: RecordsRenderer,
            //     maxWidth: 150,
            //     width: 150
            // },
            {
                field: "",
                headerName: "Actions",
                cellRenderer: ActionsOnFormRenderer,
                maxWidth: 100,
                width: 100
            }
        ]);

    const CustomLoadingOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center"
                style={{ height: '9%' }}
            >
                <Row className="dashboard-row">
                    <div className="spinner">
                        <Bars
                            height="80"
                            width="80"
                            color="var(--theme1_primary)"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                </Row>
                {props.loadingMessage}
            </div>
        );
    };

    const CustomNoRowsOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center flex-row-center-center"
                style={{ height: '9%' }}
            >
                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                    {props.noRowsMessageFunc()}
                </div>
            </div>
        );
    };

    const loadingOverlayComponent = useMemo(() => {
        return CustomLoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: "Loading your forms",
        };
    }, []);
    const noRowsOverlayComponent = useMemo(() => {
        return CustomNoRowsOverlay;
    }, []);
    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => 'Loading your forms',
        };
    }, []);

    return (
        <div className="grid-container">
            <div className="ag-theme-balham" style={gridStyle}>
                <AgGridReact
                    ref={gridRef}
                    rowData={my_forms_grid_props.rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={my_forms_grid_props.onGridReady}
                    alwaysShowHorizontalScroll={false}
                    alwaysShowVerticalScroll={false}
                    rowHeight={25}
                    suppressContextMenu={true}
                    onFirstDataRendered={onFirstDataRendered}
                    noRowsOverlayComponent={noRowsOverlayComponent}
                    noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                />
            </div>
        </div>
    );
};

const MyForms = (props) => {
    // Setting page url for helper function
    useEffect(() => {
        setToLS("url", ["myforms"])
    }, [])
    const navigate = useNavigate()
    const [dataSource, setDataSource] = useState([]);
    const [myformsRowData, setMyformsRowData] = useState(dataSource)
    const setData = (data) => {
        setDataSource(data['data'])
        setMyformsRowData(data['data'])
    }
    let myFormsGridApi;
    const onMyFormsGridReady = useCallback((params) => {
        myFormsGridApi = params.api;
        myFormsGridApi.closeToolPanel();
    }, []);
    const [previewFullWindowModalShow, setPreviewFullWindowModalShow] = useState(false)
    const [recordsFullWindowModalShow, setRecordsFullWindowModalShow] = useState(false)
    const [previewComponents, setPreviewComponents] = useState([{}])
    const [formTitle, setFormTitle] = useState("")
    const [formDescription, setFormDescription] = useState("")
    const [formUrl, setFormUrl] = useState("")
    const [recordsModalData, setRecordsModalData] = useState([])
    const usertype = localStorage.getItem("usertype")
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    const getAllForms = useCallback((params) => {
        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                setData(data)
            });
    }, []);

    const getDefaultTemplates = useCallback((params) => {
        fetch(`${process.env.REACT_APP_BASE_URI}/workflow/forms/templates/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                setData(data)
            });
    }, []);

    const addTemplate = () => {
        console.log("TEMPLATE ADDED: ")
    }

    useEffect(() => {
        // if (usertype === "SuperAdmin") {
        getAllForms()
        // } else {
        // console.log("USER DOES NOT HAVE PERMISSION TO ACCESS THIS PAGE!")
        // }
    }, [])


    return (
        <>
            <PreviewFullWindowModal
                show={previewFullWindowModalShow}
                onHide={() => setPreviewFullWindowModalShow(false)}
                previewcomponents={previewComponents}
                formtitle={formTitle}
                formurl={formUrl}
            />
            <RecordsFullWindowModal
                show={recordsFullWindowModalShow}
                onHide={() => setRecordsFullWindowModalShow(false)}
                recordsmodaldata={recordsModalData}
                formtitle={formTitle}
                formdescription={formDescription}
                formurl={formUrl}
            />
            <Row className="page-wrapper">
                <Col md={12}>
                    <p className="display-6" style={{ height: "fit-content", marginBottom: 20 }}>Client Onboarding Template Builder</p>
                </Col>
                <Col md={12}>
                    <div className="navigator" style={{ margin: "-2.15rem 20px 0.45rem 0px", width: "fit-content", float: "right" }}>
                        <Button className="custom-button" onClick={() => props.transporter("client_onboarding_form_config", {
                            "config_option": {}
                        })}>Create form</Button>
                    </div>
                </Col>

                <Col md="12" className="grid-row myforms-grid">
                    <MyFormsGrid
                        rowData={myformsRowData}
                        onGridReady={onMyFormsGridReady}
                        transporter={props.transporter}
                        myformsoption={props.myformsoption}
                        setpreviewcomponents={(previewcomponents) => setPreviewComponents(previewcomponents)}
                        setformtitle={(formtitle) => setFormTitle(formtitle)}
                        setformdescription={(description) => setFormDescription(description)}
                        setformurl={(url) => setFormUrl(url)}
                        setpreviewfullwindowmodal={(flag) => setPreviewFullWindowModalShow(flag)}
                        setrecordsmodaldata={(data) => setRecordsModalData(data)}
                        setrecordsfullwindowmodal={(flag) => setRecordsFullWindowModalShow(flag)}
                    />
                </Col>
            </Row >
        </>
    )
}

export default MyForms;