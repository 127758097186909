import React, { useState, useEffect } from "react";
import _ from "lodash";
import * as Icon from "react-bootstrap-icons";
import { Card, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getFromLS, setToLS } from "../../utils/storage";
// import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Bars } from "react-loader-spinner";
import { logout } from "../../utils";
import * as Highcharts from 'highcharts/highmaps';
import dashboard_settings from '../../dashboard_settings/dashboard_settings.json';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useNavigate } from "react-router-dom";
import './ReportDashboard.css';

// import { NoAccessPopup } from "../../reusable-components/NoAccessPopupComponent/NoAccessModal";

// This is internal report mapper for intelligence report
const REPORT_MAPPER = {
  "Client Geographical Distribution": "ZipCodeMap",
  "Client Filing Type Distribution": "SingleVsJointFiling",
  "Client Type Distribution": "IndividualVsEntityChart",
  "Client Extension Status": "ExtensionStatus",
  "Entity Type Distribution": "EntityTypeDistribution",
  "Top 10 Customers by Billing": "TopCustomers",
};

require("highcharts/modules/accessibility")(Highcharts);


const ReportDashboard = (report_dashboard_props) => {
  // console.log("REPORT DASHBOARD PROPS: ", report_dashboard_props)
  const [firm_id, setFirmId] = useState(localStorage.getItem("firmId"));
  const [reports, setReports] = useState([]);
  const [reportView, setReportView] = useState([]);
  const [allReportsFlag, setAllReportsFlag] = useState([])
  // const [reportToggle, setReportToggle] = useState(false);
  const [favoriteReports, setFavoriteReports] = useState([]);
  const [isLoading, setLoading] = useState(true);

  // const [dashBoardConfigState, setDashboardConfigState] = useState();
  const [saveDashboardSettingToggle, setSaveDashboardSettingToggle] = useState(true);
  const localChartTheme = getFromLS("chart-theme");
  const dashboard_config = getFromLS('dashboard-config')
  const navigate = useNavigate();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({ firm_id }),
  };
  let drag_data_format = dashboard_config != null ? dashboard_config : dashboard_settings.initial_data;
  const setReporter = (reps) => {
    // console.log("Reports: ", reps);
    // console.log("DRAG DATA FORMAT: ", drag_data_format);
    // console.log("DASHBOARD SETTINGS: ", JSON.stringify(drag_data_format) == JSON.stringify(dashboard_settings.initial_data))
    let reports = reps.Data.reports;
    if (drag_data_format === undefined || JSON.stringify(drag_data_format) === JSON.stringify(dashboard_settings.initial_data)) {
      drag_data_format = {
        "reports": {},
        "columns": {
          "column-1": {
            "id": "column-1",
            "reportIds": []
          },
          "column-2": {
            "id": "column-2",
            "reportIds": []
          },
          "column-3": {
            "id": "column-3",
            "reportIds": []
          }
        },
        "columnOrder": [
          "column-1",
          "column-2",
          "column-3"
        ],
        "default_view": "list"
      }
      console.log("DRAG DATA", drag_data_format);
      for (let report in reports) {
        drag_data_format["reports"][reports[report].report_name] = {
          "id": reports[report].report_name,
          "content": reports[report]
        }
        if (drag_data_format["columns"]["column-1"].reportIds.length
          === drag_data_format["columns"]["column-2"].reportIds.length &&
          drag_data_format["columns"]["column-2"].reportIds.length
          === drag_data_format["columns"]["column-3"].reportIds.length) {
          drag_data_format["columns"]["column-1"].reportIds.push(reports[report].report_name)
        }
        else if (drag_data_format["columns"]["column-1"].reportIds.length
          > drag_data_format["columns"]["column-2"].reportIds.length &&
          drag_data_format["columns"]["column-2"].reportIds.length
          === drag_data_format["columns"]["column-3"].reportIds.length) {
          drag_data_format["columns"]["column-2"].reportIds.push(reports[report].report_name)
        }
        else if (drag_data_format["columns"]["column-1"].reportIds.length
          === drag_data_format["columns"]["column-2"].reportIds.length &&
          drag_data_format["columns"]["column-2"].reportIds.length
          > drag_data_format["columns"]["column-3"].reportIds.length) {
          drag_data_format["columns"]["column-3"].reportIds.push(reports[report].report_name)
        }
      }
      console.log("DRAG DATA FORMAT: ", drag_data_format);
      setToLS("dashboard-config", drag_data_format)
      setData(drag_data_format)
    }
    // setReportToggle(true);
    // if (drag_data_format.default_view == "favorites") {
    //   setReportToggle(true)
    // } else {
    //   setReportToggle(false)
    // }
    setReports(reports);
    const fav_reports = []
    for (let report in reports) {
      if (reports[report].is_favourite === 'Y') {
        fav_reports.push(reports[report])
      }
    }
    setFavoriteReports(fav_reports)
    setReportView(fav_reports);
    return reps;
  };

  const get_dashboard_configuration = () => {
    const parameter_name = "dashboard_config";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ firm_id, parameter_name }),
    };
    fetch(`${process.env.REACT_APP_BASE_URI_REPORTS}/workflow/personalization/get_params/`,
      // `${process.env.REACT_APP_BASE_URI}/workflow/personalization/get_params/`,
      requestOptions
    )
      .then((result) => result.json())
      .then((result) => dashboardConfigStateUpdater(result));
  };
  const dashboardConfigStateUpdater = (result) => {
    console.log("DASHBOARD CONFIG: ", result)
    console.log("DASHBOARD SETTINGS: ", dashboard_settings)
    if (result != undefined) {
      if (result.data[0].params.length === 0) {
        setToLS("dashboard-config", dashboard_settings.initial_data);
      } else if (
        result.data[0].params[0].parameter_name === "dashboard_config"
      ) {
        setToLS(
          "dashboard-config",
          JSON.parse(result.data[0].params[0].parameter_value_1)
        );
      }
    } else {
      setToLS("dashboard-config", dashboard_settings.initial_data);
    }
    return;
  };
  // useEffect(() => {
  //   get_dashboard_configuration();
  // }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_URI_REPORTS}/workflow/all_reports/`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            // Handle unauthorized error
            logout();
            alert("Session ended , Please login back");
          } else {
            throw new Error("Request failed.");
          }
        }
        return response.json();
      })
      .then((reps) => setReporter(reps))
      .then((reps) => {
        setAllReportsFlag(reps.Data.reports)
        setToLS("all_reports", reps.Data.reports)
      })
    // .then(()=> get_dashboard_configuration())
    // .then(get_favorite_reports());
    setTimeout(() => {
      setLoading(false);
    }, 6000);
  }, []);

  const save_dashboard_configutaion = (initialData) => {
    const parameter_value_1 = JSON.stringify(initialData);
    const parameter_name = "dashboard_config";
    const payload = { firm_id, parameter_name, parameter_value_1 };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    fetch(`${process.env.REACT_APP_BASE_URI_REPORTS}/workflow/personalization/set_params/`,
      // `${process.env.REACT_APP_BASE_URI}/workflow/personalization/set_params/`,
      requestOptions
    ).then((response) => {
      if (!response.ok) {
        if (response.status === 401) {
          // Handle unauthorized error
          logout();
          alert("Session ended , Please login back");
        } else {
          throw new Error("Request failed.");
        }
      }
      return response.json();
    });
  };

  const ReportCard = (report_card_props) => {
    const [favorite, setFavorite] = useState(!report_card_props.report.is_favourite);
    const categories = report_card_props.report.thumbnail.categories || [];
    const [primarySeries, setPrimarySeries] = useState({});
    const handleClick = () => {
      setFavorite(!favorite);
      if (favorite === true) {
        const index = favoriteReports.findIndex(
          (element) => element.report_name === report_card_props.report.report_name
        );
        const indexAll = reports.findIndex(
          (element) => element.report_name === report_card_props.report.report_name
        );
        if (index === -1) {
          console.log(
            "Updating the favourite value when added to fgavourite in all_reports",
            reports
          );
          console.log("Report to be updated in all_reports", report_card_props.report);
          report_card_props.report.is_favourite = true;
          favoriteReports.push(report_card_props.report);
          if (indexAll !== -1) {
            reports[indexAll].is_favourite = true;
          }
          setToLS("favorite_reports", favoriteReports);
          setToLS("all_reports", reports);
          // set_favorite_reports(favoriteReports);
          updateAllReportFav(report_card_props.report.report_name, favorite);
        }
      } else {
        const index = favoriteReports.findIndex(
          (element) => element.report_name === report_card_props.report.report_name
        );
        const indexAll = reports.findIndex(
          (element) => element.report_name === report_card_props.report.report_name
        );
        if (index !== -1) {
          console.log(
            "Updating the favourite value when added to fgavourite in all_reports",
            reports
          );
          console.log("Report to be updated in all_reports", report_card_props.report);
          favoriteReports.splice(index, 1);
          if (indexAll !== -1) {
            reports[indexAll].is_favourite = false;
          }
          setToLS("favorite_reports", favoriteReports);
          setToLS("all_reports", reports);
          // set_favorite_reports(favoriteReports);
          updateAllReportFav(report_card_props.report.report_name, favorite);
        }
      }
    };

    useEffect(() => {
      setPrimarySeries(report_card_props.report.thumbnail.data);
    }, [report_card_props.report.thumbnail.data]);


    const [topology, setTopology] = useState(null);
    useEffect(() => {
      const fetchData = async () => {
        const topologyData = await fetchTopology();
        setTopology(topologyData);
      };

      fetchData();
    }, []);

    const primaryOptions = {
      chart: {
        innerWidth: 80,
        // width: 600,
        height: 400,
        color: "var(--text_color)",
      },
      // marginRight: -100,
      title: {
        align: "center",
        text: report_card_props.report.report_name,
        style: {
          textOutline: false,
          color: "var(--text_color)",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size: var(--default)"></span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span> <b>{point.y:,.0f}</b> of total<br/>',
      },
      exporting: {
        enabled: false,
      },
    };
    primaryOptions.chart.type = report_card_props.report.type_of_chart;
    primaryOptions.xAxis = {
      categories: categories,
      gridLineWidth: 1,
      gridLineColor: "d8d8d8",
      title: {
        style: {
          textOutline: false,
          color: "var(--text_color)",
        },
      },
      labels: {
        style: {
          textOutline: false,
          color: "var(--text_color)",
        },
      },
    }
    primaryOptions.yAxis = {
      gridLineWidth: 1,
      gridLineColor: "#d8d8d8",
      title: {
        style: {
          textOutline: false,
          color: "var(--text_color)",
        },
      },
      labels: {
        style: {
          textOutline: false,
          color: "var(--text_color)",
        },
      },
    }

    primaryOptions.plotOptions = {
      pie: {
        dataLabels: {
          enabled: false  // Disable data labels on the slices
        },
        showInLegend: true  // Enable legend
      },
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          style: {
            textOutline: false,
            color: "var(--text_color)",
          },
        },
        cursor: "pointer",
      },
    }

    primaryOptions.labels = {
      style: {
        textOutline: false,
        color: "var(--text_color)",
      },
    }
    primaryOptions.series = report_card_props.report.type_of_chart === "bar" ||
      report_card_props.report.type_of_chart === "column"
      ? primarySeries
      : {
        colorByPoint: true,
        data: primarySeries,
      }

    const fetchTopology = async () => {
      try {
        const response = await fetch('https://code.highcharts.com/mapdata/countries/us/us-all.topo.json');
        return response.json();
      } catch (error) {
        console.error('Error fetching topology:', error);
      }
    };

    useEffect(() => {
      console.log("PROPS FOR REPORT CARDS", report_card_props)
      if (report_card_props.report.type_of_chart === "map") {
        Highcharts.mapChart('container', {
          chart: {
            map: topology,
          },
          title: {
            text: 'Client Geographical Distribution'
          },

          mapNavigation: {
            enabled: false,
            buttonOptions: {
              verticalAlign: 'bottom'
            }
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            mappoint: {
              dataLabels: {
                enabled: true,
                format: '{point.name}',
              },
            }
          },
          series: [{
            name: 'Basemap',
            borderColor: '#606060',
            nullColor: 'rgba(200, 200, 200, 0.2)',
          },
          {
            type: 'mappoint',
            name: 'ZipCode',
            data: report_card_props.report.thumbnail.data,
            showInLegend: false,
            labels: {
              enabled: false
            },
            // maxSize: '8%'
          }],
        });
      }
    }, [topology])



    const openReport = () => {
      console.log("REPORT CARD ID: ", report_card_props)
      console.log("PRIMARY OPTIONS: ", primaryOptions)
      // navigate(report_card_mini_props.report.content.url)
      report_dashboard_props.transporter(REPORT_MAPPER[report_card_props.report.report_name], { report_navigator: true })
    }

    return (
      <Col className="card-container-dashboard" md="4">
        <Card>
          <Link onClick={() => openReport()} className="normal-text">
            <div className="chart-container">
              {report_card_props.report.type_of_chart === "map" ?

                <>
                  {/* <h5 style={{margin: '2%', textAlign: 'center', whitespace: 'nowrap', color: `#333333`, fill: 'var(--highcharts-neutral-color-80)',fontSize: '6em',fontWeight: 'bold',display: 'block',textanchor: 'middle', fontfamily: `Arial`}}>
                  Client Geographical Distribution
                 </h5> */}
                  <div id="container" style={{ width: '100%' }}></div>

                  {/* <Image src={require('../../../images/map.jpeg')} alt="Map" style={{ width: '100%', height: 'auto', marginTop: '17.5%', padding:'2%'}} /> */}
                </>
                : (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={primaryOptions}
                  />
                )}
            </div>
          </Link>
          <Card.Body>
            <div className="favorite-bar">
              <div className="favorite-item">
                <Card.Text>{report_card_props.report.description}</Card.Text>
              </div>
              <div className="toggle favorite-item heart" onClick={handleClick}>
                {
                  // favorite ? (
                  //   <div>
                  //     <Icon.Heart />
                  //   </div>
                  // ) : (
                  <div>
                    <Icon.HeartFill />
                  </div>
                  // )
                }
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  const [initialData, setData] = useState(drag_data_format);

  const updateAllReportFav = (repn, is_fav) => {
    const set_to = is_fav;
    const report_name = repn;
    const payload = { firm_id, report_name, set_to };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    console.log(requestOptions);
    fetch(`${process.env.REACT_APP_BASE_URI_REPORTS}/workflow/personalization/set_favourite/`,
      // `${process.env.REACT_APP_BASE_URI}/workflow/personalization/set_favourite/`,
      requestOptions
    ).then((response) => {
      if (!response.ok) {
        if (response.status === 401) {
          // Handle unauthorized error
          logout();
          alert("Session ended , Please login back");
        } else {
          throw new Error("Request failed.");
        }
      }
      return response.json();
    });
  };

  // const viewReports = () => {
  //   setReportToggle((reportToggle) => !reportToggle);
  // };

  const saveDashboardSettings = (data) => {
    console.log("Settings data to be saved: ", data)
    setToLS("dashboard-config", data)
    save_dashboard_configutaion(data)
  }

  // const saveDashboardViewSettings = (data) => {
  //   if (toggle_fav) {
  //     data.default_view = "favorites"
  //   }
  //   else if (!toggle_fav) {
  //     data.default_view = "list"
  //   }
  //   console.log("Default view updated: ", data)
  //   setToLS("dashboard-config", data)
  //   save_dashboard_configutaion(data)
  // }

  const Switch = () => {
    return (
      <>
        <label className="report-view-toggler">
          <Icon.Stack className="dashboard-view-heart" />
          {/* <input type="checkbox" checked={isToggled} onChange={onToggle} />
          {reportToggle ? (
            <Icon.HeartFill className="dashboard-view-heart" />
          ) : (
            <Icon.Stack className="dashboard-view-heart" />
          )} */}
        </label>

      </>
    );
  };

  if (isLoading) {
    return (
      <div className="loading-container" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <Row className="dashboard-row">
          <div className="spinner-container" style={{ margin: "0 auto", textAlign: "center" }}>
            <div className="spinner">
              <Bars
                height="80"
                width="80"
                color="var(--theme1_primary)"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <h5>Loading all reports</h5>
            </div>
          </div>
        </Row>
      </div>
    );
  }

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = initialData.columns[source.droppableId];
    const finish = initialData.columns[destination.droppableId];

    if (start === finish) {
      const newReportIds = Array.from(start.reportIds);
      newReportIds.splice(source.index, 1);
      newReportIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        reportIds: newReportIds,
      };

      const newState = {
        ...initialData,
        columns: {
          ...initialData.columns,
          [newColumn.id]: newColumn,
        },
      };

      setData(newState);
      return;
    }

    // Moving from one list to another and swap last elements
    const startReportIds = Array.from(start.reportIds);
    startReportIds.splice(source.index, 1);

    const finishReportIds = Array.from(finish.reportIds);
    finishReportIds.splice(destination.index, 0, draggableId);
    const last_from_destination = finishReportIds.pop()
    const newFinish = {
      ...finish,
      reportIds: finishReportIds,
    };

    startReportIds.push(last_from_destination)
    const newStart = {
      ...start,
      reportIds: startReportIds,
    };

    const newState = {
      ...initialData,
      columns: {
        ...initialData.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    setData(newState);
    setSaveDashboardSettingToggle(true)
    console.log(newState)
  };

  let noAccessRestratiner = true

  if (allReportsFlag.length === 0) {
    setTimeout(() => {
      noAccessRestratiner = false
    }, 2000);
    // if (!noAccessRestratiner) {
    //   return (
    //     <NoAccessPopup
    //       featurename={"any reports"}
    //     />
    //   )
    // }
  }

  return (
    <>
      <Row className="card-row-header">
        <div style={{width: "fit-content"}}>
          <Switch></Switch>
          <span className="display-6 page-header">Dashboard</span>
        </div>
        <div style={{width: 350, marginTop: 10}}>
          <DropdownButton
            as={ButtonGroup}
            key="down-centered"
            id="dashboard-config"
            title="Configure dashboard"
            className="configure-dashboard"
          >
            <Dropdown.Item eventKey="1" className="save-changes-1">
              <Button className="save-button">
                {/* onClick={() => saveDashboardViewSettings(initialData)}> */}
                Make &nbsp; <Icon.Stack style={{ fill: "#bd0155" }} />
                {/* {reportToggle ? <Icon.HeartFill style={{ fill: "#bd0155" }} /> : <Icon.Stack />} */}
                &nbsp; default view
              </Button>

            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="2" className="save-changes-2"><Button className="save-button" onClick={() => saveDashboardSettings(initialData)}>Save configuration</Button></Dropdown.Item>
          </DropdownButton>
        </div>
      </Row>
      <>
        <Row className="dashboard-row ">
          {
            reportView.length > 0 &&
            reportView.map((report, index) => (
              <ReportCard report={report} key={index} />
            ))
          }
        </Row>
      </>
      {/* {reportToggle
        ?
       
        : */}
      {/* <>
          <Row>
            <DragDropContext onDragEnd={onDragEnd}>
              <DragDropContextContainer>
                {initialData.columnOrder.map(columnId => {
                  const column = initialData.columns[columnId];
                  const reports = column.reportIds.map(reportId => initialData.reports[reportId]);
                  return <Column key={column.id} column={column} reports={reports} />;
                })}
              </ DragDropContextContainer>
            </DragDropContext>
          </Row>
        </>
      } */}
    </>
  );
};

export default ReportDashboard;
