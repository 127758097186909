import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from "react-router-dom";
import NewLogin from "./pages/login/LoginNew";
import MyClients from "./pages/client_onboarding/MyClients";
import ClientOnboardingFormConfig from "./pages/client_onboarding/ClientOnboardingFormConfig";
import ClientDataManagementConfig from "./pages/client_onboarding/ClientDataManagementConfig";
import MyForms from "./pages/onboarding_form_builder/my_forms/MyForms"
import FormLayout from "./pages/onboarding_form_builder/Layout";
import Dashboard from "./pages/new_dashboard/Dashboard";
import ReportDashboard from './pages/reports_dashboard/ReportDashboard';
import IndividualVsEntityChart from './pages/reports_dashboard/reports/CustomersByType';
import SingleVsJointFiling from './pages/reports_dashboard/reports/CustomersByJoinType';
import GeoDistMap from './pages/reports_dashboard/reports/CustomersOfficewiseGeoDistribution';
import ExtensionStatus from "./pages/reports_dashboard/reports/CustomersExtensionStatus";

import DiffToolMainClientGridPage from "./pages/client_reconciliation/diff_tool_main_client_grid_page/DiffToolMainClientGridPage";
import EngagementLetterMainPage from "./pages/engagement_letter/engagement_letter_main_page/EngagementLetterMainPage";
import MyTemplatesPage from "./pages/engagement_letter/my_templates_page/MyTemplatesPage";
import EngagementLetterDetailsPage from "./pages/engagement_letter/engagement_letter_details_page/EngagementLetterDetailsPage";
import ApprovalDetailsPage from "./pages/engagement_letter/approval_details_page/ApprovalDetailsPage";

import ClientDataMapping from "./pages/client_onboarding/ClientDataMapping";
import EntityTypeDistribution from "./pages/reports_dashboard/reports/EntityTypeDist";
import ClientApproval from "./pages/client_onboarding/ClientApproval";

function App() {
  return (
    <>
    {/*  */}
      <Routes>
        <Route path={""} element={<NewLogin />}></Route>
        <Route path={"/"} element={<NewLogin />}></Route>
        <Route path={"/login"} element={<NewLogin />}></Route>
        <Route path={"/login2"} element={<NewLogin />}></Route>
        <Route path={"/dashboard"} element={<Dashboard />}></Route>
        <Route path={"/client-onboarding-form"} element={<ClientOnboardingFormConfig />}></Route>
        <Route path={"/client-data-management"} element={<ClientDataManagementConfig />}></Route>
        <Route path={"/client-field-mapping"} element={<ClientDataMapping />}></Route>
        <Route path={"/forms"} element={<MyForms />}></Route>
        <Route path={"/create-form"} element={<FormLayout />}></Route>
        <Route path={"/my-clients"} element={<MyClients />}></Route>
        <Route path={"/approvals"} element={<ClientApproval />}></Route>
        {/* <Route path={"/reports"} element={<ReportDashboardBackup />}></Route> */}
        <Route path={"/clientdashboard"} element={<ReportDashboard />}></Route>

        <Route path={"/dashboard/cust_type_reports"} element={<IndividualVsEntityChart />}></Route>
        <Route path={"/dashboard/cust_join_type"} element={<SingleVsJointFiling />}></Route>
        {/* <Route path={"/dashboard/cust_geo_dist"} element={<ZipCodeMap />}></Route> */}
        <Route path={"/dashboard/cust_geo_dist"} element={<GeoDistMap />}></Route>
        <Route path={"/dashboard/cust_extension_status"} element={<ExtensionStatus />}></Route>
        <Route path={"/dashboard/cust_entity_dist"} element={<EntityTypeDistribution />}></Route>

        <Route path={"/diff-tool"} element={<DiffToolMainClientGridPage />}></Route>
        <Route path={"/templates"} element={<MyTemplatesPage />}></Route>
        <Route path={"/wizard-form-builder"} element={<EngagementLetterMainPage />}></Route>
        <Route path={"/template-mappings"} element={<EngagementLetterMainPage />}></Route>
        <Route path={"/client-details"} element={<EngagementLetterDetailsPage />}></Route>
        <Route path={"/approval-details"} element={<ApprovalDetailsPage />}></Route>

      </Routes>
    </>
  );
}

export default App;
