import React, { useContext } from 'react'
import { Col } from "react-bootstrap";
import { FormContext } from '../FormContext';
import "./CustomElements.css"

const Radio = ({ id, label, value, disabled, pivot, pivotreverse, mandatory, hidden, md, margins }) => {
    const radio_value = value
    const radio_pivot = pivot
    const radio_pivot_reverse = pivotreverse
    const { handleChange } = useContext(FormContext)
    // console.log("MD: ", md)
    const onChange = (event) => {
        event.stopPropagation()
        console.log("Radio event: ", event)
        handleChange(id, event)
    }
    const marginTop = margins["top"] !== undefined && margins["top"] !== null ? margins["top"] : 5
    const marginRight = margins["right"] !== undefined && margins["right"] !== null ? margins["right"] : 5
    const marginBottom = margins["bottom"] !== undefined && margins["bottom"] !== null ? margins["bottom"] : 5
    const marginLeft = margins["left"] !== undefined && margins["left"] !== null ? margins["left"] : 5
    return (
        <>
            {
                radio_pivot_reverse
                    ?
                    <Col md={md !== undefined ? md : 12} className={radio_pivot ? "pad-trbl-10 pivotreverse-check-holder" : "pad-trbl-10 custom-select-holder"} style={{ margin: `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px` }}>
                        <label className="custom-checkbox-label move-left" htmlFor="exampleCheck1">{mandatory ? <span style={{ color: "red", marginRight: 5 }}>*</span> : ""}{label}</label>
                        <input type="radio" className={radio_pivot ? "form-check-input move-left custom-check-input pivot-check" : "form-check-input move-left custom-check-input"} id="exampleCheck1" checked={radio_value}
                            onChange={event => onChange(event)}
                            disabled={disabled}
                            required={mandatory}
                            autoComplete="new-password"
                        />
                    </Col>
                    :
                    <Col md={md !== undefined ? md : 12} className={radio_pivot ? "pad-trbl-10 pivot-check-holder" : "pad-trbl-10 custom-select-holder"} style={{ margin: `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px` }}>
                        <label className="custom-checkbox-label move-left" htmlFor="exampleCheck1">{mandatory ? <span style={{ color: "red", marginRight: 5 }}>*</span> : ""}{label}</label>
                        <input type="radio" className={radio_pivot ? "form-check-input move-left custom-check-input pivot-check" : "form-check-input move-left custom-check-input"} id="exampleCheck1" checked={radio_value}
                            onChange={event => onChange(event)}
                            disabled={disabled}
                            required={mandatory}
                            autoComplete="new-password"
                        />
                    </Col>
            }
        </>
    )
}

export default Radio