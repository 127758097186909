import React, {
    useState,
    useEffect
} from "react";
import {
    Button,
    Modal
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";


export const ConfirmationModal = (props) => {
    const [navigationData, setNavigationData] = useState(props.navigationdata)
    useEffect(() => {
      setNavigationData(props.navigationdata)
    }, [props.navigationdata])
    
    const skipAndLeave = () => {
        props.skipandleave(navigationData)
    }
    const stayAndSave = () => {
        props.stayandsave()
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            scrollable="false"
            className={"smallmodal"}
        >
            <Modal.Header closeButton>
            <h5><Icon.ExclamationDiamondFill fill="red"/> &nbsp;Are you sure you want to leave?</h5>
            </Modal.Header>
            <Modal.Body>
                You might lose the changes you've made on this page if not saved.
            </Modal.Body>
            <Modal.Footer className="action-footer">
            <div className="intent-buttons">
                    <Button className="intent-save" onClick={()=>stayAndSave()}> Stay</Button>
                    <Button className="intent-skip" onClick={()=>skipAndLeave()}> Leave </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}