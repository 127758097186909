import React from "react";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useState, useCallback, useRef, useEffect } from "react";
import {
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
    Button
} from "react-bootstrap";
import { logout } from "../../../utils/index.js";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { ThreeCircles, Bars } from "react-loader-spinner";
import * as Icon from "react-bootstrap-icons";
import '../EngagementLetter.css';
import UploadSpreadsheetModal from '../upload_spreadsheet_modal/UploadSpreadsheetModal.js';
import UploadTemplateModal from '../upload_template_modal/UploadTemplateModal.js';
import { toast } from "react-toastify";
import MyClientsModal from '../my_clients_modal/MyClientsModal.js';
import CreateTemplateModal from '../create_template_modal/CreateTemplateModal.js';
import { v4 as uuidv4 } from 'uuid';
import { ApprovalConfigurationModal } from '../approval_configuration_modal/ApprovalConfigurationModal.js';
import TemplatePreviewModal from '../template_preview_modal/TemplatePreviewModal.js';

const MyTemplatesPage = (props) => {
    const [rowHeight, setRowHeight] = useState(25);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [engagementTemplateDetails, setEngagementTemplateDetails] = useState([]);
    const [uploadSpreadsheetModal, setUploadSpreadsheetModalShow] = useState(false);
    const [addNewWizardShow, setAddNewWizardShow] = useState(false);
    const [uploadTemplateModal, setUploadTemplateModalShow] = useState(false);
    const [templateFileUploading, setTemplateFileUploading] = useState(false);
    const [spreadsheetFileUploading, setSpreadsheetFileUploading] = useState(false);
    const [addNewWizardSaveFlag, setAddNewWizardSaveFlag] = useState(false);
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [myClientsModalShow, setMyClientsModalShow] = useState(false);
    const [createTemplateModalShow, setCreateTemplateModalShow] = useState(false);
    const [clientsSelecting, setClientsSelecting] = useState(false);
    const [templateCreation, setTemplateCreation] = useState(false);
    const [docFileName, setDocFileName] = useState("");
    const [approvalSettingModal, setApprovalSettingModal] = useState(false);
    const [templatePreviewModal, setTemplatePreviewModal] = useState(false);
    const [pdfFileUrl, setPdfFileUrl] = useState();
    const [userName, setUserName] = useState(localStorage.getItem("username"));    
    const [templateData, setTemplateData] = useState({});
    const [wizardData, setWizardData] = useState()
    const [wizarDataMessage, setWizarDataMessage] = useState("")
    const toastBasicConfig = {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };

    const toastErrorConfig = {
        ...toastBasicConfig,
        className: "toastify-color-error",
    };

    const fetchEngagementTemplatePageData = useCallback(() => {
        // Reset state
        resetState();

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };

        setRefreshGrid(true);

        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/templates/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                setEngagementTemplateDetails(data.data);
            })
            .finally(() => {
                setRefreshGrid(false);
            });
    }, []);

    // Reference to the AgGrid component
    const gridRef = useRef();

    // Style for the AgGrid
    const gridStyle = useMemo(() => ({ height: "85vh", width: "100%" }), []);

    // Callback to handle sort change
    const onSortChanged = (e) => {
        e.api.refreshCells();
    };

    const removeFileExtension = (filename) => {
        return filename.replace(/\.[^/.]+$/, "");
    };

    const handleDocToPdfConversion = (docFileUrl) => {
        return new Promise((resolve, reject) => {
            const requestBody = {
                "fileUrl": docFileUrl
            };

            fetch(`${process.env.REACT_APP_CONVERSION_API_BASE_URI}/api/pdfconversionfunction`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            logout();
                            alert("Session ended, please log in again.");
                        }
                        throw new Error("Request failed.");
                    }
                    return response.blob();
                })
                .then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
                    resolve(url);
                })
                .catch(error => {
                    console.error('Error converting Doc to PDF:', error);
                    toast.error(
                        "Failed to convert Doc to PDF.",
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                    reject(error);
                });
        });
    };

    /**
     * Button cell renderer for the grid.
     * @param {Object} props - Props for the button cell renderer.
     * @returns Button for editing the row in the grid.
     */
    const EditBtnCellRenderer = (props) => {
        const viewClick = async () => {
            const filename = props.data.FileName;
            const docFileUrl = `${process.env.REACT_APP_AZURE_BLOB_BASE_URI}/templatefiles/${filename}`;

            const pdfFileUrl = await handleDocToPdfConversion(docFileUrl);
            console.log("pdf url:", pdfFileUrl);
            
            console.log(pdfFileUrl);

            setPdfFileUrl(pdfFileUrl);
            
            setTemplatePreviewModal(true);
        }

        const editClick = () => {
            const filename = props.data.FileName;
            const filenameWithoutExtension = filename.substring(0, filename.lastIndexOf('.')) || filename;
            localStorage.setItem("key", filenameWithoutExtension);

            setDocFileName(props.data.FileName);

            localStorage.setItem('doc_original_file_name', props.data.TemplateOriginalFileName);

            localStorage.setItem('letter_file_name', filenameWithoutExtension + ".pdf");

            localStorage.setItem('signatureFields', props.data.FrontendSignatureDataList);
            localStorage.setItem('recipients', props.data.Recipients);
            localStorage.setItem('template_id', props.data.TemplateId);
            localStorage.setItem('isEdit', JSON.stringify(true));
            localStorage.setItem('edit_type', "Template");

            setCreateTemplateModalShow(true);
            setTemplateData(props.data)
        }

        const approvalBtnClick = () => {
            localStorage.setItem('template_id', props.data.TemplateId);
            setApprovalSettingModal(true);
            setTemplateData(props.data)
        }

        return (
            <div className="icon-cell-container" style={{ justifyContent: "flex-start", gap: "10px" }}>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="button-tooltip">
                            View template
                        </Tooltip>
                    }
                    error={true}
                >
                    <div style={{
                        margin: "0px",
                        width: "29%"
                    }}>
                        <Button className="icon-button" style={{
                            padding: "0px", margin: "0px",
                            width: "100%", height: "100%"
                        }} 
                                onClick={() => viewClick()}>
                            <span className="file-icon" style={{ width: "max-content", height: "max-content" }}>
                                <img width="100%" height="100%" src="https://img.icons8.com/fluency-systems-regular/100/visible--v1.png" alt="visible--v1"/>
                            </span>
                        </Button>
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id="button-tooltip">
                                Edit template
                            </Tooltip>
                        }
                        error={true}
                    >
                    <Button className="icon-button" style={{ 
                            padding: "0px", margin: "0px",
                            pointerEvents: "all",
                            width: "23%"
                        }} 
                        onClick={() => editClick()}>
                        <Icon.PencilSquare />
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="button-tooltip">
                            Settings
                        </Tooltip>
                    }
                    error={true}
                >
                    <Button className="icon-button" style={{ 
                        padding: "0px", margin: "0px",
                        width: "25%"
                    }} 
                        onClick={() => approvalBtnClick()}>
                        <Icon.GearFill />
                    </Button>
                </OverlayTrigger>
            </div>
        );
    }

    const TemplateFileNameRenderer = (file_name_props) => {
        const template_id = file_name_props.data.TemplateId;

        const downloadTemplate = useCallback((template_id, file_name) => {
            const getRequestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            };

            fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/download/template/?TemplateId=${template_id}`, getRequestOptions)
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            // Handle unauthorized error
                            logout();
                            alert("Session ended , Please login back");
                        } else {
                            throw new Error("Request failed.");
                        }
                    }
                    return response.blob();
                }).then((blob) => {
                    const href = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', file_name);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
        }, []);

        return (
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="button-tooltip">
                        Click to download
                    </Tooltip>
                }
                error={true}
            >
                <div style={{ padding: 0, margin: 0 }}>
                    <a className="icon-button" style={{ padding: 0, justifyContent: "left" }} onClick={() => downloadTemplate(template_id, file_name_props.data["TemplateOriginalFileName"])}>
                        <span className="download-links">
                            {removeFileExtension(file_name_props.data["TemplateOriginalFileName"])}
                        </span>
                    </a>
                </div>
            </OverlayTrigger>
        )
    }

    const CreatedDateRenderer = (created_date_props) => {

        const formatDateTime = (dateTimeString) => {
            // Convert the input string to a Date object in the specified timezone offset
            const date = new Date(dateTimeString + '-05:52'); // Append the timezone offset to the input string

            // Adjust to user's local timezone
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0');

            // Format hours, minutes, and seconds
            let hour = date.getHours();
            const minute = String(date.getMinutes()).padStart(2, '0');
            const second = String(date.getSeconds()).padStart(2, '0');

            // Determine AM/PM notation
            const ampm = hour >= 12 ? 'PM' : 'AM';
            hour = hour % 12;
            hour = hour ? hour : 12; // Hour '0' should be '12'
            const formattedHour = String(hour).padStart(2, '0');

            // Format as "YYYY-MM-DD h:mm:ss A"
            return `${year}-${month}-${day} ${formattedHour}:${minute}:${second} ${ampm}`;
        };

        return (
            <span>
                {formatDateTime(created_date_props.data["CreatedDate"])}
            </span>
        )
    }

    const FileNameHeader = ({ headerName, IconComponent }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconComponent style={{ marginRight: '5px' }} />
                <span>{headerName}</span>
            </div>
        );
    };


    const defaultTemplateDetailsColumnDefs = [
        {
            headerName: "Template Name",
            field: "TemplateOriginalFileName",
            minWidth: 360,
            cellRenderer: TemplateFileNameRenderer,
            headerComponent: FileNameHeader,
            headerComponentParams: {
                headerName: "Template Name",
                IconComponent: Icon.FileEarmarkWordFill
            },
            sortable: false,
            checkboxSelection: true
        },
        {
            headerName: "Group Name",
            field: "GroupName",
            minWidth: 348,            
        },
        {
            headerName: "Status",
            field: "Status",
            minWidth: 300,
            sortable: false
        },
        {
            headerName: "Created Date",
            field: "CreatedDate",
            minWidth: 305,
            cellRenderer: CreatedDateRenderer,
            sortable: false,
        },
        {
            headerName: "Action",
            field: "",
            cellRenderer: EditBtnCellRenderer,
            pinned: "right",
            maxWidth: 90,
            minWidth: 90,
            sortable: false
        }
    ];

    // Column definitions for AgGrid
    const [templateDetailsColumnDefs, setTemplateDetailsColumnDefs] = useState(defaultTemplateDetailsColumnDefs);

    useEffect(() => {
        fetchEngagementTemplatePageData();
    }, []);

    // Default column definition for AgGrid
    const defaultColDef = useMemo(
        () => ({
            sortable: true,
            filter: true,
            resizable: true,
            animateRows: true,
        }),
        []
    );

    let gridApi;
    // Callback when the grid is ready
    const onGridReady = useCallback((params) => {
        gridApi = params.api;
        gridApi.closeToolPanel();
    }, []);

    /**
     * Custom overlay component for no rows.
     * @param {Object} props - Props for the overlay component.
     * @returns Overlay component for displaying a message when no rows are present.
     */
    const CustomNoRowsOverlay = (props) => {
        return (
            <div
                className="ag-overlay-loading-center flex-row-center-center"
                style={{ height: '9%' }}
            >
                <div className="spinner flex-column-center-center" style={{ fontSize: 14, fontWeight: 500 }}>
                    {props.noRowsMessageFunc()}
                </div>
            </div>
        );
    };

    const noRowsOverlayComponent = useMemo(() => { return CustomNoRowsOverlay }, []);

    const noRowsOverlayComponentParams = useMemo(() => { return { noRowsMessageFunc: () => !refreshGrid ? `No data available`:`Loading your templates` } }, [refreshGrid]);

    const resetState = () => {
        setRefreshGrid(false);
        setEngagementTemplateDetails([]);
        setTemplateDetailsColumnDefs(defaultTemplateDetailsColumnDefs);
        setIsRowSelected(false);
    };

    // Function to handle row selection change
    const onSelectionChanged = useCallback(async (event) => {
        const selectedRows = event.api.getSelectedRows();
        setIsRowSelected(selectedRows.length > 0); // Update state based on whether rows are selected

        if (selectedRows.length > 0) {
            const templateId = selectedRows[0]["TemplateId"];
            console.log("templateId", templateId)
            localStorage.setItem("template_id", JSON.stringify(templateId));
        }
    }, []);

    const fetchWizardFormDetails = (templateId) => {
        return new Promise((resolve, reject) => {
            let token = localStorage.getItem("token");
            const getRequestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            };

            if (templateId !== undefined) {
                fetch(
                    `${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/wizard_page_templates/?TemplateId=${templateId}`,
                    getRequestOptions
                )
                    .then((response) => {
                        if (!response.ok) {
                            if (response.status === 401) {
                                logout();
                                alert("Session ended , Please login back");
                            } else {
                                reject(new Error("Request failed."));
                            }
                        }
                        return response.json();
                    })
                    .then((result) => {
                        resolve(result["data"]);
                    })
                    .catch((error) => reject(error));
            } else {
                reject(new Error("Template ID is undefined"));
            }
        });
    };

    const fetchTemplateApprovalConfigDetails = (templateId) => {
        return new Promise((resolve, reject) => {
            let token = localStorage.getItem("token");
            const getRequestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            };

            if (templateId !== undefined) {
                fetch(
                    `${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/template_approval_configuration_details/?TemplateId=${templateId}`,
                    getRequestOptions
                )
                    .then((response) => {
                        if (!response.ok) {
                            if (response.status === 401) {
                                logout();
                                alert("Session ended , Please login back");
                            } else {
                                reject(new Error("Request failed."));
                            }
                        }
                        return response.json();
                    })
                    .then((result) => {
                        resolve(result["data"]);
                    })
                    .catch((error) => reject(error));
            } else {
                reject(new Error("Template ID is undefined"));
            }
        });
    };

    const [selectedFieldOptions, setSelectedFieldOptions] = useState({})
    useEffect(() => {
        const token = localStorage.getItem("token")
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        fetch(`${process.env.REACT_APP_BASE_URI}/mdm/engagement-letter/firm_template_fields/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        // Handle unauthorized error
                        logout();
                        alert("Session ended , Please login back");
                    } else {
                        throw new Error("Request failed.");
                    }
                }
                return response.json();
            })
            .then((data) => {
                if (data.success === true) {
                    console.log("SELECTED FIELD OPTIONS: ", JSON.parse(data["data"][0]["SelectedFields"]))
                    setSelectedFieldOptions(JSON.parse(data["data"][0]["SelectedFields"]))
                } else {
                    toast.error(
                        "Failed to fetch selected fields: " + data.message,
                        {
                            position: "bottom-right",
                            autoClose: 2500,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            className: "toastify-color-error",
                        }
                    );
                }
            })
            .catch((err) => console.log("Err: ", err));
    }, [])

    const createTemplateBtnClick = () => {
        const newUUID = uuidv4();
        localStorage.setItem("key", `${userName}_${newUUID}`);
        console.log(`${userName}_${newUUID}`);

        setDocFileName("EngagementLetter.docx");
        localStorage.setItem('letter_file_name', "EngagementLetter.pdf");

        localStorage.setItem('signatureFields', JSON.stringify({}));
        localStorage.setItem('recipients', JSON.stringify([]));

        localStorage.setItem('isEdit', JSON.stringify(false));

        localStorage.setItem("template_id", JSON.stringify(-1));

        localStorage.setItem('edit_type', "Template");

        setCreateTemplateModalShow(true);
    }

    const addNewWizard = async () => {
        setAddNewWizardShow(true);
        const templateId = localStorage.getItem("template_id");
        console.log("templateId", templateId)
        setWizarDataMessage("Loading your wizard")
        const wizardFormDetails = await fetchWizardFormDetails(templateId);
        console.log("WIZARD FORM DETAILS: ", wizardFormDetails)
        const formatted_pages = []
        if (wizardFormDetails.length !== 0) {
            for (let [index, page] of wizardFormDetails.entries()) {
                formatted_pages.push({
                    data: JSON.parse(page["PreviewStructure"]),
                    flag: false,
                    id: index,
                    pageName: page["TemplateName"]
                })
            }
        }else{
            setWizarDataMessage("This feature has not been configured for the selected template. Please complete the configuration to use this feature.")
        }
        console.log("FORMATTED PAGES: ", formatted_pages)
        setWizardData(formatted_pages)
    }

    const uploadSpreadsheet = async () => {
        const templateId = localStorage.getItem("template_id");
        console.log("templateId", templateId)
        const approvalConfigDetails = await fetchTemplateApprovalConfigDetails(templateId);
        const isApprovalConfigured = approvalConfigDetails.filter(data => data.ApprovalRequired === "N" || data.Active === "Y").length > 0;

        if (isRowSelected) {
            if (isApprovalConfigured) {
                setUploadSpreadsheetModalShow(true);
            }
            else {
                toast.error(
                    `Please set approval configuration before uploading.`,
                    toastErrorConfig
                );
            }
        }
        else {
            toast.error(
                `Please select a template before uploading.`,
                toastErrorConfig
            );
        }
    }

    // JSX for the page component
    return (
        <>            
                <>
                    {createTemplateModalShow && (
                        <CreateTemplateModal
                            docFileName={docFileName}
                            show={createTemplateModalShow}
                            onHide={() => {
                                if (localStorage.getItem("template_id") !== "-1")
                                    setApprovalSettingModal(true);
                                else {
                                    setCreateTemplateModalShow(false);
                                    fetchEngagementTemplatePageData();
                                }
                            }}
                            setTemplateCreation={(flag) => setTemplateCreation(flag)}
                        />
                    )}
                    {approvalSettingModal && (
                        <ApprovalConfigurationModal
                            show={approvalSettingModal}
                            templateId={localStorage.getItem("template_id")}
                            onHide={() => {
                                setApprovalSettingModal(false);

                                if (createTemplateModalShow) {
                                    setCreateTemplateModalShow(false);
                                    fetchEngagementTemplatePageData();
                                }
                            }}
                            templateData={templateData}
                            fetchTemplateData={fetchEngagementTemplatePageData}
                        />
                    )}
                    {templatePreviewModal && (
                        <TemplatePreviewModal
                            show={templatePreviewModal}
                            pdfFileUrl={pdfFileUrl}
                            onHide={() => setTemplatePreviewModal(false)}
                        />
                    )}
                    <MyClientsModal
                        show={myClientsModalShow}
                        onHide={() => setMyClientsModalShow(false)}
                        setClientsSelecting={(flag) => setClientsSelecting(flag)}
                    />
                    <UploadTemplateModal
                        show={uploadTemplateModal}
                        onHide={() => setUploadTemplateModalShow(false)}
                        refresh={() => fetchEngagementTemplatePageData()}
                        setfileuploading={(flag) => setTemplateFileUploading(flag)}
                    />
                    <UploadSpreadsheetModal
                        show={uploadSpreadsheetModal}
                        onHide={() => setUploadSpreadsheetModalShow(false)}
                        refresh={() => fetchEngagementTemplatePageData()}
                        setfileuploading={(flag) => setSpreadsheetFileUploading(flag)}
                    />

                    <Row className='no-gutter'>
                        <p className="display-6" style={{ height: "fit-content", width: "fit-content", padding: 0, marginBottom: 0 }}>
                            Engagement Letter templates
                        </p>
                    </Row>
                    <Row className='no-gutter'>
                        <Col md={12} className="d-flex justify-content-end" style={{ float: "right" }}>
                            <div style={{ marginBottom: "0rem", marginRight: "1rem", marginTop: "-2.1rem" }}>
                                <Button
                                    onClick={createTemplateBtnClick}
                                    className="custom-button"
                                >
                                    {
                                        templateCreation ?
                                            <div style={{ marginRight: 6 }}>
                                                <ThreeCircles
                                                    height="15"
                                                    width="15"
                                                    color="#4fa94d"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel="three-circles-rotating"
                                                    outerCircleColor="var(--theme1_tertiary)"
                                                    innerCircleColor="var(--theme1_secondary)"
                                                    middleCircleColor="var(--theme1_primary)"
                                                />
                                            </div>
                                            :
                                            null
                                    }
                                    <p style={{ marginBottom: 0, marginTop: 0 }}>Create Template</p>
                                </Button>
                            </div>
                            <div style={{ marginBottom: "0rem", marginRight: "1rem", marginTop: "-2.1rem", display: "none" }}>
                                <Button
                                    onClick={() => {
                                        setUploadTemplateModalShow(true);
                                    }}
                                    style={{
                                        display: "none"
                                    }}
                                    className="custom-button"
                                >
                                    {
                                        templateFileUploading ?
                                            <div style={{ marginRight: 6 }}>
                                                <ThreeCircles
                                                    height="15"
                                                    width="15"
                                                    color="#4fa94d"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel="three-circles-rotating"
                                                    outerCircleColor="var(--theme1_tertiary)"
                                                    innerCircleColor="var(--theme1_secondary)"
                                                    middleCircleColor="var(--theme1_primary)"
                                                />
                                            </div>
                                            :
                                            null
                                    }
                                    <p style={{ marginBottom: 0, marginTop: 0, marginLeft: 5 }}>Upload Template</p>
                                </Button>
                            </div>
                            <div style={{ marginBottom: "0rem", marginRight: "1rem", marginTop: "-2.1rem", display: "none" }}>
                                <Button
                                    onClick={() => {
                                        setMyClientsModalShow(true);
                                    }}
                                    style={{
                                        display: "none"
                                    }}
                                    disabled={!isRowSelected}
                                    className="custom-button"
                                >
                                    {
                                        clientsSelecting ?
                                            <div style={{ marginRight: 6 }}>
                                                <ThreeCircles
                                                    height="15"
                                                    width="15"
                                                    color="#4fa94d"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel="three-circles-rotating"
                                                    outerCircleColor="var(--theme1_tertiary)"
                                                    innerCircleColor="var(--theme1_secondary)"
                                                    middleCircleColor="var(--theme1_primary)"
                                                />
                                            </div>
                                            :
                                            null
                                    }
                                    <p style={{ marginBottom: 0, marginTop: 0, marginLeft: 5 }}>Select Clients</p>
                                </Button>
                            </div>
                            <div style={{ marginBottom: "0rem", marginRight: "1rem", marginTop: "-2.1rem" }}>
                                <Button
                                    onClick={addNewWizard}
                                    className="custom-button"
                                >
                                    {
                                        addNewWizardSaveFlag ?
                                            <div style={{ marginRight: 6 }}>
                                                <ThreeCircles
                                                    height="15"
                                                    width="15"
                                                    color="#4fa94d"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel="three-circles-rotating"
                                                    outerCircleColor="var(--theme1_tertiary)"
                                                    innerCircleColor="var(--theme1_secondary)"
                                                    middleCircleColor="var(--theme1_primary)"
                                                />
                                            </div>
                                            :
                                            null
                                    }
                                    <p style={{ marginBottom: 0, marginTop: 0 }}>Add</p>
                                </Button>
                            </div>
                            <div style={{ marginBottom: "0rem", marginRight: "1rem", marginTop: "-2.1rem" }}>
                                <Button
                                    onClick={uploadSpreadsheet}
                                    className="custom-button"
                                >
                                    {
                                        spreadsheetFileUploading ?
                                            <div style={{ marginRight: 6 }}>
                                                <ThreeCircles
                                                    height="15"
                                                    width="15"
                                                    color="#4fa94d"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel="three-circles-rotating"
                                                    outerCircleColor="var(--theme1_tertiary)"
                                                    innerCircleColor="var(--theme1_secondary)"
                                                    middleCircleColor="var(--theme1_primary)"
                                                />
                                            </div>
                                            :
                                            null
                                    }
                                    <p style={{ marginBottom: 0, marginTop: 0 }}>Upload Spreadsheet</p>
                                </Button>
                            </div>
                            <div style={{ marginBottom: "0rem", marginTop: "-1.75rem" }}>
                                {
                                    refreshGrid ?
                                        <div>
                                            <ThreeCircles
                                                height="20"
                                                width="20"
                                                color="#4fa94d"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                                ariaLabel="three-circles-rotating"
                                                outerCircleColor="var(--theme1_tertiary)"
                                                innerCircleColor="var(--theme1_secondary)"
                                                middleCircleColor="var(--theme1_primary)"
                                            />
                                        </div>
                                        :
                                        <Icon.ArrowCounterclockwise onClick={fetchEngagementTemplatePageData}
                                            style={{
                                                fontSize: 20,
                                                float: "right",
                                                border: "0px",
                                                cursor: "pointer"
                                            }}
                                        />
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row className='no-gutter'>
                        <Col md={12}>
                            <div className="ag-theme-balham" style={gridStyle}>
                                <AgGridReact
                                    key={refreshGrid}
                                    ref={gridRef}
                                    rowData={engagementTemplateDetails}
                                    columnDefs={templateDetailsColumnDefs}
                                    defaultColDef={defaultColDef}
                                    onGridReady={onGridReady}
                                    alwaysShowHorizontalScroll={false}
                                    alwaysShowVerticalScroll={false}
                                    rowHeight={rowHeight}
                                    rowSelection={"single"}
                                    suppressContextMenu={true}
                                    noRowsOverlayComponent={noRowsOverlayComponent}
                                    noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                                    onSortChanged={onSortChanged}
                                    onSelectionChanged={onSelectionChanged}
                                />
                            </div>
                        </Col>
                    </Row>                    
                </>                
        </>
    );
};

export default MyTemplatesPage;