import React, { useEffect, useState, useCallback } from 'react';
import Highcharts from 'highcharts';
import './ChartTable.css';
import HighchartsReact from "highcharts-react-official";
import { Row } from "react-bootstrap";
import { Tabs, Tab } from 'react-bootstrap';
import _ from "lodash";
import * as Icon from "react-bootstrap-icons";
import { Bars } from "react-loader-spinner";
import { ActionGrid } from './Grid';
import { useNavigate } from 'react-router-dom';

const ExtensionStatus = () => {
  const [isLoading, setLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [originalTableData, setOriginalTableData] = useState(null);
  const [clickedSeriesData, setClickedSeriesData] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [noDataMessage, setNoDataMessage] = useState('');
  const [slices, setSlices] = useState([]);
  const [activeTab, setActiveTab] = useState('chart');
  const [tableHeader, setTableHeader] = useState('');
  const navigate = useNavigate();
  
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URI_REPORTS}/cust_extension_status/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            alert("Session ended, Please login back");
          } else {
            throw new Error("Request failed.");
          }
        }

        const data = await response.json();
        setOriginalData(data);
        setChartData(data);

        if (!data || !data.Success || !data.Data || data.Data.length === 0) {
          setNoDataMessage('No data currently available.');
        } else {
          setNoDataMessage('');
        }

        const responseTableData = await fetch(`${process.env.REACT_APP_BASE_URI_REPORTS}/workflow/get_default_extension`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          },
        });
  
        if (!responseTableData.ok) {
          throw new Error('Request failed.');
        }
  
        const seriesData = await responseTableData.json();
        if(seriesData) {
          setClickedSeriesData(seriesData.Data);
          setOriginalTableData(seriesData.Data);
          setShowTable(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the function to fetch data
    fetchData();

    setTimeout(() => {
      setLoading(false);
    }, 6000);
  }, []); 

  let gridApi;
  const onGridReady = useCallback((params) => {
    gridApi = params.api;
    gridApi.closeToolPanel();
  }, []);

  const handlePieSliceClick = async (event) => {
    const extension = event.point.category;

    console.log(event);
    console.log(extension);

    // Make a POST call to get series data
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URI_REPORTS}/cust_extension_status/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify({ extension: extension }),
      });

      if (!response.ok) {
        throw new Error('Request failed.');
      }
      const seriesData = await response.json();
      setClickedSeriesData(seriesData.Data);
      setActiveTab('table');
      setShowTable(true);
      setTableHeader(extension);
    } catch (error) {
      console.error('Error making POST request:', error);
    }
  };

  useEffect(() => {
    if (chartData && chartData.Success && chartData.Data && chartData.Data.length > 0) {
      setSlices(chartData.Data.map(item => ({
        name: item.ExtensionStatus,
        y: item.percentage,
        count: item.count
    })))
    }
  }, [chartData]);

  const updateChart = async => {
    console.log('update chart called');
    const options = {
      ...primaryOptions,
      series: [{
        name: 'Percentage of Clients',
        data: chartData.Data.map(item => ({
            y: item.percentage,
            count: item.count,
            ExtensionStatus: item.ExtensionStatus
          }))
      }],
      plotOptions: {
        series: {
          point: {
            events: {
              click: handlePieSliceClick,
            }
          }
        }
    }
    };
        return (
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          />
        );
  }

// Render the table when clickedSeriesData is available
const renderTable = () => {
  if (clickedSeriesData && clickedSeriesData.length > 0 && showTable) {

    return (
    <>
      <h5>
      {tableHeader === null || tableHeader === '' ? 'Cleint Details' : ('Extension Status ' + ' ' + tableHeader )}</h5>
      <ActionGrid data = {clickedSeriesData} onGridReady={onGridReady} />
    </>);
  }
};

const resetData = () => {
  if (originalData) {
    setChartData(originalData);
    setSlices(chartData.Data.map(item => ({
      name: item.ExtensionStatus,
      y: item.percentage
  })));
  if(originalTableData) {
    setClickedSeriesData(originalTableData);
    setShowTable(true);
    setTableHeader(null);
  }
  updateChart();
  setActiveTab('chart');
  }
};
    
if (!chartData || !chartData.Success || !chartData.Data || chartData.Data.length === 0) {
    return <p>No data currently available.</p>;
  }

  const primaryOptions = {
    
    chart: {
        type: 'bar'
      },
      title: {
        text: 'Client Extension Status'
      },
      style: {
        color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
    },
      xAxis: {
          title: {
              text: 'Extension status'
          },
        categories: chartData.Data.map(item => item.ExtensionStatus),
        labels: {
            style: {
                color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
            }
          }
      },
      yAxis: {
        title: {
          text: 'Percentage of Clients'
        },
        max: 100,
        gridLineWidth: 0
      },
      tooltip: {
        formatter: function () {
            console.log(this);
            return 'Percentage: ' + this.y.toFixed(2) + '%<br/>Count: ' + this.point.count;
        }
    },
      legend: {
          enabled: true,
          align: 'right',
          verticalAlign: 'top',
          layout: 'vertical',
          x: 0,
          y: 0
      },    
      series: [{
        name: 'Percentage of Clients',
        data: chartData.Data.map(item => ({
            y: item.percentage,
            count: item.count,
            ExtensionStatus: item.ExtensionStatus
          }))
      }],
      plotOptions: {
        series: {
          point: {
            events: {
              click: handlePieSliceClick,
            }
          }
        }
      }  
};

const renderChart = () => {
  if (!chartData || !chartData.Success || !chartData.Data || chartData.Data.length === 0) {
    return <p>No data currently available.</p>;
  }

  const options = {
    chart: {
      type: 'bar'
    },
    title: {
      text: 'Client Extension Status'
    },
    xAxis: {
        title: {
            text: 'Extension status'
        },
      categories: chartData.Data.map(item => item.ExtensionStatus)
    },
    yAxis: {
      title: {
        text: 'Percentage of Clients'
      },
      max: 100,
      gridLineWidth: 0
    },
    legend: {
        enabled: true,
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        x: 0,
        y: 0
    },    
    series: [{
      name: 'Percentage of clients',
      data: chartData.Data.map(item => ({
        y: item.percentage,
        count: item.count,
        ExtensionStatus: item.ExtensionStatus
      }))
    }],
    tooltip: {
        formatter: function () {
            return 'Percentage: ' + this.y.toFixed(2) + '%<br/>Count: ' + this.point.count;
        }
    },
    plotOptions: {
        series: {
          point: {
            events: {
              click: handlePieSliceClick,
            }
          }
        }
      }
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};

  return (
    <div>
      {isLoading && (
        <div className="loading-container" style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
        <Row className="dashboard-row">
          <div className="spinner-container" style={{ margin: "0 auto", textAlign: "center" }}>
            <div className="spinner">
              <Bars
                height="80"
                width="80"
                color="var(--theme1_primary)"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
              <h5 style={{ display: "inline"}}>Loading Extension Status Report</h5>
            </div>
          </div>
        </Row>
      </div> )} 
      {!isLoading && (
        <>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-1rem', float: 'right' }}>
          {/* <button className='btn-mini-card' onClick={handleBackClick}>
            <Icon.ArrowLeftCircleFill className="report-transporter" />
          </button> */}
          <button className='btn-mini-card' onClick={resetData}>
            <Icon.ArrowClockwise className="report-transporter" />
          </button>
        </div>
          <Tabs activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
          <Tab eventKey="chart" title="Chart View">
            {renderChart()}
          </Tab>
          <Tab eventKey="table" title="Table View">
            <Row style ={{height: "80vh"}}>
              {renderTable()}
            </Row>
          </Tab>
        </Tabs>
        </>
      )}
      </div>
      );
};

export default ExtensionStatus;
