import React, { useContext } from 'react'
import { Col } from "react-bootstrap";
import { FormContext } from '../FormContext';
import "./CustomElements.css"

const Daypicker = ({ id, label, value, disabled, placeholder, mandatory, hidden, md, margins }) => {
    const check_value = value
    const { handleChange } = useContext(FormContext)
    console.log("DAYPICKER: ", [id, label, check_value, disabled, placeholder, md])
    const marginTop = margins["top"] !== undefined && margins["top"] !== null ? margins["top"] : 5
    const marginRight = margins["right"] !== undefined && margins["right"] !== null ? margins["right"] : 5
    const marginBottom = margins["bottom"] !== undefined && margins["bottom"] !== null ? margins["bottom"] : 5
    const marginLeft = margins["left"] !== undefined && margins["left"] !== null ? margins["left"] : 5
    const onChange = (event) => {
        event.stopPropagation()
        console.log("DAYPICKER event: ", event)
        handleChange(id, event)
    }
    return (<>
        {
            check_value
                ?
                <Col md={md} className={"custom-daypicker-holder daypicker-check-holder"} style={{ margin: `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px` }}>
                    <input type="checkbox" className={"form-check-input move-left custom-daypicker-input daypicker-check"} id="exampleCheck1" checked={check_value}
                        onChange={event => onChange(event)}
                        disabled={disabled}
                        style={{
                            "backgroundImage": `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='120px'><text x='40' y='40' font-size='50' font-weight='700' font-family='Helvetica' fill='white'>${placeholder}</text></svg>")`
                        }}
                        required={mandatory}
                        autoComplete="new-password"
                    />
                </Col>
                :
                <Col md={md} className={"custom-daypicker-holder daypicker-check-holder"} style={{ margin: `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px` }}>
                    <input type="checkbox" className={"form-check-input move-left custom-daypicker-input daypicker-check"} id="exampleCheck1" checked={check_value}
                        onChange={event => onChange(event)}
                        disabled={disabled}
                        style={{
                            "backgroundImage": `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='120px'><text x='40' y='40' font-size='50' font-weight='700' font-family='Helvetica' fill='black'>${placeholder}</text></svg>")`
                        }}
                        required={mandatory}
                        autoComplete="new-password"
                    />
                </Col>
        }
    </>
    )
}

export default Daypicker